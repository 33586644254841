/**
 * This function return thumbnail or full URL from product images array
 * @param images product images array
 * @param prioritizeThumbnail return thumbnail over product URL
 * @param prioritizeWebp return webp version over product URL
 * @returns {string} URL of the thumbnail or the full image.
 */
export const getImageUri = (
  images,
  prioritizeThumbnail = false,
  prioritizeWebp = false,
) => {
  if (!images || images.length === 0) return '';
  let imageUri = images[0].URL;

  if (!prioritizeThumbnail && !prioritizeWebp) return imageUri;

  if (prioritizeWebp && prioritizeThumbnail) {
    if (images[0].thumbBigURLWebp && images[0].thumbBigURLWebp !== '') {
      imageUri = images[0].thumbBigURLWebp;
    } else if (images[0].thumbURLWebp && images[0].thumbURLWebp !== '') {
      imageUri = images[0].thumbURLWebp;
    } else if (images[0].URLWebp && images[0].URLWebp !== '') {
      imageUri = images[0].URLWebp;
    } else if (images[0].thumbBigURL && images[0].thumbBigURL !== '') {
      imageUri = images[0].thumbBigURL;
    } else if (images[0].thumbURL && images[0].thumbURL !== '') {
      imageUri = images[0].thumbURL;
    } else if (images[0].URL && images[0].URL !== '') {
      imageUri = images[0].URL;
    }
    return imageUri;
  }

  if (prioritizeWebp && !prioritizeThumbnail) {
    if (images[0].URLWebp && images[0].URLWebp !== '') {
      imageUri = images[0].URLWebp;
    } else if (images[0].URL && images[0].URL !== '') {
      imageUri = images[0].URL;
    } else if (images[0].thumbBigURLWebp && images[0].thumbBigURLWebp !== '') {
      imageUri = images[0].thumbBigURLWebp;
    } else if (images[0].thumbURLWebp && images[0].thumbURLWebp !== '') {
      imageUri = images[0].thumbURLWebp;
    } else if (images[0].thumbBigURL && images[0].thumbBigURL !== '') {
      imageUri = images[0].thumbBigURL;
    } else if (images[0].thumbURL && images[0].thumbURL !== '') {
      imageUri = images[0].thumbURL;
    }
    return imageUri;
  }

  if (!prioritizeWebp && prioritizeThumbnail) {
    if (images[0].thumbBigURL && images[0].thumbBigURL !== '') {
      imageUri = images[0].thumbBigURL;
    } else if (images[0].thumbURL && images[0].thumbURL !== '') {
      imageUri = images[0].thumbURL;
    } else if (images[0].thumbBigURLWebp && images[0].thumbBigURLWebp !== '') {
      imageUri = images[0].thumbBigURLWebp;
    } else if (images[0].thumbURLWebp && images[0].thumbURLWebp !== '') {
      imageUri = images[0].thumbURLWebp;
    } else if (images[0].URL && images[0].URL !== '') {
      imageUri = images[0].URL;
    } else if (images[0].URLWebp && images[0].URLWebp !== '') {
      imageUri = images[0].URLWebp;
    }
    return imageUri;
  }
};
