import { createSlice } from '@reduxjs/toolkit';

const initialState = true;

const isYuge = createSlice({
  name: 'isYuge',
  initialState,
  reducers: {
    setProduct(state, action) {
      state = action.payload;
      localStorage.setItem('sYuge', state);
      return state;
    },
  },
});

export const { setProduct } = isYuge.actions;
export default isYuge.reducer;
