import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export function ProductSkeleton() {
  return (
    <div className="mb-4" style={{}}>
      {' '}
      {/* col-sm-12 col-sm-6 col-md-6 col-lg-4 */}
      <Skeleton
        className="mb-2"
        style={{
          aspectRatio: '1',
          position: 'relative',
          top: '-4px',
          borderRadius: '12px',
        }}
      />
      <Skeleton
        className="mt-2 mb-2"
        height={35}
        style={{ borderRadius: '12px' }}
      />
      <Skeleton className="mb-2" height={20} style={{ borderRadius: '12px' }} />
      <Skeleton height={20} style={{ borderRadius: '12px' }} />
    </div>
  );
}

export default function ProductsSkeleton() {
  return (
    <div className="w-100 mt-3">
      {' '}
      {/*  mt-3 */}
      <div
        className="w-100 row"
        style={{
          margin: '0',
        }} /* style={{ marginLeft: "-15px", marginRight: "-15px"  }} */
      >
        <div className="w-100" style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-15px', }}>
          <div className="col-sm-12 col-sm-6 col-md-6 col-lg-4 pr-0 pl-4">
            <ProductSkeleton />
          </div>
          <div className="col-sm-12 col-sm-6 col-md-6 col-lg-4 pr-0 pl-4">
            <ProductSkeleton />
          </div>
          <div className="col-sm-12 col-sm-6 col-md-6 col-lg-4 pr-0 pl-4">
            <ProductSkeleton />
          </div>
        </div>
      </div>
    </div>
  );
}
