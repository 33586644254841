export const isEmailValid = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isPassWordStrong = (password) => {
  if (password.length < 8) {
    return false;
  }
  if (!password.match(/[a-z]+/)) {
    return false;
  }
  if (!password.match(/[A-Z]+/)) {
    return false;
  }
  if (!password.match(/[0-9]+/)) {
    return false;
  }

  return true;
};

export const hasWhiteSpace = (password) => {
  return /\s/g.test(password);
};

export const isDomain = (str) => {
  const regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
};
