import { CERTIFICATE_STATUS } from '../../common/variables';

export const selectCustomDomainIsLoading = (state) =>
  state.customDomain.isLoading;

export const selectCustomDomainIsLoading2 = (state) =>
  state.customDomain.isLoading2;

export const selectCustomDomainError = (state) => state.customDomain.error;

export const selectCustomDomainSuccess = (state) => state.customDomain.success;

export const selectSuccessBranding = (state) =>
  state.customDomain.successBranding;

export const selectBranding = (state) => state.customDomain.branding;

export const selectBrandingLogoUrl = (state) => {
  if (!state.customDomain) {
    return null;
  }
  const { branding } = state.customDomain;
  if (branding && branding.logo && branding.logo.url) {
    return branding.logo.url;
  }
  return null;
};

export const selectBrandingLogoStatus = (state) => {
  if (!state.customDomain) {
    return null;
  }
  const { branding } = state.customDomain;
  if (branding && branding.logo && branding.logo.logo_status) {
    return branding.logo.logo_status;
  }
  return null;
};

export const selectBrandingCDNStatus = (state) => {
  if (!state.customDomain) {
    return null;
  }
  const { branding } = state.customDomain;
  if (branding && branding.domain && branding.domain.cdn_status) {
    return branding.domain.cdn_status;
  }
  return null;
};

export const selectBrandingCertificateStatus = (state) => {
  if (!state.customDomain) {
    return null;
  }
  const { branding } = state.customDomain;
  if (
    branding &&
    branding.certificate &&
    branding.certificate.certificate_status
  ) {
    return branding.certificate.certificate_status;
  }
  return null;
};

export const selectBrandingCertificateCname = (state) => {
  if (!state.customDomain) {
    return null;
  }
  const { branding } = state.customDomain;
  if (
    branding &&
    branding.certificate &&
    branding.certificate.certificate_status &&
    branding.certificate.certificate_status ===
      CERTIFICATE_STATUS.pendingValidation &&
    branding.certificate.certificate_cname_value &&
    branding.certificate.certificate_cname_name
  ) {
    return {
      name: branding.certificate.certificate_cname_name,
      value: branding.certificate.certificate_cname_value,
    };
  }
  return null;
};

export const selectBrandingSettings = (state) => {
  if (!state.customDomain) {
    return null;
  }
  const { branding } = state.customDomain;
  if (branding && branding.settings) {
    return branding.settings;
  }
  return null;
};
