import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Product from '../components/Product/Product';
import { __ } from '../lib/i18n';
import { Footer } from '../components';
import { requestMerchantPods, selectProduct, removeProductById } from '../redux/slices/products';
import TitleComponent from '../components/TitleComponent';
import noP from '../img/products.jpg';
import CategoriesTab from '../components/CategoriesTab';
import DnDProducts from '../components/ProductDnD/DnDProducts';
import ProductsSkeleton from '../components/ProductsSkeleton/ProductsSkeleton';
import spinnerSrc from '../img/ajax-loader.gif';
import '../components/Product/Product.scss';
import { RootState } from '../redux/store';
import ArSupported from '../utils/arSupported';
import OrganizationalButtons from '../components/OrganizationalButtons';
import ProductAsTable from '../components/Product/ProductAsTable';

const Products = () => {
  const {
    app: { lang },
    products: { items, isFetchingProducts, errorProducts, showLoadMore },
    merchantCategories: { category },
  } = useSelector((state: RootState) => state);
  const [arrProduct, setArrProducts] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const { isAndroid, isIOS } = ArSupported();

  // ORGANIZATIONAL VIEW
  const [isMosaicView, setIsMosaicView] = useState(true);

  const handleOrganizationalView = (e: boolean) => {
    setIsMosaicView(e);
  };

  useEffect(() => {
    formatProducts();
  }, [items, isMosaicView]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEdit = product => {
    dispatch(selectProduct(product));
    history.push(`/products/${product.id}/edit`);
  };

  const handleDelete = ({ podId, productId }) => {
    // @ts-ignore
    dispatch(removeProductById({ podId, productId }));
  };

  const handleExtractions = product => {
    dispatch(selectProduct(product));
    localStorage.setItem('selectedProductId', product.id);
    history.push(`/products3d/${product.id}/extractions`);
  };

  const formatProducts = () => {
    const extraStyle = {
      padding: 0,
    };
    const orderedByModifiedOrCreated = [...items].sort((a, b) => {
      const modifiedA = a.modified || a.created;
      const modifiedB = b.modified || b.created;

      return modifiedB.localeCompare(modifiedA);
    });
    let temp = orderedByModifiedOrCreated?.map(p => {
      return (
        <div className="col-12" style={extraStyle} key={p?.id ?? Math.random()}>
          {isMosaicView ? (
            <Product
              lang={lang}
              product={p}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleExtractions={handleExtractions}
            />
          ) : (
            <ProductAsTable
              // lang={lang}
              product={p}
              // handleEdit={handleEdit}
              // handleDelete={handleDelete}
              // handleExtractions={handleExtractions}
            />
          )}
        </div>
      );
    });
    setArrProducts(temp ?? []);
  };

  const getBackground = () => {
    if (isFetchingProducts) return null;
    if (!items?.length) return 'productEmptyBackGround';
    return null;
  };

  const handleFetchNextPage = useCallback(() => {
    dispatch(requestMerchantPods());
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      sessionStorage.setItem('scrollPosition', document.getElementById('pproducts').scrollTop.toString());
    };

    const targetElement = document.getElementById('pproducts');

    targetElement.addEventListener('scroll', handleScroll);
    targetElement.addEventListener('touchmove', handleScroll);

    return () => {
      targetElement.removeEventListener('scroll', handleScroll);
      targetElement.removeEventListener('touchmove', handleScroll);
    };
  }, []);

  const displayProducts = () => {
    if (!items?.length) {
      return (
        <div /* className="px-10" */>
          {isAndroid || isIOS ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <button
                className={`btn btn-primary small mb-0 ${!category && 'disabledElement'}`}
                style={{
                  /* textDecoration: "underline", textUnderlineOffset: '8px', */ minHeight: '40px',
                  borderRadius: '20px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
                onClick={category ? () => history.push('/products/add') : null}>
                {' '}
                {__(lang, 'ADD_PRODUCT')}
              </button>
            </div>
          ) : (
            <button
              className={`btn btn-warning small mb-0 ${!category && 'disabledElement'}`}
              style={{ color: '#FFF', minHeight: '40px' }}
              onClick={category ? () => history.push('/products/add') : null}>
              {' '}
              {__(lang, 'ADD_PRODUCT')}
            </button>
          )}

          <div
            style={{
              width: '100%',
              marginTop: '16px',
              display: 'flex',
              justifyContent: 'center',
              gap: '12px',
              marginRight: '15px',
            }}>
            {/* <SearchBar /> */}
            <OrganizationalButtons onChangeView={handleOrganizationalView} />
          </div>
          <CategoriesTab />

          {isFetchingProducts && !errorProducts ? (
            <ProductsSkeleton />
          ) : (
            <div className="no-products">
              <p>{__(lang, 'NO_PRODUCTS')}</p>
              <img src={noP} alt="no products" />
            </div>
          )}
        </div>
      );
    }

    return (
      <div>
        <div className="w-100 d-flex flex-row justify-between" style={{ alignItems: 'center' }}>
          {isAndroid || isIOS ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <button
                className={`btn btn-primary small mb-0 ${!category && 'disabledElement'}`}
                style={{
                  /* textDecoration: "underline", textUnderlineOffset: '8px', */ minHeight: '40px',
                  borderRadius: '20px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
                onClick={category ? () => history.push('/products/add') : null}>
                {' '}
                {__(lang, 'ADD_PRODUCT')}
              </button>
            </div>
          ) : (
            <button
              className={`btn btn-warning small mb-0 ${!category && 'disabledElement'}`}
              style={{ color: '#FFF', minHeight: '40px' }}
              onClick={category ? () => history.push('/products/add') : null}>
              {' '}
              {__(lang, 'ADD_PRODUCT')}
            </button>
          )}
        </div>
        <div
          style={{
            width: '100%',
            marginTop: '16px',
            display: 'flex',
            justifyContent: 'center',
            gap: '12px',
            marginRight: '15px',
          }}>
          {/* <SearchBar /> */}
          <OrganizationalButtons onChangeView={handleOrganizationalView} />
        </div>
        <CategoriesTab />

        <div className="w-100">
          <div className="w-100 row" style={{ margin: 0 }}>
            <DnDProducts cards={items} productsList={arrProduct} isMosaicView={isMosaicView} />
          </div>
        </div>
        {showLoadMore && (
          <div className="d-flex justify-content-center mt-4">
            <button
              disabled={isFetchingProducts}
              className="btn btn-dark small mb-2"
              style={{ color: '#FFF' }}
              onClick={() => handleFetchNextPage()}>
              {isFetchingProducts ? (
                <img src={spinnerSrc} style={{ width: '25px', height: '25px' }} alt="loading" />
              ) : (
                __(lang, 'LOAD_MORE')
              )}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <main
      id="pproducts"
      role="main"
      className={
        'container-fluid  pt-3 ' + //
        getBackground()
      }
      style={{ paddingLeft: '25px', paddingRight: '25px' }}>
      <TitleComponent title={__(lang, 'PRODUCT_LIST')} />
      {displayProducts()}
      <Footer />
    </main>
  );
};
export default Products;
