import React, { useMemo, useRef, useEffect } from 'react';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import './IbanStyle.css';
import { __ } from '../../../lib/i18n';

const useOptions = () => {
  const options = useMemo(
    () => ({
      supportedCountries: ['SEPA'],
      placeholderCountry: 'NL',
      style: {
        base: {
          color: '#32325d',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
          ':-webkit-autofill': {
            color: '#32325d',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a',
          },
        },
      },
    }),
    [],
  );

  return options;
};

const IbanForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const inputEl = useRef(null);

  const { onSave, onEdit, hasAccount, lang, toggleFocus } = props;

  const handleOnButtonClick = (saving) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const ibanElement = elements.getElement(IbanElement);

    if (saving) {
      onSave(stripe, ibanElement);
    } else {
      onEdit(stripe, ibanElement);
    }
  };

  useEffect(() => {
    if (toggleFocus > 0) {
      inputEl.current.click();
    }
  }, [toggleFocus]);

  return (
    <>
      <div className="form-row">
        <label
          ref={inputEl}
          htmlFor="iban-element"
          style={{ fontWeight: 'bold' }}
        >
          IBAN:{' '}
        </label>
        &nbsp;&nbsp;&nbsp;
        <IbanElement
          id="iban-element"
          options={options}
          onReady={() => {
            // console.log("IbanElement [ready]");
          }}
          onChange={(event) => {
            // console.log("IbanElement [change]", event);
          }}
          onBlur={() => {
            // console.log("IbanElement [blur]");
          }}
          onFocus={() => {
            // console.log("IbanElement [focus]");
          }}
        />
      </div>
      <div className="row d-flex justify-content-end">
        {!hasAccount && (
          <button
            type="button"
            className="btn btn-default section-btn"
            disabled={!stripe}
            onClick={() => {
              handleOnButtonClick(true);
            }}
          >
            {__(lang, 'SAVE')}
          </button>
        )}
        {hasAccount && (
          <button
            type="button"
            className="btn btn-default section-btn"
            disabled={!stripe}
            onClick={() => {
              handleOnButtonClick(false);
            }}
          >
            {__(lang, 'SAVE')}
          </button>
        )}
      </div>
    </>
  );
};

export default IbanForm;
