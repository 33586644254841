import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import backArrow from '../img/backArrow.svg';
import { useHistory } from 'react-router';
const TitleComponent = (props) => {
  const { title, back, style } = props;

  const history = useHistory();

  const handleBack = (e) => {
    e.preventDefault();
    history.push(back);
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div
      className="flex flex-wrap flex-md-nowrap align-items-center border-bottom"
      style={style}
    >
      {back && isMobile && (
        <a href="/" onClick={handleBack}>
          <img
            className="mr-2"
            src={backArrow}
            width={25}
            style={{ marginBottom: '2px' }}
          />
        </a>
      )}
      <h2 className="m-0 text-warning">{title}</h2>
    </div>
  );
};

TitleComponent.defaultProps = {
  style: {
    marginBottom: 30,
  },
  back: '',
};

TitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.object,
  back: PropTypes.string,
};

export default TitleComponent;
