import React from 'react';
import { useMemo } from 'react';
import { memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { ItemTypes } from './ItemTypes.js';

const style = {
  cursor: 'move',
};

export const Card = memo(function Card({ p, moveCardHover, moveCardDrop, e }) {
  const {
    merchantCategories: { category },
  } = useSelector(state => state);
  const ref = useRef(null);
  const [{ isDragging, handlerId }, connectDrag] = useDrag({
    type: ItemTypes.CARD,
    item: { id: p?.id },
    collect: monitor => {
      return {
        handlerId: monitor.getHandlerId(),
        isDragging: monitor.isDragging(),
      };
    },
  });
  const [, connectDrop] = useDrop({
    accept: ItemTypes.CARD,
    hover({ id: draggedId }) {
      if (draggedId !== p?.id) {
        moveCardHover(draggedId, p?.id);
      }
    },
    drop() {
      moveCardDrop();
    },
  });
  connectDrag(ref);
  connectDrop(ref);

  const opacity = isDragging ? 0.5 : 1;
  const containerStyle = useMemo(() => ({ ...style, opacity }), [opacity]);

  return (
    <div
      data-handler-id={category !== 'ALL' ? handlerId : null}
      ref={category !== 'ALL' ? ref : null}
      style={category !== 'ALL' ? containerStyle : {}}>
      {e}
    </div>
  );
});
