import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL_V1 } from '../../common/variables';

const requestOrderStats = createAsyncThunk(
  'REQUEST_ORDERS_STATS',
  async (merchantId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;
      let res = await fetch(API_URL_V1 + '/subscriptions/orders/stats?merchantId=' + merchantId, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        return await res.json();
      } else return rejectWithValue(res.status);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const requestOrders = createAsyncThunk('REQUEST_ORDERS', async (arg, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    const { merchantId, statusNew, statusShipping, statusDelivered, page, elements } = arg;
    let res = await fetch(
      `${API_URL_V1}/subscriptions/auth?merchantId=${merchantId}&statusNew=${statusNew}&statusShipping=${statusShipping}&statusDelivered=${statusDelivered}&page=${page}&elements=${elements}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (res.status === 200) {
      return await res.json();
    }
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const changeOrderNewToShipping = createAsyncThunk('CHANGE_NEW_SHIPPING', async (arg, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    const { orderId } = arg;
    let res = await fetch(API_URL_V1 + '/subscriptions/merchant/order/status', {
      body: JSON.stringify({ merchantOrderId: orderId, status: 'shipping' }),
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    }
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const changeOrderShippingToNew = createAsyncThunk('CHANGE_SHIPPING_NEW', async (arg, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    const { orderId } = arg;
    let res = await fetch(API_URL_V1 + '/subscriptions/merchant/order/status', {
      body: JSON.stringify({ merchantOrderId: orderId, status: 'shipping' }),
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    }
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    isFetchingOrderPagin: false,
    isFetchingOrder: false,
    updatingOrder: false,
    errorOrder: false,
    errorOrderPagin: false,
    paginator: {},
    arrOrders: [],
    isFetchingOrdersStats: false,
    stats: {},
    changingOrderStatus: false,
    orderSelected: null,
    errorUpdating: false,
  },
  reducers: {
    selectOrder(state, action) {
      state.orderSelected = action.payload;
    },
  },
  extraReducers: {
    [requestOrderStats.pending]: state => {
      state.isFetchingOrdersStats = true;
      state.errorOrder = false;
    },
    [requestOrderStats.fulfilled]: (state, action) => {
      state.isFetchingOrdersStats = false;
      state.errorOrder = false;
      state.stats = action.payload;
    },
    [requestOrderStats.rejected]: (state, action) => {
      state.isFetchingOrdersStats = false;
      state.errorOrder = action.payload;
    },
    [requestOrders.pending]: state => {
      state.isFetchingOrder = true;
      state.errorOrder = false;
    },
    [requestOrders.fulfilled]: (state, action) => {
      state.isFetchingOrder = false;
      state.errorOrder = false;
      state.arrOrders = action.payload;
    },
    [requestOrders.rejected]: (state, action) => {
      state.isFetchingOrder = false;
      state.errorOrder = action.payload;
    },
    [changeOrderNewToShipping.pending]: state => {
      state.updatingOrder = true;
      state.errorUpdating = false;
    },
    [changeOrderNewToShipping.fulfilled]: (state, action) => {
      state.updatingOrder = false;
      state.errorUpdating = false;
      let orderUpdated = action.payload;
      let arr = [...state.arrOrders];
      state.arrOrders = arr.filter(order => order.id !== orderUpdated.id);
      state.stats.newOrders = state.stats.newOrders - 1;
    },
    [changeOrderNewToShipping.rejected]: (state, action) => {
      state.updatingOrder = false;
      state.errorUpdating = action.payload;
    },
    [changeOrderShippingToNew.pending]: state => {
      state.updatingOrder = true;
      state.errorUpdating = false;
    },
    [changeOrderShippingToNew.fulfilled]: (state, action) => {
      state.updatingOrder = false;
      state.errorUpdating = false;
      let orderUpdated = action.payload;
      let arr = [...state.arrOrders];
      state.arrOrders = arr.filter(order => order.id !== orderUpdated.id);
      state.stats.newOrders = state.stats.newOrders + 1;
    },
    [changeOrderShippingToNew.rejected]: (state, action) => {
      state.updatingOrder = false;
      state.errorUpdating = action.payload;
    },
  },
});
export const { selectOrder } = orderSlice.actions;
export { requestOrderStats, requestOrders, changeOrderNewToShipping, changeOrderShippingToNew };
export default orderSlice.reducer;
