import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL_V1 } from '../../common/variables';

const fetchConfirmByEmail = createAsyncThunk('CONFIRM_BY_EMAIL', async (arg, { rejectWithValue }) => {
  try {
    const { email, code } = arg;
    let res = await fetch(API_URL_V1 + '/users/confirm', {
      body: JSON.stringify({ username: email, securityCode: code }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    });

    if (res.status === 200) {
      return await res.json();
    }

    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (err) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const confirmSlice = createSlice({
  name: 'confirm',
  initialState: {
    isConfirming: false,
    success: null,
    errorConfirming: false,
  },
  reducers: {},
  extraReducers: {
    [fetchConfirmByEmail.pending]: state => {
      state.isConfirming = true;
      state.errorConfirming = false;
      state.success = null;
    },
    [fetchConfirmByEmail.fulfilled]: state => {
      state.isConfirming = false;
      state.errorConfirming = false;
      state.success = true;
    },
    [fetchConfirmByEmail.rejected]: (state, action) => {
      state.isConfirming = false;
      state.errorConfirming = action.payload;
      state.success = false;
    },
  },
});

//export const {} = citiesSlice.actions

export { fetchConfirmByEmail };

export default confirmSlice.reducer;
