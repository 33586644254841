import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { __ } from '../../lib/i18n';
import $ from 'jquery';

import './styles.css';
import ProductListingsIcon from '../../common/icons/productlistings';
import ARStorefrontIcon from '../../common/icons/arstorefront';
import YUGEProfileIcon from '../../common/icons/yugeprofile';
import AnalyticsIcon from '../../common/icons/analytics';
import OrdersIcon from '../../common/icons/orders';
import SmartLinksIcon from '../../common/icons/smartLinksIcon';

import { Collapse } from 'react-collapse';
import { setProduct } from '../../redux/slices/isYuge';
import { RootState } from '../../redux/store';
import ArSupported from '../../utils/arSupported';

const urlsYuge = [
  {
    url: 'products',
    name: 'PRODUCT_LIST',
    icon: <ProductListingsIcon />,
  },
  {
    url: 'domain',
    name: 'YUGE_PROFILE',
    icon: <YUGEProfileIcon />,
  },
  {
    url: 'analytics',
    name: 'ANALYTICS',
    icon: <AnalyticsIcon />,
  },
];

const orders = {
  url: 'orders',
  name: 'ORDERS',
  icon: <OrdersIcon />,
};

const urlsStorefront = [
  {
    url: 'products',
    name: 'PRODUCT_LIST',
    icon: <ProductListingsIcon />,
  },
  {
    url: 'productslinks',
    name: 'SMART_LINKS',
    icon: <SmartLinksIcon />,
  },
  {
    url: 'website',
    name: 'AR_STORE_FRONTS',
    icon: <ARStorefrontIcon />,
  },
  {
    url: 'storefrontdomain',
    name: 'DOMAIN',
    icon: <YUGEProfileIcon />,
  },
];

const SideBar = () => {
  const {
    app: { lang, isFetchingUser },
    order: { stats },
    merchant: {
      isFetchingMerchant,
      item: { country },
    },
    isYuge,
    merchantCategories: { isFetchingMerchantCategories, category },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;
  const [effectShow, setEffectShow] = useState(true);

  const getProduct = () => {
    try {
      const sYuge = localStorage.getItem('sYuge');
      setEffectShow(sYuge == 'true');
      dispatch(setProduct(sYuge == 'true'));
    } catch (e) {}
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    $('[data-toggle=sidebar-collapse]').click(() => SideBarCollapse());
  }, []);

  useEffect(() => {
    setEffectShow(isYuge);
  }, [isYuge]);

  const handleChangeProduct = () => {
    // if (isYuge) {
    history.push('/products');

    // }
    // setTimeout(() => {
    dispatch(setProduct(!isYuge));
    // }, 5);
  };

  const SideBarCollapse = () => {
    $('.menu-collapsed').toggleClass('d-none');
    $('.sidebar-submenu').toggleClass('d-none');
    $('.submenu-icon').toggleClass('d-none');
    $('#sidebar-container').toggleClass('sidebar-expanded sidebar-collapse');

    let separatorTitle = $('.sidebar-separator-title');
    if (separatorTitle.hasClass('d-flex')) separatorTitle.removeClass('d-flex');
    else separatorTitle.addClass('d-flex');
    $('.collapse-icon').toggleClass(
      'fa-angle-double-left fa-angle-double-right',
    );
  };

  const handleMenuClick = (event) => {
    event.preventDefault();
    const path = event.target.getAttribute('data-path');
    history.push(path);
  };

  const geturlsYuge = () => {
    return country === 'NL' ? [...urlsYuge, orders] : urlsYuge;
  };
  const { isAndroid } = ArSupported();

  return (
    <div className="d-lg-flex flex-column d-none d-sm-none  bg-light px-2 hsidebar sidebar-min-max-width">
      {' '}
      {/* col-md-3 col-lg-2 */}
      <div className="d-lg-flex flex-column d-none d-sm-none  bg-light  hsidebarContent ">
        <nav className="navbar bg-light p-0 ">
          <ul
            className="nav flex-column menu"
            style={{
              paddingTop: 'calc(25vh - 65px)' /* , minHeight: '316.38px' */,
            }}
          >
            {effectShow && (
              <Collapse isOpened={isYuge}>
                {geturlsYuge().map(({ url, name, icon }) => (
                  <li
                    key={name}
                    className={
                      'mt-2 nav-item ' +
                      (pathname === `/${url}` ? 'selected' : '')
                    }
                  >
                    <a
                      data-path={`/${url}`}
                      href={`/${url}`}
                      onClick={(event) => {
                        if (
                          isFetchingMerchant /* || isFetchingUser */ ||
                          isFetchingMerchantCategories
                        ) {
                          event.preventDefault(); // Prevent navigation if either condition is true
                        } else {
                          handleMenuClick(event); // Call the navigation function if both conditions are false
                        }
                      }}
                      className={`hover-link nav-link d-flex align-items-end
                     ${
                       (isFetchingMerchant /* || isFetchingUser */ ||
                         isFetchingMerchantCategories) &&
                       ' disabledElement'
                     }
                     `}
                      style={{ whiteSpace: 'nowrap', height: '40px' }}
                    >
                      <div className="mr-2" style={{ width: '25px' }}>
                        {icon}
                      </div>
                      {__(lang, name)}
                      {url === 'orders' && stats.newOrders > 0 && (
                        <div
                          className="rounded-circle mr-1 text-danger float-right bg-warning "
                          style={{
                            width: '25px',
                            height: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                          }}
                        >
                          <span className="text-white">{stats.newOrders}</span>
                        </div>
                      )}
                    </a>
                  </li>
                ))}
              </Collapse>
            )}
            {!effectShow && (
              <Collapse isOpened={!isYuge}>
                {urlsStorefront.map(({ url, name, icon }) => (
                  <li
                    key={name}
                    className={
                      'mt-2 nav-item ' +
                      (pathname === `/${url}` ? 'selected' : '')
                    }
                  >
                    <a
                      data-path={`/${url}`}
                      // href={`/${url}`}
                      // ////////////////////////////////////////////////////
                      onClick={(event) => {
                        if (
                          isFetchingMerchant /* || isFetchingUser */ ||
                          (isFetchingMerchantCategories && !category)
                        ) {
                          event.preventDefault(); // Prevent navigation if either condition is true
                        } else {
                          handleMenuClick(event); // Call the navigation function if both conditions are false
                        }
                      }}
                      className={`hover-link nav-link d-flex align-items-end
                     ${
                       (isFetchingMerchant /* || isFetchingUser */ ||
                         (isFetchingMerchantCategories && !category)) &&
                       ' disabledElement'
                     }
                     `}
                      style={{ whiteSpace: 'nowrap', height: '40px' }}
                    >
                      <div className="mr-2" style={{ width: '25px' }}>
                        {icon}
                      </div>
                      {__(lang, name)}
                      {url === 'orders' && stats.newOrders > 0 && (
                        <div
                          className="rounded-circle mr-1 text-danger float-right bg-warning "
                          style={{
                            width: '25px',
                            height: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                          }}
                        >
                          <span className="text-white">{stats.newOrders}</span>
                        </div>
                      )}
                    </a>
                  </li>
                ))}
              </Collapse>
            )}
          </ul>
        </nav>
        <nav
          className={`mt-auto d-flex flex-column pb-4 ${
            isAndroid && 'mb-auto'
          }`}
        >
          <h3
            className="px-2 font-weight-bold"
            style={{ color: '#5c5c5c', marginTop: '20px', fontSize: '14px' }}
          >
            Switch product:
          </h3>
          <div
            className="row px-4"
            style={{ display: 'flex', justifyContent: 'start' }}
          >
            <div className="btn-group" role="group" aria-label="...">
              <button
                type="button"
                className={
                  'btn switchB mr-0 ' +
                  (isYuge
                    ? 'btn-warning ac-warning'
                    : 'btn-secondary ac-secondary')
                }
                onClick={handleChangeProduct}
              >
                YUGE
              </button>
              <button
                type="button"
                className={
                  'btn switchB ' +
                  (!isYuge
                    ? 'btn-warning ac-warning'
                    : 'btn-secondary ac-secondary')
                }
                onClick={handleChangeProduct}
              >
                AR Storefront
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default SideBar;
