import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { requestUserInfo, onLogOut } from '../../redux/slices/app';
import docCookies from '../../common/cookie';

const AuthByToken = () => {
  const location = useLocation();
  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const access_token = params.get('access_token');

    if (access_token) {
      async function loadUser() {
        try {
          const userInfoResult = await dispatch(requestUserInfo(access_token));
          if (userInfoResult?.error || !userInfoResult?.payload?.user?.approved) {
            if(!userInfoResult?.payload?.user?.approved === false) {
              alert('Your merchant is pending approval by Yuge management. Please stay tuned for a confirmation email.')
            }
            dispatch(onLogOut());
            push('/login');
            return;
          }
          sessionStorage.setItem('sessionToken', access_token);
          sessionStorage.setItem('isLogin', 'true');
          docCookies.setItem('token', access_token);
          push('/merchant');
        } catch (error) {
          dispatch(onLogOut());
          push('/login');
        }
      }
      loadUser();
    } else {
      dispatch(onLogOut());
      push('/login');
    }
  }, []);

  return (
    <div
      className="row  d-flex  justify-content-center"
      style={{ height: '-webkit-fill-available', alignContent: 'center' }}
    >
      <div className="spinner-border text-warning" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default AuthByToken;
