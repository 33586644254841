import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { BankAccountNotification, Footer, IbanElementStripe, LoadingModal } from '../components';
import OpeningHours from '../components/OpeningHours/OpeningHours';
import { __ } from '../lib/i18n';
import './Merchant.scss';
import { updateBankTransferAccount, saveIbanNumber, changePassword, deleteAccount } from '../redux/slices/merchant';
import { requestCountries } from '../redux/slices/countries';
import { Alert } from 'react-bootstrap';
import { isPassWordStrong } from '../utils/validations';
import CustomAlert from '../components/CustomAlert';
import CustomAlertAndButton from '../components/CustomAlertAndButton';
import TitleComponent from '../components/TitleComponent';
import { onLogOut } from '../redux/slices/app';

const Merchant = () => {
  const _location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [bankAccountProcedureStatus, setBankAccountProcedureStatus] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [changePasswordError, setChangePasswordError] = useState(null);
  const [deleteAccountError, setDeleteAccountError] = useState(null);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(null);
  const [editBankAccount, setEditBankAccount] = useState(false);
  const [toggleFocus, setToggleFocus] = useState(0);
  // const [isYuge, setIsYuge] = useState(true);

  const { isYuge } = useSelector(state => state);

  const {
    app: {
      lang,
      user: {
        hasTransfersBankAccountCreated,
        hasTransfersBankAccountStatusIncomplete,
        transfersBankAccountLast4,
        planId,
      },
    },
    categories: { isFetchingCategories, items: categories },
    merchant: {
      isSaving,
      savingIban,
      isFetchingMerchant,
      item: {
        id,
        companyName,
        description,
        phoneNumber,
        address,
        postalCode,
        city,
        email,
        name,
        salesPerson,
        openingHours,
        country,
        isWebShop,
        categories: categoriesMerchant = [],
      },
    },
    countries: { items: arrCountries = [] },
  } = useSelector(state => state);

  let isFetching = isFetchingCategories || isFetchingMerchant || isSaving;
  let hasCategories = categoriesMerchant && categoriesMerchant.length > 0;
  let countryValue;
  if (country && arrCountries) {
    arrCountries.forEach(countryItem => {
      if (countryItem.country === country) {
        countryValue = countryItem.name[__(lang, 'CURRENT_LANGUAGE')];
      }
    });
  }

  const fields = [
    { key: 'SLUG', value: companyName },
    { key: 'EMAIL', value: email },
    {
      key: 'STORE_DESCRIPTION',
      value: description ? description : 'FILL_INFO',
    },
    { key: 'ONLINE_SHOP', value: isWebShop ? 'YES' : 'NO' },
    { key: 'YOUR_NAME', value: name ? name : 'FILL_INFO' },
    {
      key: 'CONTACT_PERSON',
      value: salesPerson ? salesPerson : 'FILL_INFO',
    },
    {
      key: 'PHONE_NUMBER',
      value: phoneNumber ? phoneNumber : 'FILL_INFO',
    },
    { key: 'ADDRESS', value: address ? address : 'FILL_INFO' },
    {
      key: 'POSTAL_CODE',
      value: postalCode ? postalCode : 'FILL_INFO',
    },
    { key: 'CITY', value: city ? city : 'FILL_INFO' },
    {
      key: 'COUNTRY',
      value: countryValue ? countryValue : 'FILL_INFO',
    },
  ];
  /*const appKey = 'AIzaSyAp1lPD7aFsqkqC_9IC6pjUWtLDUeZk4SU'
  const latLon = location ? `${location[1]},${location[0]}` : undefined
  const imgSrc = location
      ? `https://maps.googleapis.com/maps/api/staticmap?center=${latLon}&zoom=13&size=300x300&maptype=roadmap&markers=color:red%7Clabel:C%7C${latLon}&key=${appKey}`
      : ''*/

  useEffect(() => {
    if (_location.hash === '#bank_account') {
      let link = document.createElement('a');
      link.href = '#bank_account';
      link.click();
    }
  }, [_location.hash]);

  useEffect(() => {
    dispatch(requestCountries({ region: country }));
  }, [country, dispatch]);

  const goToAccountBank = event => {
    event.preventDefault();
    history.push('#bank_account');
    setToggleFocus(toggleFocus + 1);
  };
  const onEditNumberAccount = (stripe, ibanElement) => {
    dispatch(
      updateBankTransferAccount({
        stripe: stripe,
        ibanElement: ibanElement,
        merchantId: id,
        name: name ? name : companyName,
      }),
    );
  };

  const onSaveNumberAccount = (stripe, ibanElement) => {
    dispatch(
      saveIbanNumber({
        stripe: stripe,
        ibanElement: ibanElement,
        merchantId: id,
        name: name ? name : companyName,
      }),
    );
  };

  const queryStringObject = queryString.parse(_location.search);
  useEffect(() => {
    if (queryStringObject['createTransferBankAccount']) {
      setBankAccountProcedureStatus(queryStringObject['createTransferBankAccount']);
    }
  }, [queryStringObject]);

  const changePasswordHandler = event => {
    event.preventDefault();
    if (!oldPassword) {
      setChangePasswordError(__(lang, 'INVALID_CREDENTIALS'));
      return;
    }
    if (!newPassword || !isPassWordStrong(newPassword)) {
      setChangePasswordError(__(lang, 'STRONG_PASSWORD_REQUIRED'));
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setChangePasswordError(__(lang, 'PASSWORDS_DOESNT_MATCH'));
      return;
    }
    dispatch(changePassword({ oldPassword, newPassword }))
      .then(res => {
        if (res && res.payload && res.payload.success) {
          setOldPassword('');
          setNewPassword('');
          setConfirmNewPassword('');
          setChangePasswordSuccess(true);
          return;
        }

        if (res && res.payload && res.payload.error) {
          if (res.payload.errorCode === 401) {
            setOldPassword('');
            setChangePasswordError(__(lang, 'WRONG_PASSWORD'));
          } else {
            setChangePasswordError(__(lang, 'SOMETHING_WENT_WRONG'));
          }
        }
      })
      .catch(error => {
        setChangePasswordError(__(lang, 'SOMETHING_WENT_WRONG'));
        console.log(error);
      });
  };

  const deleteAccountEvent = event => {
    event.preventDefault();
    if (window.confirm(__(lang, 'DELETE_ACCOUNT_CONFIRM'))) {
      deleteAccountHandler();
    }
  };
  const deleteAccountHandler = () => {
    dispatch(deleteAccount())
      .then(res => {
        if (res?.payload?.status === 200) {
          dispatch(onLogOut());
          history.push('/login');
          return;
        }
        if (res?.payload?.error) {
          setDeleteAccountError(__(lang, 'SOMETHING_WENT_WRONG'));
        }
      })
      .catch(error => {
        setDeleteAccountError(__(lang, 'SOMETHING_WENT_WRONG'));
        console.log(error);
      });
  };

  const yugeC = () => (
    <main role="main" className={'container-fluid  pt-3 px-4 '} style={{ paddingLeft: '50px', paddingRight: '25px' }}>
      <TitleComponent title={__(lang, 'MY_ACCOUNT')} />

      {bankAccountProcedureStatus === 'success' && <Alert bsStyle={'success'}>{__(lang, 'IBAN_SUCCESS')}</Alert>}
      {bankAccountProcedureStatus === 'failure' && <Alert bsStyle={'danger'}>{__(lang, 'IBAN_FAILURE')}</Alert>}
      {bankAccountProcedureStatus !== 'success' && country === 'NL' && (
        <BankAccountNotification
          lang={lang}
          href={'#bank_account'}
          goToAccountBank={goToAccountBank}
          hasTransfersBankAccountCreated={hasTransfersBankAccountCreated}
          hasTransfersBankAccountStatusIncomplete={hasTransfersBankAccountStatusIncomplete}
        />
      )}
      <LoadingModal isLoading={isFetching || savingIban} />

      {planId === null && (
        <CustomAlertAndButton
          type={'warning'}
          message={__(lang, 'FREE_PLAN_WARNING')}
          buttonText={__(lang, 'MORE_INFO')}
          onButtonClick={() => history.push('/billing')}
        />
      )}

      <div className="row justify-content-center">
        {!isFetching && (
          <div className="col-sm-12 col-md-6 col-lg-6">
            <p>{__(lang, 'INFO')}:</p>
            <div className="section-bg">
              {fields.map(data => {
                const fillInfo = data.value === 'FILL_INFO';
                const value = fillInfo ? __(lang, data.value) : data.value;

                return (
                  <div className="row form-group" key={data.key}>
                    <div style={{ width: '40%' }}>
                      <label className="font-weight-bold">{__(lang, data.key)}</label>
                    </div>
                    <div style={{ width: '60%' }}>
                      <p style={{ fontStyle: fillInfo ? 'italic' : 'normal' }}>{value}</p>
                    </div>
                  </div>
                );
              })}
              <div className="form-group" style={{ textAlign: 'right' }}>
                <button className="btn btn-default section-btn" onClick={() => history.push('/merchant/edit')}>
                  {__(lang, 'EDIT')}
                </button>
              </div>
            </div>
            {country === 'NL' && (
              <div id="bank_account">
                <p>{__(lang, 'BANK_ACCOUNT')}:</p>
                <div
                  className="section-bg pr-3"
                  id={'_ibanForm'}
                  style={{ paddingBottom: transfersBankAccountLast4 ? 20 : 5 }}>
                  {(editBankAccount ||
                    !hasTransfersBankAccountCreated ||
                    !transfersBankAccountLast4 ||
                    hasTransfersBankAccountStatusIncomplete) && (
                    <IbanElementStripe
                      onSave={onSaveNumberAccount}
                      onEdit={onEditNumberAccount}
                      hasAccount={hasTransfersBankAccountCreated}
                      lang={lang}
                      toggleFocus={toggleFocus}
                    />
                  )}
                  {!editBankAccount &&
                    hasTransfersBankAccountCreated &&
                    transfersBankAccountLast4 &&
                    !hasTransfersBankAccountStatusIncomplete && (
                      <div style={{ textAlign: 'left' }}>
                        <label style={{ fontWeight: 'bold' }}>IBAN:</label>
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ fontWeight: 'bold', color: '#ffc107' }}>
                          {'**** **** **** ****   ' + transfersBankAccountLast4}
                        </span>
                        <button
                          className="btn btn-default section-btn"
                          onClick={() => {
                            setEditBankAccount(true);
                          }}
                          style={{ paddingTop: 0, float: 'right' }}>
                          {__(lang, 'EDIT')}
                        </button>
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        )}
        {!isFetching && (
          <div className="col-sm-12 col-md-6 col-lg-6">
            <p>{__(lang, 'OPENING_HOURS')}:</p>
            <div className="section-bg">
              <OpeningHours lang={lang} openingHours={openingHours} />
              <div className="form-group" style={{ textAlign: 'right' }}>
                <button className="btn btn-default section-btn" onClick={() => history.push('/merchant/opening-hours')}>
                  {__(lang, 'EDIT')}
                </button>
              </div>
            </div>
            <div className="section-bg" style={{ paddingTop: '18px' }}>
              <div className="row form-group" style={{ marginBottom: 0 }}>
                <div style={{ width: '40%' }}>
                  <label className="font-weight-bold" style={{ marginBottom: 0 }}>
                    {__(lang, 'CATEGORIES')}
                  </label>
                </div>
                <div style={{ width: '60%' }}>
                  {hasCategories && (
                    <ul className="list-unstyled" style={{ marginBottom: 0 }}>
                      {categoriesMerchant.map(category => {
                        // Ron TO DO //
                        let temp = categories.find(cat => cat.key === categoriesMerchant[0]);
                        return <li key={category}>{temp.title[lang]}</li>;
                      })}
                    </ul>
                  )}
                </div>
              </div>
              <div className="form-group" style={{ textAlign: 'right' }}>
                <button
                  className="btn btn-default section-btn"
                  onClick={() => history.push('/merchant/categories')}
                  style={{ paddingTop: 0 }}>
                  {__(lang, 'EDIT')}
                </button>
              </div>
            </div>
            <div className="section-bg" style={{ paddingTop: '18px' }}>
              {changePasswordSuccess && (
                <div style={{ marginRight: 5 }}>
                  <CustomAlert type={'info'} onDismiss={() => setChangePasswordSuccess(null)}>
                    {__(lang, 'PASSWORD_CHANGED_SUCCESSFULLY')}
                  </CustomAlert>
                </div>
              )}
              {changePasswordError && (
                <div style={{ marginRight: 5 }}>
                  <CustomAlert type={'error'} onDismiss={() => setChangePasswordError(null)}>
                    {changePasswordError}
                  </CustomAlert>
                </div>
              )}
              <div className="flex flex-col form-group" style={{ marginBottom: 0 }}>
                <div className="mb-4" style={{ width: '40%' }}>
                  <label className="font-weight-bold" style={{ marginBottom: 0 }}>
                    {__(lang, 'CHANGE_PASSWORD')}
                  </label>
                </div>
                <div className="w-100" style={{ paddingRight: 20 }}>
                  <div className="form-group">
                    <label>{__(lang, 'OLD_PASSWORD')}</label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      value={oldPassword}
                      onChange={event => setOldPassword(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>{__(lang, 'NEW_PASSWORD')}</label>
                    <input
                      className="form-control"
                      type="password"
                      name="newPassword"
                      value={newPassword}
                      onChange={event => setNewPassword(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>{__(lang, 'CONFIRM_NEW_PASSWORD')}</label>
                    <input
                      className="form-control"
                      type="password"
                      name="confirmNewPassword"
                      value={confirmNewPassword}
                      onChange={event => setConfirmNewPassword(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group" style={{ textAlign: 'right' }}>
                <button
                  className="btn btn-default section-btn"
                  onClick={changePasswordHandler}
                  style={{ paddingTop: 0 }}>
                  {__(lang, 'SAVE')}
                </button>
              </div>
            </div>
            <div className="section-bg" style={{ paddingTop: '18px' }}>
              {deleteAccountError && (
                <div style={{ marginRight: 5 }}>
                  <CustomAlert type={'error'} onDismiss={() => setDeleteAccountError(null)}>
                    {deleteAccountError}
                  </CustomAlert>
                </div>
              )}
              <div className="flex flex-col form-group" style={{ marginBottom: 0 }}>
                <div className="mb-4" style={{ width: '40%' }}>
                  <label className="font-weight-bold" style={{ marginBottom: 0 }}>
                    {__(lang, 'DELETE_ACCOUNT')}
                  </label>
                </div>
              </div>
              <label>{__(lang, 'DELETE_ACCOUNT_WARNING')}</label>
              <div className="form-group" style={{ textAlign: 'right' }}>
                <button className="btn btn-default section-btn" onClick={deleteAccountEvent} style={{ paddingTop: 0 }}>
                  {__(lang, 'DELETE_ACCOUNT')}
                </button>
              </div>
            </div>
          </div>
        )}
        {!isFetching && (
          <div className="col-sm-6 " style={{ paddingLeft: '5%', paddingRight: '15px' }}>
            {/*<img
                        className="map"
                        width="100%"
                        height="auto"
                        src={imgSrc}
                        style={{ borderRadius: '8px' }}
                        alt={'...'}
                    />*/}
          </div>
        )}
      </div>
      <Footer />
    </main>
  );

  const storefrontC = () => (
    <main role="main" className={'container-fluid  pt-3 px-4 '} style={{ paddingLeft: '50px', paddingRight: '25px' }}>
      <TitleComponent title={__(lang, 'MY_ACCOUNT')} />

      <LoadingModal isLoading={isFetching || savingIban} />

      <div className="row justify-content-center">
        {!isFetching && (
          <>
            <div className="col-sm-12 col-md-6 col-lg-6">
              {planId === null && (
                <CustomAlertAndButton
                  type={'warning'}
                  message={__(lang, 'FREE_PLAN_WARNING')}
                  buttonText={__(lang, 'MORE_INFO')}
                  onButtonClick={() => history.push('/billing')}
                />
              )}
              {bankAccountProcedureStatus === 'success' && (
                <Alert bsStyle={'success'}>{__(lang, 'IBAN_SUCCESS')}</Alert>
              )}
              <div className="section-bg">
                {fields.map(data => {
                  const fillInfo = data.value === 'FILL_INFO';
                  const value = fillInfo ? __(lang, data.value) : data.value;

                  return (
                    <div className="row form-group" key={data.key}>
                      <div style={{ width: '40%' }}>
                        <label className="font-weight-bold">{__(lang, data.key)}</label>
                      </div>
                      <div style={{ width: '60%' }}>
                        <p style={{ fontStyle: fillInfo ? 'italic' : 'normal' }}>{value}</p>
                      </div>
                    </div>
                  );
                })}
                <div className="form-group" style={{ textAlign: 'right' }}>
                  <button className="btn btn-default section-btn" onClick={() => history.push('/merchant/edit')}>
                    {__(lang, 'EDIT')}
                  </button>
                </div>
              </div>
              <div className="section-bg" style={{ paddingTop: '18px' }}>
                <div className="row form-group" style={{ marginBottom: 0 }}>
                  <div style={{ width: '40%' }}>
                    <label className="font-weight-bold" style={{ marginBottom: 0 }}>
                      {__(lang, 'CATEGORIES')}
                    </label>
                  </div>
                  <div style={{ width: '60%' }}>
                    {hasCategories && (
                      <ul className="list-unstyled" style={{ marginBottom: 0 }}>
                        {categoriesMerchant.map(category => {
                          let temp = categories.find(cat => cat.key === categoriesMerchant[0]);
                          return <li key={category}>{temp.title[lang]}</li>;
                        })}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="form-group" style={{ textAlign: 'right' }}>
                  <button
                    className="btn btn-default section-btn"
                    onClick={() => history.push('/merchant/categories')}
                    style={{ paddingTop: 0 }}>
                    {__(lang, 'EDIT')}
                  </button>
                </div>
              </div>
              <div className="section-bg" style={{ paddingTop: '18px' }}>
                {changePasswordSuccess && (
                  <div style={{ marginRight: 5 }}>
                    <CustomAlert type={'info'} onDismiss={() => setChangePasswordSuccess(null)}>
                      {__(lang, 'PASSWORD_CHANGED_SUCCESSFULLY')}
                    </CustomAlert>
                  </div>
                )}
                {changePasswordError && (
                  <div style={{ marginRight: 5 }}>
                    <CustomAlert type={'error'} onDismiss={() => setChangePasswordError(null)}>
                      {changePasswordError}
                    </CustomAlert>
                  </div>
                )}
                <div className="flex flex-col form-group" style={{ marginBottom: 0 }}>
                  <div className="mb-4" style={{ width: '40%' }}>
                    <label className="font-weight-bold" style={{ marginBottom: 0 }}>
                      {__(lang, 'CHANGE_PASSWORD')}
                    </label>
                  </div>
                  <div className="w-100" style={{ paddingRight: 20 }}>
                    <div className="form-group">
                      <label>{__(lang, 'OLD_PASSWORD')}</label>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        value={oldPassword}
                        onChange={event => setOldPassword(event.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>{__(lang, 'NEW_PASSWORD')}</label>
                      <input
                        className="form-control"
                        type="password"
                        name="newPassword"
                        value={newPassword}
                        onChange={event => setNewPassword(event.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>{__(lang, 'CONFIRM_NEW_PASSWORD')}</label>
                      <input
                        className="form-control"
                        type="password"
                        name="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={event => setConfirmNewPassword(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group" style={{ textAlign: 'right' }}>
                  <button
                    className="btn btn-default section-btn"
                    onClick={changePasswordHandler}
                    style={{ paddingTop: 0 }}>
                    {__(lang, 'SAVE')}
                  </button>
                </div>
              </div>
              <div className="section-bg" style={{ paddingTop: '18px' }}>
                {deleteAccountError && (
                  <div style={{ marginRight: 5 }}>
                    <CustomAlert type={'error'} onDismiss={() => setDeleteAccountError(null)}>
                      {deleteAccountError}
                    </CustomAlert>
                  </div>
                )}
                <div className="flex flex-col form-group" style={{ marginBottom: 0 }}>
                  <div className="mb-4" style={{ width: '40%' }}>
                    <label className="font-weight-bold" style={{ marginBottom: 0 }}>
                      {__(lang, 'DELETE_ACCOUNT')}
                    </label>
                  </div>
                </div>
                <label>{__(lang, 'DELETE_ACCOUNT_WARNING')}</label>
                <div className="form-group" style={{ textAlign: 'right' }}>
                  <button
                    className="btn btn-default section-btn"
                    onClick={deleteAccountEvent}
                    style={{ paddingTop: 0 }}>
                    {__(lang, 'DELETE_ACCOUNT')}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </main>
  );

  return isYuge ? yugeC() : storefrontC();
};
export default Merchant;
