import { API_URL_V1 } from '../../common/variables';

export const FETCHING_INVOICES = 'FETCHING_INVOICES';
export const RECEIVED_INVOICES = 'RECEIVED_INVOICES';
export const ERROR_INVOICES = 'ERROR_INVOICES';

const fetchingInvoices = () => ({ type: FETCHING_INVOICES });
const receivedInvoices = invoices => ({ type: RECEIVED_INVOICES, invoices });
const errorInvoices = error => ({ type: ERROR_INVOICES, error });

export const requestInvoices = (last_id = null, quantity = 50000) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(fetchingInvoices());
    return fetch(`${API_URL_V1}/subscriptions/invoices?quantity=${quantity}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${state.app.token}`,
      },
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw res.status;
        }
      })
      .then(result => {
        return dispatch(receivedInvoices(result));
      })
      .catch(err => {
        return dispatch(errorInvoices(err));
      });
  };
};
