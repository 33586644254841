import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ImageEraser from '../../components/ImageEraser';
import { updateProductExtraction } from '../../redux/slices/products';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import back1 from '../../img/bgEraser/back1.png';
import back2 from '../../img/bgEraser/back2.png';
import back3 from '../../img/bgEraser/back3.png';
import back4 from '../../img/bgEraser/back4.png';
import back5 from '../../img/bgEraser/back5.png';
import back6 from '../../img/bgEraser/back6.png';
import { __ } from '../../lib/i18n';

const Eraser = () => {
  const {
    app: { lang },
    products: { selectedProduct, selectedExtraction, isUpdatingExtraction, errorUpdatingExtraction },
  } = useSelector(state => state);
  const { push } = useHistory();
  const [variantId, setVariantId] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const query = window?.location?.search;
    if (query.includes('variant=')) {
      setVariantId(query.split('=')[1]);
    }
  }, []);

  useEffect(() => {
    if (selectedExtraction === null) {
      if (selectedProduct !== null) {
        push(`/products/${selectedProduct.id}/${variantId ? 'edit' : 'extractions'}`);
      } else {
        push('/products');
      }
    }
  }, [selectedExtraction, selectedProduct, push]);

  const saveVariant = event => {
    dispatch(
      updateProductExtraction({
        productId: variantId,
        fileBase64: event.substring(event.indexOf(',') + 1),
        fileName: selectedExtraction.filename,
      }),
    ).then(() => {
      push(`/products/${selectedProduct.id}/edit`);
    });
  };

  const saveProduct = event => {
    dispatch(
      updateProductExtraction({
        productId: selectedProduct.id,
        fileBase64: event.substring(event.indexOf(',') + 1),
        fileName: selectedExtraction.filename,
      }),
    ).then(() => {
      push(`/products2d/${selectedProduct.id}/extractions`);
    });
  };

  const _onSave = event => {
    variantId ? saveVariant(event) : saveProduct(event);
  };

  const _onCancel = () => {
    variantId
      ? push(`/products/${selectedProduct.id}/${'edit'}`)
      : push(`/products2d/${selectedProduct.id}/${'extractions'}`);
  };

  const render = () => {
    if (selectedExtraction === null) return null;

    if (isUpdatingExtraction === true)
      return (
        <div style={{ height: '500px' }} className="flex-grow-1 align-items-center row justify-content-center mt-5">
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );

    return (
      <>
        {errorUpdatingExtraction && (
          <div className="alert alert-danger" role="alert">
            {__(lang, 'ERROR_UPDATING_PRODUCT')}
          </div>
        )}
        <ImageEraser
          backgrounds={[back1, back2, back3, back4, back5, back6]}
          imageSrc={selectedExtraction.URL}
          imageWidth={selectedExtraction.width}
          imageHeight={selectedExtraction.height}
          save={_onSave}
          close={_onCancel}
          sliderThumbColor={'#F6BE50'}
          sliderTrackColor={'#D8D8D8'}
          sliderTrackSelectedColor={'#F6BE50'}
          sliderCircleIndicatorsColor={'#D8D8D8'}
          saveButtonColor={'#F6BE50'}
          textColor={'#F6BE50'}
          labelSave={__(lang, 'SAVE')}
          labelCancel={__(lang, 'CANCEL')}
          disableBackgroundSelector={true}
        />
      </>
    );
  };

  return (
    <div className="container-fluid" style={{ width: '100vw', overflowX: 'hidden' }}>
      <div className="row justify-content-center align-items-center mt-4">{render()}</div>
    </div>
  );
};

export default Eraser;
