import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {API_URL_V1} from '../../common/variables';

const requestMerchants = createAsyncThunk(
  'REQUEST_MERCHANTS',
  async (arg = null, { getState, rejectWithValue }) => {
    try {
      const state = getState();

      let res = await fetch(API_URL_V1 + '/merchants/auth/search?userIsOwner=true', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.app.token}`,
        },
      });
      if (res.status === 200) {
        let result = await res.json();
        return result[0];
      } else return rejectWithValue(res.status);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const merchantsSlice = createSlice({
  name: 'merchants',
  initialState: {
    isFetchingMerchants: false,
    isIndexed: false,
    items: [],
    errorFetchingMerchants: false,
  },
  reducers: {},
  extraReducers: {
    [requestMerchants.pending]: (state) => {
      state.isFetchingMerchants = true;
    },
    [requestMerchants.fulfilled]: (state, action) => {
      state.isFetchingMerchants = false;
      state.items = action.payload;
    },
    [requestMerchants.rejected]: (state, action) => {
      state.errorFetchingMerchants = action.payload;
    },
  },
});

export { requestMerchants };

export default merchantsSlice.reducer;
