import * as actions from '../actions/invoicesActions';
let initialState = { fetching: true, error: false, arrInvoices: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCHING_INVOICES:
      return { ...state, fetching: true };
    case actions.RECEIVED_INVOICES:
      return { ...state, arrInvoices: action.invoices, fetching: false };
    case actions.ERROR_INVOICES:
      return { ...state, error: action.error, fetching: false };
    default:
      return state;
  }
};
