import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pad from '../lib/pad';

export default class Time extends Component {
  render() {
    const { time } = this.props;
    let { hour, minute } = time;
    hour = pad('00', hour, true);
    minute = pad('00', minute, true);

    return (
      <span>
        {hour}:{minute}
      </span>
    );
  }
}

Time.propTypes = {
  time: PropTypes.object.isRequired,
};
