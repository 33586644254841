import React, { useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { __ } from '../../lib/i18n';
import moment from 'moment';
import { getImageUri } from '../../utils/images';

const OrderProductDetails = () => {
  const history = useHistory();
  const {
    app: { lang },
    order: { orderSelected },
  } = useSelector((state) => state);
  useEffect(() => {
    if (orderSelected === null) history.goBack();
  }, [history, orderSelected]);

  const getCountAmount = () => {
    if (orderSelected !== null) {
      const { products } = orderSelected;
      let count = 0,
        amount = 0;
      products.forEach((product) => {
        count += product.count;
        amount += product.amount;
      });

      return [count, Math.abs(amount / 100)];
    }
    return [0, 0];
  };
  const displayProducts = () => {
    if (orderSelected !== null) {
      const { products } = orderSelected;
      return products.map((product, key) => (
        <tr key={key}>
          <td>
            <img
              src={getImageUri(product.product.images, false, true)}
              style={{ width: '70px', height: '70px' }}
              alt=""
            />
          </td>
          <td className="align-middle">
            {product.product.description.length > 0
              ? product.product.description
              : '-'}
          </td>
          <td>
            <p className="mb-0 weg">
              {__(lang, 'WEIGHT')}-{product.product.dimensions.width}
            </p>
            <p className="mb-0 ">
              {__(lang, 'HEIGHT')}-{product.product.dimensions.height}
            </p>
            <p className="mb-0 ">
              {__(lang, 'DEPTH')}-{product.product.dimensions.depth}
            </p>
          </td>
          <td className="align-middle">{product.product.price}</td>
          <td className="align-middle">{product.count}</td>
        </tr>
      ));
    }
    return null;
  };

  const render = () => {
    if (orderSelected !== null) {
      const {
        shippingAddress: { fullName, city, street },
        user: { phoneNumber },
        created,
        shippingStatus,
      } = orderSelected;
      return (
        <main role="main" className={'container-fluid  pt-3 px-4 '}>
          <div className="row justify-content-center">
            <h3 className="font-weight-bold text-warning text-underline">
              {__(lang, 'ORDER_DETAIL')}
            </h3>
          </div>
          <div className="row">
            <div className={'col-sm-12 col-md-4'}>
              <div className={'row'}>
                <div className="col-5">
                  <p className="font-weight-bold">{__(lang, 'NAME')}:</p>
                  <p className="font-weight-bold">{__(lang, 'STREET')}:</p>
                </div>
                <div className="col">
                  <p>{fullName}</p>
                  <p>{street}</p>
                </div>
              </div>
            </div>

            <div className={'col-sm-12 col-md-4'}>
              <div className={'row'}>
                <div className="col-5">
                  <p className="font-weight-bold">{__(lang, 'CITY')}:</p>
                  <p className="font-weight-bold">{__(lang, 'TEL_NUMBER')}:</p>
                  <p className="font-weight-bold">{__(lang, 'COUNT')}:</p>
                </div>
                <div className="col">
                  <p>{city}</p>
                  <p>{phoneNumber}</p>
                  <p>{getCountAmount()[0]}</p>
                </div>
              </div>
            </div>

            <div className={'col-sm-12 col-md-4'}>
              <div className={'row'}>
                <div className="col-5">
                  <p className="font-weight-bold">
                    {__(lang, 'TOTAL_PURCHASE')}:
                  </p>
                  <p className="font-weight-bold">{__(lang, 'CREATED')}:</p>
                  <p className="font-weight-bold">{__(lang, 'STATE')}:</p>
                </div>
                <div className="col">
                  <p>€ {getCountAmount()[1]}</p>
                  <p>{moment(created).format('YYYY/MM/DD HH:mm')}</p>
                  <p className="font-weight-bold text-warning">
                    {shippingStatus.toUpperCase()}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div>
              <h4 className="text-warning text-underline">
                {__(lang, 'PRODUCTS')}
              </h4>{' '}
            </div>
            <table className="table table-striped table-sm table-bordered text-center align-middle">
              <thead>
                <tr>
                  <th>{__(lang, 'IMAGE')}</th>
                  <th>{__(lang, 'DESCRIPTION')}</th>
                  <th>{__(lang, 'DIMENSIONS')}</th>
                  <th>{__(lang, 'PRICE')} €</th>
                  <th>{__(lang, 'COUNT')}</th>
                </tr>
              </thead>
              <tbody>{displayProducts()}</tbody>
            </table>
          </div>
        </main>
      );
    }
    return null;
  };

  return render();
};
export default memo(OrderProductDetails);
