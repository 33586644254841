import React from 'react';
import PropType from 'prop-types';
import { __ } from '../../lib/i18n';

function PricingHeader({ lang, period, handlePeriod }) {
  return (
    <div className="lg:w-1/2 w-full">
      <div className="flex justify-center mb-5 sm:mb-0">
        <div className="w-56">
          <button
            type="button"
            style={{
              border: '1px solid transparent !important',
              outline: 'none !important',
              padding: '0',
            }}
            className="focus:ring-0 border-0 bg-gray-100 shadow rounded-xl flex items-center"
            onClick={() => handlePeriod(period === 'month' ? 'year' : 'month')}>
            <div
              className={`${
                period === 'month' ? 'bg-green-300' : 'bg-gray-100 text-gray-600'
              } font-semibold text-base leading-none rounded-xl py-3 px-6 mr-1`}
              id="month">
              {__(lang, 'MONTHLY')}
            </div>
            <div
              className={`${
                period === 'year' ? 'bg-green-300' : 'bg-gray-100 text-gray-600'
              } font-semibold text-base leading-none rounded-xl py-3 px-6`}
              id="year">
              {__(lang, 'YEARLY')}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

PricingHeader.propTypes = {
  period: PropType.string,
  handlePeriod: PropType.func,
};

export default PricingHeader;
