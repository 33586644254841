import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {API_URL_V1, BRANDING_TYPE} from '../../common/variables';
import { setMerchantIsSaving } from './merchant';

export const checkBrandingStatus = createAsyncThunk(
  'branding/CHECK_BRANDING_STATUS',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;
      const { merchantId } = data;

      if (!merchantId) {
        throw 'Error';
      }

      const res = await fetch(
        API_URL_V1 + `/branding/status/private/${merchantId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (res.status === 200) {
        return await res.json();
      }

      if (res.status === 404) {
        return { notFound: true };
      }

      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      console.log('Error getting branding status', e);
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);

export const createCustomLogo = createAsyncThunk(
  'CREATE_CUSTOM_LOGO',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setMerchantIsSaving(true));
      const { token } = getState().app;
      const { merchantId, file } = data;
      const fd = new FormData();
      fd.append('file', file);

      const res = await fetch(API_URL_V1 + `/branding/${merchantId}/logo`, {
        method: 'POST',
        body: fd,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setMerchantIsSaving(false));
      if (res.status === 200) {
        dispatch(checkBrandingStatus({ merchantId: merchantId }));
        return await res.json(); //branding created
      }
      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      console.log('Error uploading logo for branding', e);
      dispatch(setMerchantIsSaving(false));
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);

export const deleteCustomLogo = createAsyncThunk(
  'DELETE_CUSTOM_LOGO',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setMerchantIsSaving(true));
      const { token } = getState().app;
      const { merchantId } = data;

      const res = await fetch(API_URL_V1 + `/branding/${merchantId}/logo`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setMerchantIsSaving(false));
      if (res.status === 200) {
        return await res.json();
      }
      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      console.log('Error deleting logo for branding', e);
      dispatch(setMerchantIsSaving(false));
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);

export const createCustomDomainBranding = createAsyncThunk(
  'branding/CREATE_CUSTOM_DOMAIN_BRANDING',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;

      const { merchantId, customDomain } = data;

      const bodyData = JSON.stringify({
        domain_name: customDomain,
        type: BRANDING_TYPE.full,
      });

      const res = await fetch(API_URL_V1 + `/branding/${merchantId}`, {
        method: 'POST',
        body: bodyData,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status === 200) {
        return await res.json(); //branding object created
      }
      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      console.log('Error creating branding', e);
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);

export const toggleOnlineBranding = createAsyncThunk(
  'branding/TOGGLE_ONLINE_BRANDING',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;

      const { merchantId, enabled } = data;

      const bodyData = JSON.stringify({
        enabled: enabled,
      });

      const res = await fetch(API_URL_V1 + `/branding/${merchantId}/cdn`, {
        method: 'PUT',
        body: bodyData,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status === 200) {
        return await res.json(); //branding object created
      }
      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      console.log('Error creating branding', e);
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);

export const setBrandingSettings = createAsyncThunk(
  'branding/SET_BRANDING_SETTINGS',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;
      const { merchantId, backActionUrl, brandingType } = data;

      let body = JSON.stringify({
        back_action_url: backActionUrl,
        web_type: brandingType,
      });

      const res = await fetch(API_URL_V1 + `/branding/${merchantId}/settings`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body,
      });

      if (res.status === 200) {
        return await res.json();
      }

      return rejectWithValue({
        error: true,
        errorCode: res.status,
        message: 'Error updating settings',
      });
    } catch (e) {
      console.log('Error updating settings', e);
      return rejectWithValue({
        error: true,
        errorCode: 900,
        message: 'Error updating settings',
      });
    }
  },
);

const initialState = {
  logo: null,
  isLoading2: false,
  isSaving: false,
  error: null,
  success: false,
  successBranding: false,
  branding: null,
};

export const CustomDomainSlice = createSlice({
  name: 'CustomDomain',
  initialState,
  reducers: {
    cleanErrorAndSuccess: (state) => {
      state.error = null;
      state.success = false;
    },
    setCustomDomainError: (state, action) => {
      state.error = action.payload;
    },
    cleanSuccessBranding: (state) => {
      state.successBranding = false;
    },
  },
  extraReducers: {
    [createCustomLogo.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
    },
    [createCustomLogo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.success = true;
      state.branding = action.payload;
    },
    [createCustomLogo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [deleteCustomLogo.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
    },
    [deleteCustomLogo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.success = true;
      state.branding = action.payload;
    },
    [deleteCustomLogo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [createCustomDomainBranding.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
    },
    [createCustomDomainBranding.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.success = true;
      state.branding = action.payload;
    },
    [createCustomDomainBranding.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [checkBrandingStatus.pending]: (state) => {
      state.isLoading2 = true;
      state.error = false;
    },
    [checkBrandingStatus.fulfilled]: (state, action) => {
      state.isLoading2 = false;
      state.error = false;
      const { payload } = action;
      if (payload.notFound) {
        state.branding = payload;
        state.successBranding = false;
      } else {
        state.successBranding = true;
        state.branding = payload;
      }
    },
    [checkBrandingStatus.rejected]: (state, action) => {
      state.isLoading2 = false;
      state.error = action.payload;
    },
    [toggleOnlineBranding.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
    },
    [toggleOnlineBranding.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.success = true;
      state.branding = action.payload;
    },
    [toggleOnlineBranding.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [setBrandingSettings.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
    },
    [setBrandingSettings.fulfilled]: (state) => {
      state.isLoading = false;
      state.error = false;
    },
    [setBrandingSettings.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  cleanErrorAndSuccess,
  setCustomDomainError,
  cleanSuccessBranding,
} = CustomDomainSlice.actions;

export default CustomDomainSlice.reducer;
