// using live true will use services in production, using false will use services in development
export const LIVE = window.location.href.includes('https://merchant.goyuge.com');

export const API_URL_V1 = LIVE ? 'https://api.goyuge.com/v1' : 'https://dev.api.goyuge.com/v1';

//message timeouts and other configs
export const configParams = {
  feedbackTimeout: 7000,
};

//Branding constants
export const LOGO_STATUS = {
  notCreated: 'LOGO_NOT_CREATED',
  created: 'LOGO_CREATED',
};
export const CDN_STATUS = {
  notCreated: 'CDN_NOT_CREATED',
  enabled: 'CDN_CREATED_ENABLED',
  disabled: 'CDN_CREATED_DISABLED',
  errorExist: 'CDN_WITH_ERROR_ALREADY_EXIST',
};
export const CERTIFICATE_STATUS = {
  notCreated: 'CERTIFICATE_NOT_CREATED',
  issued: 'CERTIFICATE_ISSUED',
  creating: 'CERTIFICATE_CREATING',
  pendingValidation: 'CERTIFICATE_PENDING_VALIDATION',
};

export const BRANDING_TYPE = {
  light: 'BRANDING_TYPE_LIGHT',
  full: 'BRANDING_TYPE_FULL',
};
