import React from 'react';
import Chart from 'react-google-charts';
import PropTypes from 'prop-types';
import LoadingModal from '../LoadingModal/Loading';

const BarChart = (props) => {
  const {
    width = '350px',
    height = '220px',
    data,
    title = '',
    subtitle = '',
  } = props;
  return (
    <Chart
      width={width}
      height={height}
      chartType="Bar"
      loader={<LoadingModal isLoading={true} />}
      data={data}
      options={{
        chart: {
          title: title,
          subtitle: subtitle,
        },
      }}
    />
  );
};
BarChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
export default BarChart;
