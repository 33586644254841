// @ts-nocheck
import { lazy } from 'react';

// PRODUCT
export const LazyProductsAdd = lazy(() => import('../containers/ProductsAdd'));
export const LazyProductsEdit = lazy(
  () => import('../containers/ProductsEdit'),
);
export const LazyProductsEditImage = lazy(
  () => import('../containers/ProductsEditImage'),
);
export const LazyProductsExtractions = lazy(
  () => import('../containers/ProductsExtractions'),
);
export const LazyProductsProductsModel3D = lazy(
  () => import('../containers/ProductsModel3D'),
);
export const LazyProductsLinks = lazy(
  () => import('../containers/ProductsLinks'),
);
export const LazyProducts = lazy(() => import('../containers/ProductsIndex'));
export const LazyEraserContainer = lazy(() => import('../containers/eraser'));
export const LazyTransformContainer = lazy(
  () => import('../containers/transform'),
);

//MERCHANT
export const LazyMerchantEdit = lazy(
  () => import('../containers/MerchantEdit'),
);
export const LazyMerchantOpeningHours = lazy(
  () => import('../containers/MerchantOpeningHours'),
);
export const LazyMerchantCategories = lazy(
  () => import('../containers/MerchantCategories'),
);
export const LazyInvoices = lazy(() => import('../containers/invoices'));
export const LazyMerchant = lazy(() => import('../containers/Merchant'));

// MIX
export const LazyWebsite = lazy(() => import('../containers/Website'));
export const LazyCustomDomain = lazy(
  () => import('../containers/customDomain'),
);
export const LazyNewCustomDomain = lazy(
  () => import('../containers/storefrontDomain/newCustomDomain'),
);
export const LazyFAQ = lazy(() => import('../containers/FAQ'));
export const LazyAnalytics = lazy(() => import('../containers/analytics'));
export const LazyCustomers = lazy(() => import('../containers/customer'));
export const LazyOrderProductDetails = lazy(
  () => import('../containers/orders/OrderProductDetails'),
);
export const LazyOrders = lazy(() => import('../containers/orders'));
export const LazyBilling = lazy(() => import('../containers/billing'));
// For some reason LazyMerchant is also used in this path /stripe
