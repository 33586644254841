import React from 'react';
import { __ } from '../../lib/i18n';

const RowOrder = (props) => {
  const {
    id,
    shippingAddress: { fullName, city, street },
    user: { phoneNumber },
    products,
  } = props.order;
  const { lang = 'en', orderState } = props;

  const getCount = () => {
    let count = 0,
      amount = 0;
    products.forEach((product) => {
      count += product.count;
      amount += product.amount;
    });
    return [count, amount];
  };

  const _onDetail = (evt) => {
    evt.preventDefault();
    props.onSelectOrderDetail(props.order);
  };
  const _onChangeStatusNewShipping = (evt) => {
    evt.preventDefault();
    props.changeMerchantOrderStatusNewToShipping(id);
  };

  const _onChangeStatusShippingNew = (evt) => {
    evt.preventDefault();
    props.changeMerchantOrderStatusToShippingNew(id);
  };
  const getBtn = () => {
    if (orderState === 'shipping') {
      return (
        <td className="align-middle">
          <button
            className="btn btn-outline-secondary"
            onClick={_onChangeStatusShippingNew}
          >
            {__(lang, 'CANCEL')}
          </button>
        </td>
      );
    }
    if (orderState === 'delivered') {
      return null;
    }
    return (
      <td className="align-middle">
        <button
          className="btn btn-outline-secondary"
          onClick={_onChangeStatusNewShipping}
        >
          {__(lang, 'SHIPPING')}
        </button>
      </td>
    );
  };
  return (
    <tr className="align-middle">
      <td className="align-middle">{fullName}</td>
      <td className="align-middle">{street}</td>
      <td className="align-middle">{city}</td>
      <td className="align-middle">{phoneNumber}</td>
      <td className="align-middle">{getCount()[0]}</td>
      <td className="align-middle">€ {getCount()[1]}</td>
      <td className="align-middle">
        <button className="btn text-primary text-underline" onClick={_onDetail}>
          {__(lang, 'VIEW')}
        </button>
      </td>
      {getBtn()}
    </tr>
  );
};

export default RowOrder;
