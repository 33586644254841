import React, { useEffect, useState } from 'react'
import QRCode from "react-qr-code";
import download from '../../img/download.svg';
import share from '../../img/share.svg';
import qrCode from '../../img/qr-code.svg';
import { useSelector } from 'react-redux';
import { selectBranding } from '../../redux/selectors/customDomainSelectors';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import linkedinIcon from '../../img/icons/LinkedIn.svg'
import whatsappIcon from '../../img/icons/Whatsapp.svg'
import twitterIcon from '../../img/icons/Twitter.svg'
import facebookIcon from '../../img/icons/Facebook.svg'
import linkIcon from '../../img/icons/Link.svg'
import {__} from '../../lib/i18n';
import {RootState} from "../../redux/store";

const ProductsAsTable = (props) => {
    const {
        product: {
            id,
            title,
            images,
            price,
            currency,
            description,
            sold,
            externalBuyUrl,
        },
    } = props;
    const {app: { lang }} = useSelector((state: RootState) => state);

    const branding = useSelector(selectBranding);
    const baseUrl = branding?.domain?.cdn_dns_deployed
        ? `https://${branding.domain.domain_name}`
        : 'https://goyuge.com';

    const handleClickDownload = (event) => {
        event.preventDefault();
        let finalURL = images[0].URL;

        const splitedFilename = images[0].filename.split('.');
        const fileExtension = splitedFilename[splitedFilename.length - 1];

        fetch(finalURL)
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${title || 'download'}.${fileExtension}`;
                a.click();
                return a;
            });
    };

    const [showQR, setShowQR] = useState(false);

    const handleShowQr = (event) => {
        event.preventDefault();
        setShowQR(true);
    };

    const [showShare, setShowShare] = useState(false);

    const handleShowShare = (event) => {
        event.preventDefault();
        setShowShare(true);
    };

    useEffect(() => {
        if (showQR) {
            const productHtml = document.getElementById('pr' + id + title);
            if (productHtml)
                productHtml.focus();
        }
    }, [showQR])

    return (
        <div className='w-100 ' style={{ backgroundColor: '#f8f9fa', border: '1px solid #cfccc0', marginTop: '-1px', }}>
            <div style={{ width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ height: '60px', width: '60px', backgroundColor: '#a5a194' }}>
                    <img
                        alt="thumb"
                        className="pl-link-img"
                        src={images[0]?.thumbBigURLWebp}
                        width="100%"
                        height="100%"
                    />
                </div>
                <div title={title} className='title-container px-2' style={{ display: 'flex', justifyContent: 'center', color: '#948f7f', flexBasis: .3, flexGrow: 1, borderRight: '2px solid #ffc107' }}>
                    <div className=' title-container'>{title?.length ? title : '-'} {/* &nbsb; */}</div>
                </div>
                <div title={'File size'} className='title-container' style={{ display: 'flex', justifyContent: 'center', color: '#948f7f', flexBasis: .3, flexGrow: 1, borderRight: '2px solid #ffc107' }}>
                    <div className=' title-container'>File size</div>
                </div>
                <div style={{ display: 'flex', gap: '12px', flexBasis: .3, flexGrow: 1, justifyContent: 'space-evenly' }}>
                    <button
                        className="btn btn-download buttonBigWhenHover m-0"
                        onClick={handleClickDownload}
                    >
                        <img
                            id="img-download"
                            title="download"
                            src={download}
                            alt={'...'}
                            style={{ width: '30px', height: '30px', }}
                        />
                    </button>
                    <button
                        className="btn btn-download buttonBigWhenHover m-0"
                        type="button" data-toggle="modal" data-target={"#shareModal" + id}
                    >
                        <img
                            id="img-share"
                            title="share"
                            src={share}
                            alt={'...'}
                            style={{ width: '30px', height: '30px', }}
                        />
                    </button>
                    <button
                        className="btn btn-download buttonBigWhenHover m-0"
                        type="button" data-toggle="modal" data-target={"#qrModal" + id}
                    >
                        <img
                            id="img-qrCode"
                            title="QR-Code"
                            src={qrCode}
                            alt={'...'}
                            style={{ width: '30px', height: '30px', }}
                        />
                    </button>
                </div>
            </div>
            {/* MODAL */}
            {id &&
                <div className="modal fade" id={"shareModal" + id} tabIndex={-1} aria-labelledby={"shareModalLabel" + id} aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border-0" style={{ backgroundColor: '#ffc107', boxSizing: 'border-box', paddingLeft: '25px', paddingRight: '25px', borderRadius: '12px' }}>
                            <div className="modal-header border-bottom-0" style={{ width: '100%' }}>
                                <div
                                    style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                                >
                                    <h5 className="modal-title text-white mt-4" id={"shareModalLabel" + id}
                                    >
                                        Link copied to clipboard
                                    </h5>
                                </div>

                                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                 </button> */}
                            </div>
                            <div className="modal-body ">
                                <div className='d-flex justify-content-center w-100'>
                                    <a
                                        className="blue-hover2 text-white"
                                        style={{ wordBreak: 'break-word' }}
                                        target="blank"
                                        href={`${baseUrl}/product/${id}`}
                                    >

                                        {baseUrl}/product/{id}
                                    </a>
                                </div>
                                <div className='d-flex justify-content-center w-100 mt-4'>
                                    <h5 className="modal-title text-white" id={"shareModalLabel" + id}
                                    >
                                        Share it with others
                                    </h5>
                                </div>
                                <div className='d-flex justify-content-center w-100 mt-4'>
                                    {/* @ts-ignore */}
                                    <WhatsappShareButton
                                        url={`${baseUrl}/product/${id}`}
                                        title={title}
                                        separator=":: "
                                        className="opacc"
                                    >
                                        <img src={whatsappIcon} alt='lnkdn' style={{ width: '32px', height: '32px', borderRadius: '10px' }} />
                                        {/* <WhatsappIcon size={32} round /> */}
                                    </WhatsappShareButton>

                                    {/* @ts-ignore */}
                                    <TwitterShareButton
                                        url={`${baseUrl}/product/${id}`}
                                        title={title}
                                        className="opacc">
                                        <img src={twitterIcon} alt='lnkdn' style={{ width: '32px', height: '32px', borderRadius: '10px' }} />

                                        {/* <TwitterIcon size={32} round /> */}
                                    </TwitterShareButton>

                                    {/* @ts-ignore */}
                                    <FacebookShareButton
                                        url={`${baseUrl}/product/${id}`}
                                        title={title}
                                        className="opacc"
                                    >
                                        <img src={facebookIcon} alt='lnkdn' style={{ width: '32px', height: '32px', borderRadius: '10px' }} />
                                        {/* <FacebookIcon size={32} round /> */}
                                    </FacebookShareButton>

                                    {/* @ts-ignore */}
                                    <LinkedinShareButton
                                        url={`${baseUrl}/product/${id}`}
                                        title={title}
                                        className="opacc">
                                        <img src={linkedinIcon} alt='lnkdn' style={{ width: '32px', height: '32px', borderRadius: '10px' }} />
                                    </LinkedinShareButton>

                                    {/* @ts-ignore */}
                                    {/* <CustomTooltip text={title}> */}
                                    <div
                                        title={title}
                                        className="opacc "
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                `${baseUrl}/product/${id}`,
                                            )
                                        }
                                    >
                                        <img src={linkIcon} alt='lnkdn' style={{ width: '32px', height: '32px', borderRadius: '10px' }} />
                                    </div>
                                    {/* </CustomTooltip> */}
                                </div>

                            </div>
                            <div className="modal-footer border-top-0 d-flex justify-content-center pt-0">
                                <button type="button" className="btn btn-warning px-4" style={{ backgroundColor: '#ffda6b', color: '#5c5236', borderRadius: '10px' }} data-dismiss="modal">CLOSE</button>
                                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* END MODAL */}

            {/* QR MODAL */}
            {id &&
                <div className="modal fade" id={"qrModal" + id} tabIndex={-1} aria-labelledby={"qrModalLabel" + id} aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border-0" style={{ backgroundColor: '#ffc107', boxSizing: 'border-box', paddingLeft: '25px', paddingRight: '25px', borderRadius: '12px' }}>
                            <div
                                id={'pr' + id + title}
                                onBlur={() => setShowQR(false)}
                                className='mt-3'
                                style={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', boxSizing: 'border-box',
                                    borderRadius: '12px',
                                    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    position: 'relative',
                                    width: '100%',
                                    backgroundColor: '#ffc107'
                                }}
                                tabIndex={0}
                            >
                                <div style={{ width: 'calc(100% - 66px)', height: 'calc(100% - 66px)', padding: 0, margin: 0, marginTop: '12px', backgroundColor: '#ffc107', }}>
                                  <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={`${baseUrl}/product/${id}`}
                                    viewBox="0 0 256 256"
                                    bgColor="rgba(1, 1, 1, 0)"
                                  />
                                </div>
                                {/* <div style={{ textAlign: 'center', padding: '6px', marginBottom: '6px', height: '48px', color: 'white', fontWeight: 600 }}>{title}</div> */}
                                <div style={{ textAlign: 'center', padding: '6px', marginBottom: '6px', height: '48px', color: 'white', fontWeight: 600 }}>{__(lang, 'SCAN_QR_CODE')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* END QR MODAL */}
        </div>
    )
}

export default ProductsAsTable
