import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Time from '../Time';
import { __ } from '../../lib/i18n';
import days from '../../lib/days.json';

import './OpeningHours.scss';

export default class OpeningHours extends Component {
  render() {
    const { lang, openingHours = {} } = this.props;

    return (
      <div className="OpeningHours">
        {days.map((day) => {
          const openingHour = openingHours[day];
          return (
            <div key={day} className="row form-group">
              <div className="col-5">
                <label className="font-weight-bold">{__(lang, day)}</label>
              </div>
              <div className="col-7">
                {openingHour === undefined && <span>-</span>}
                {openingHour &&
                  openingHour.map((time, index) => {
                    return (
                      <span key={`${day}.${index}`}>
                        <Time time={time} />
                        {index % 2 === 0 && ' - '}
                        {index % 2 === 1 && (
                          <span className="OpeningHoursOpen">
                            {__(lang, 'OPEN')}
                          </span>
                        )}
                        {index % 2 === 1 && <br />}
                      </span>
                    );
                  })}
                {openingHour === null && (
                  <span className="OpeningHoursClosed">
                    {__(lang, 'CLOSED')}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

OpeningHours.propTypes = {
  lang: PropTypes.string.isRequired,
  openingHours: PropTypes.object,
};
