import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ImageTransformer from '../../components/ImageTransformer';
import { updateProductExtraction } from '../../redux/slices/products';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

const Transformer = () => {
  const {
    products: { selectedProduct, selectedExtraction, isUpdatingExtraction },
  } = useSelector(state => state);
  const { push } = useHistory();
  const [variantId, setVariantId] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const query = window?.location?.search;
    if (query.includes('variant=')) {
      setVariantId(query.split('=')[1]);
    }
  }, []);

  useEffect(() => {
    if (selectedExtraction === null) {
      if (selectedProduct !== null) {
        variantId
          ? push(`/products/${selectedProduct.id}/${'edit'}`)
          : push(`/products2d/${selectedProduct.id}/${'extractions'}`);
      } else {
        push('/products');
      }
    }
  }, [selectedExtraction, selectedProduct, push]);

  const saveVariant = event => {
    dispatch(
      updateProductExtraction({
        productId: variantId,
        fileBase64: event.substring(event.indexOf(',') + 1),
        fileName: selectedExtraction.filename,
      }),
    ).then(() => {
      push(`/products/${selectedProduct.id}/edit`);
    });
  };

  const saveProduct = event => {
    dispatch(
      updateProductExtraction({
        productId: selectedProduct.id,
        fileBase64: event.substring(event.indexOf(',') + 1),
        fileName: selectedExtraction.filename,
      }),
    ).then(() => {
      push(`/products2d/${selectedProduct.id}/extractions`);
    });
  };

  const _onSave = event => {
    variantId ? saveVariant(event) : saveProduct(event);
  };

  const _onCancel = () => {
    variantId
      ? push(`/products/${selectedProduct.id}/${'edit'}`)
      : push(`/products2d/${selectedProduct.id}/${'extractions'}`);
  };

  const render = () => {
    if (selectedExtraction === null) return null;

    if (isUpdatingExtraction === true)
      return (
        <div style={{ height: '500px' }} className="flex-grow-1 align-items-center row justify-content-center mt-5">
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );

    return <ImageTransformer close={_onCancel} save={_onSave} imageSrc={selectedExtraction.URL} />;
  };

  return (
    <div className="container-fluid" style={{ width: '100vw', overflowX: 'hidden' }}>
      <div className="row justify-content-center align-items-center mt-4">{render()}</div>
    </div>
  );
};

export default Transformer;
