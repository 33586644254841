import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { __ } from '../../lib/i18n';
import { isMobile } from '../../utils/isMobile';
import './styles.css';

const urlsFooter = [
  {
    name: 'ABOUT',
    link: 'https://goyuge.com',
  },
  // {
  //   name: 'TERMS',
  //   link: 'https://terms-of-service.goyuge.com/',
  // },
  // {
  //   name: 'PRIVACY',
  //   link: 'https://privacy-policy.goyuge.com/',
  // },
  // {
  //   name: 'FAQ',
  //   link: 'https://faq-en.goyuge.com/',
  // },
  {
    name: 'CONTACT_PAGE',
    link: 'https://goyuge.com/contact',
  },
  // {
  //   name: 'STATUS_PAGE',
  //   link: 'https://stats.uptimerobot.com/0BWw7fkqNG',
  // },
];

const Footer = () => {
  const { lang } = useSelector((state) => state.app);

  const [device, setDevice] = useState(0);

  const checkDevice = () => {
    if (isMobile.iOS()) {
      setDevice(1);
    } else if (isMobile.Android()) {
      setDevice(2);
    }
  };

  useEffect(() => {
    if (window) checkDevice();
  }, []);

  return (
    <footer
      className="row justify-content-sm-start justify-content-sm-around"
      style={{ marginTop: 'auto' }}
    >
      <div className="col-sm-12 col-md-8 text-center text-light small">
        <p className="border-top pt-3 links mb-2">
          {urlsFooter.map(({ name, link }) => (
            <a key={name} target="__blank" href={link}>
              {__(lang, name)}
            </a>
          ))}
        </p>
        <p className="links mb-2">
          <DownloadLinks device={device} />
        </p>
        <p className="mb-0">
          Yuge Technologies B.V. &copy; 2015 - {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};

const ANDROIDL = () => (
  <a
    target="__blank"
    className="download-link"
    href="https://play.google.com/store/apps/details?id=com.goyuge.merchant&hl=en&gl=US"
  >
    Download for Android
  </a>
);

const IOSL = () => (
  <a
    target="__blank"
    className="download-link"
    href="https://apps.apple.com/nl/app/yuge-merchant/id6443528979?l=en"
  >
    Download for iOS
  </a>
);

const IOSL3DScanner = () => (
  <a
    target="__blank"
    className="download-link"
    href="https://apps.apple.com/us/app/3d-scanner-yuge/id6474502283"
  >
    Download 3D scanner
  </a>
);

const DownloadLinks = (props) => {
  // const { device } = props;
  // if (device === 0)
  return (
    <>
      {/* <IOSL />
        <ANDROIDL /> */}
      <IOSL3DScanner />
    </>
  );
  // if (device === 1) return <IOSL />;
  // return <ANDROIDL />;
};

export default Footer;
