import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CustomAlertAndButton = (props) => {
  const { type, message, onButtonClick, buttonText } = props;

  return (
    <Alert bsStyle={type}>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <strong>{message}</strong>
        <Button
          bsStyle={type}
          style={{ float: 'right', color: 'white' }}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </div>
    </Alert>
  );
};

CustomAlertAndButton.defaultProps = {
  buttonText: 'Ok',
};

CustomAlertAndButton.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired, // danger, success, warning
  onButtonClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

export default CustomAlertAndButton;
