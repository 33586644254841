import React, { useEffect, useState } from 'react';
import { __ } from '../lib/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { productUploadExtractions_3d } from '../redux/slices/products';
import { RootState } from '../redux/store';
import ArSupported from '../utils/arSupported';

type Upload3dModelInputProps = {
  product?: any;
  productId: string;
  onComplete: (success: boolean, limitReached?: boolean) => void;
  onUploading: (isUpl: boolean) => void;
  // onLimitReached?: (isLimitReached: boolean) => void;
};

const Upload3dModelInput = ({
  product,
  productId,
  onComplete,
  onUploading,
}: // onLimitReached
Upload3dModelInputProps) => {
  const { isAndroid, isIOS } = ArSupported();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    onUploading(isUploading);
  }, [isUploading]);

  const {
    app: { lang },
    products: { lastProductId3DModelUploaded },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (lastProductId3DModelUploaded && lastProductId3DModelUploaded === productId) {
      setIsUploading(false);
      onComplete(true);
    }
  }, [lastProductId3DModelUploaded]);

  const handleFileChange_3D = event => {
    event.preventDefault();
    const f3d = event.target.files[0];
    const extType = f3d.name.endsWith('.glb') ? 'glb' : f3d.name.endsWith('.usdz') ? 'usdz' : null;
    if (extType) {
      setIsUploading(true);
      dispatch(
        // @ts-ignore
        productUploadExtractions_3d({
          productId,
          file: f3d,
          fileType: extType,
        }),
        // @ts-ignore
      ).then(r => {
        console.log('rrrrOUT', r);

        if (r.type === 'UPLOAD_EXTRACTION_3D/fulfilled') {
          console.log('rrrrIN', r);

          setIsUploading(false);
          onComplete(true);
        } else {
          setIsUploading(false);
          onComplete(false);
        }
      });
    } else {
      setIsUploading(false);
      // Handle unsupported file type here
      console.error('Unsupported file type');
    }
  };

  const handleChange_3d = event => {
    event.preventDefault();
    if (!product || !product.glbFilesUploaded || product.glbFilesUploaded.length < 3) {
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = handleFileChange_3D;
      input.accept = '.usdz, .glb, model/*, application/octet-stream';

      input.click();
    } else {
      // setVisible3dMaxSnackbar(true);
      // onLimitReached(true);
      onComplete(false, true);
      setIsUploading(false);

      //Auto hide snackbar
      // setTimeout(() => {
      //   setVisible3dMaxSnackbar(false);
      // }, 3500);
    }
  };

  return (
    <>
      {!(isAndroid || isIOS) ? (
        <button
          className="btn-image-action buttonBigWhenHover"
          style={{
            paddingLeft: '15px',
            paddingTop: '10px',
            whiteSpace: 'nowrap',
          }}
          onClick={handleChange_3d}>
          {__(lang, 'ADD_3D_SCAN')}
        </button>
      ) : (
        <button
          className={`btn btn-warning small mb-0`}
          style={{
            color: '#FFF',
            minHeight: '40px',
            borderRadius: '20px',
            marginRight: 0,
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
          onClick={handleChange_3d}>
          {' '}
          {__(lang, 'ADD_3D_SCAN_MOBILE')}
        </button>
      )}
    </>
  );
};

export default Upload3dModelInput;
