import React, { useState, useEffect } from 'react';
import ArSupported from '../utils/arSupported';

export default function AndroidSmartBanner() {
  const { isAndroid, isIOS } = ArSupported();
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (isAndroid) localStorage.setItem('android_install_banner_closed', false);
    return () => {
      if (isAndroid)
        localStorage.setItem('android_install_banner_closed', false);
    };
  }, [isAndroid]);

  const handleClose = () => {
    localStorage.setItem('android_install_banner_closed', true);
    setButtonClicked(true);
  };

  const handleInstall = () => {
    // handleClose();
    const redirectUrl =
      /* isIOS ? */ 'https://apps.apple.com/us/app/3d-scanner-yuge/id6474502283'; /* : ""  */
    // window.location = "https://play.google.com/store/apps/details?id=com.goyuge.merchant&hl=en&gl=US";
    window.location = redirectUrl;
  };

  if (
    (!isAndroid && !isIOS) ||
    localStorage?.getItem('android_install_banner_closed') !== 'false' ||
    buttonClicked
  )
    return <></>;

  return (
    // <div className="w-full flex flex-row items-center h-18 p-2" style={{ background: "#000" }}>
    //   <div className="col-2 flex flex-row justify-end p-0">
    //     <img className="w-full" src="/yuge-merchant-app.png" alt="Yuge merchant tool app" />
    //   </div>
    //   <div className="col-7 p-2">
    //     <h2 className="m-0" style={{ fontSize: "1.25rem", color: "rgb(249,250,251)", fontWeight: 100 }}>Yuge Merchant</h2>
    //     <p className="m-0" style={{ fontSize: "0.875rem", color: "rgb(249,250,251)", fontWeight: 100 }}>Augmented Reality for Webshops</p>
    //   </div>
    //   <div className="col-3 p-0">
    //     <button
    //       type="button"
    //       onClick={handleInstall}
    //       className="w-full m-0 text-white py-2 rounded-full"
    //       style={{
    //         background: "rgb(59,130,246)",
    //         border: "none"
    //       }}
    //     >
    //       OPEN
    //     </button>
    //   </div>
    // </div>
    <div
      className="w-full flex flex-row items-center h-18"
      style={{ background: '#007bff', padding: 0, margin: 0 }}
    >
      <button
        type="button"
        className="btn btn-primary"
        style={{ width: '100%', height: '100%', margin: 0, padding: '12px' }}
        onClick={handleInstall}
      >
        <div style={{ marginLeft: '30px', textAlign: 'left' }}>
          Download Yuge 3D Scanner
        </div>
        <div style={{ marginLeft: '30px', textAlign: 'left' }}>
          from AppleStore
        </div>
      </button>
    </div>
  );
}
