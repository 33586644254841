import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import docCookies from '../../common/cookie';
import { API_URL_V1 } from '../../common/variables';

const initialAppState = {
  lang: 'en',
  isFetchingUser: false,
  errorLogin: false,
  isLogin: false,
  forgottenError: null,
  isRequestingForgottenPass: false,
  isConfirmingForgottenPass: false,
  confirmingForgottenError: null,
};

const forgottenPasswordRequest = createAsyncThunk(
  'ON_FORGOTTEN_PASSWORD',
  async ({ email, locale }, { rejectWithValue }) => {
    try {
      let res = await fetch(API_URL_V1 + '/users/password/recovery', {
        body: JSON.stringify({ username: email, locale }),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        return { isRequestingForgottenPass: false, forgottenError: false };
      } else
        return rejectWithValue({
          isRequestingForgottenPass: false,
          forgottenError: res.status,
        });
    } catch (err) {
      // console.log(err);
      return rejectWithValue({
        isRequestingForgottenPass: false,
        forgottenError: 900,
      });
    }
  },
);

const confirmForgottenPasswordRequest = createAsyncThunk(
  'ON_CONFIRM_FORGOTTEN_PASSWORD',
  async ({ email, code, password }, { rejectWithValue }) => {
    try {
      let res = await fetch(API_URL_V1 + '/users/password/recovery', {
        body: JSON.stringify({
          username: email,
          securityCode: code,
          password,
        }),
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        return {
          isConfirmingForgottenPass: false,
          confirmingForgottenError: false,
        };
      } else
        return rejectWithValue({
          isConfirmingForgottenPass: false,
          confirmingForgottenError: res.status,
        });
    } catch (err) {
      // console.log(err);
      return rejectWithValue({
        isConfirmingForgottenPass: false,
        confirmingForgottenError: 900,
      });
    }
  },
);

const requestUserInfo = createAsyncThunk('ON_REQUEST_USER_INFO', async token => {
  let res = await fetch(API_URL_V1 + '/merchants/auth/search?userIsOwner=true', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    let merchants = await res.json();
    if (merchants?.length) {
      return { isFetching: false, user: merchants[0], token: token, error: false };
    }
    return { isFetching: false, token: token, error: true };
  } else {
    throw new Error(res.status.toString());
  }
});

const onLogin = createAsyncThunk('ON_LOGGING', async (arg, { rejectWithValue }) => {
  try {
    const { email, password } = arg;
    let res = await fetch(API_URL_V1 + '/token', {
      body: `username=${email}&password=${password}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (res.status === 200) {
      let resToken = await res.json();
      return { user: {}, access_token: resToken.access_token };
    }

    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (err) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    onLogOut(state) {
      localStorage.clear();
      sessionStorage.removeItem('isLogin');
      sessionStorage.removeItem('sessionToken');
      sessionStorage.clear();
      docCookies.removeItem('token');
      Cookies.remove('token');
      state.lang = 'en';
      state.isFetchingUser = false;
      state.errorLogin = false;
      state.isLogin = false;
    },
    onChangeLanguage(state, action) {
      state.lang = action.payload;
    },
  },
  extraReducers: {
    [requestUserInfo.fulfilled]: (state, action) => {
      sessionStorage.clear();
      // localStorage.clear();
      sessionStorage.setItem('sessionToken', action.payload.token);
      sessionStorage.setItem('isLogin', 'true');
      state = {
        ...state,
        isFetching: false,
        isLogin: true,
        user: action.payload.user,
        token: action.payload.token,
        error: false,
      };
      return state;
    },
    [requestUserInfo.pending]: state => {
      // state.isLogin = false;
      state.error = false;
    },
    [requestUserInfo.rejected]: state => {
      state.error = true;
      state.isLogin = false;
    },
    [onLogin.pending]: state => {
      state.isFetchingUser = true;
      state.errorLogin = false;
      state.isLogin = false;
    },
    [onLogin.fulfilled]: (state, action) => {
      state.isFetchingUser = true;
      state.errorLogin = false;
      state.isLogin = true;
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('sessionToken', action.payload.access_token);
      docCookies.setItem('token', action.payload.access_token, '86400');
      state.user = action.payload.user;
      state.token = action.payload.access_token;
    },
    [onLogin.rejected]: (state, action) => {
      state.isFetchingUser = false;
      state.errorLogin = action.payload;
      state.isLogin = false;
    },
    [forgottenPasswordRequest.pending]: state => {
      state.isRequestingForgottenPass = true;
      state.forgottenError = null;
    },
    [forgottenPasswordRequest.fulfilled]: state => {
      state.isRequestingForgottenPass = false;
      state.forgottenError = null;
    },
    [forgottenPasswordRequest.rejected]: (state, action) => {
      state.isRequestingForgottenPass = false;
      state.forgottenError = action.payload;
    },
    [confirmForgottenPasswordRequest.pending]: state => {
      state.isConfirmingForgottenPass = true;
      state.confirmingForgottenError = null;
    },
    [confirmForgottenPasswordRequest.fulfilled]: state => {
      state.isConfirmingForgottenPass = false;
      state.confirmingForgottenError = null;
    },
    [confirmForgottenPasswordRequest.rejected]: (state, action) => {
      state.isConfirmingForgottenPass = false;
      state.confirmingForgottenError = action.payload;
    },
  },
});

export const { onLogOut, onChangeLanguage } = appSlice.actions;

export { requestUserInfo, onLogin, forgottenPasswordRequest, confirmForgottenPasswordRequest };

export default appSlice.reducer;
