// @ts-noCheck
import React, { memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { __ } from '../../lib/i18n';
import { onLogOut } from '../../redux/slices/app';
import { LanguagePicker } from '../Header/Header';
import { onChangeLanguage } from '../../redux/slices/app';
import logo from '../../img/logo.png';
import profile from '../../img/icons/profile.png';

import styles from '../Header/Header.module.scss';
import './Menu.scss';
import { useState } from 'react';
import { setProduct } from '../../redux/slices/isYuge';
import { useEffect } from 'react';
import { LIVE } from '../../common/variables';
import { changePage, changePageVariants, resetStateProducts } from '../../redux/slices/products';
import { RootState } from '../../redux/store';

const Menu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(0);

  const [homeUrl, setHomeUrl] = useState('');

  useEffect(() => {
    if (LIVE) setHomeUrl('https://goyuge.com');
    else {
      const href = window.location.href;
      const prefix = href.split('-');

      if (prefix[0] === href) {
        setHomeUrl('https://dev-web-app-spa.goyuge.com/');
      } else {
        setHomeUrl(prefix[0] + '-web-app-spa.goyuge.com/');
      }
    }
  }, []);

  const { isYuge } = useSelector((state: RootState) => state);
  const {
    app: { lang },
    merchant: {
      slug,
      item: { country } /* , isYuge */,
    },
  } = useSelector((state: RootState) => state);

  const geturlsYuge = () => {
    return country === 'NL' ? [...urlsYuge, orders] : urlsYuge;
  };

  const handleClick = event => {
    event.preventDefault();
    // let menu = document.getElementById('navbarText');
    // menu.classList.remove('show');
    const path = event.target.getAttribute('data-path');
    history.push(path);
    handleMenuOpen(open);
  };

  const _onLogOut = async event => {
    event.preventDefault();
    await dispatch(resetStateProducts());
    await dispatch(onLogOut());
    await dispatch(changePage());
    await dispatch(changePageVariants());
    history.push('/login');
    handleMenuOpen(!open);
  };

  const _handleChangeLanguage = lang => {
    dispatch(onChangeLanguage(lang));
  };

  const handleMenuOpen = item => {
    let v = item;
    if (open == item) v = 0;
    setOpen(v);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    // Close the menu if it's open and the window width is less than 990px
    if (open && window.innerWidth > 992) {
      handleMenuOpen(0);
    }
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    return () => {
      // Remove event listener on component unmount
      window.removeEventListener('resize', handleResize);
    };
  }, [open]); // Re-run the effect when the menu state changes

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-warning d-flex flex-column"
        style={{ backgroundColor: '#FFB300', position: 'relative' }}>
        <div className="d-flex w-100">
          <div className="d-flex d-lg-none justify-content-start d-lg-none col-4 p-0">
            <button
              className="navbarButton mr-0"
              type="button"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => handleMenuOpen(2)}>
              {open === 2 ? <CloseMenu /> : <BurguerMenu />}
            </button>
          </div>
          <div className={styles.header_language_picker + ' col-6 p-1 d-none d-lg-block'}>
            <LanguagePicker handleChangeLanguage={_handleChangeLanguage} type="transparent" />
          </div>
          <div
            className={styles.header_logo + ' col-2 align-self-center text-center mx-auto'}
            style={{ paddingRight: '5px' }}>
            <a href={homeUrl} style={{ color: '#FFF' }}>
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="d-flex justify-content-end d-lg-none col-4 p-0">
            <button
              className="navbarButton mr-0"
              type="button"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => handleMenuOpen(1)}>
              <img src={profile} alt="" width="30px" />
            </button>
          </div>
          <div className="col-4 dropdown d-none d-sm-none d-md-none d-lg-flex justify-content-end">
            <button
              style={{ color: '#FFF', margin: '0' }}
              className="btn dropdown-toggle d-flex justify-content-center align-items-center"
              type="button"
              id="dropdownMenu1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true">
              {slug?.slug}
              <img src={profile} alt="" style={{ marginRight: '.3rem', marginLeft: '.6rem' }} width="30px" />
              <span className="caret" style={{ color: '#FFF !important' }}></span>
            </button>
            <UserOptions lang={lang} handleClick={handleClick} />
          </div>
        </div>
        <LogoutModal lang={lang} _onLogOut={_onLogOut} />
      </nav>
      <NavbarOptions
        lang={lang}
        urls={urls}
        handleClick={handleClick}
        _onLogOut={_onLogOut}
        location={location}
        open={open}
      />
      <ProductOptions
        lang={lang}
        urls={isYuge ? geturlsYuge() : urlsStorefront}
        handleClick={handleClick}
        _onLogOut={_onLogOut}
        location={location}
        open={open}
      />
    </>
  );
};

const LogoutModal = ({ lang, _onLogOut }) => (
  <div
    className="modal fade"
    id="exampleModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title redLogout" id="exampleModalLabel">
            {__(lang, 'LOGOUT')}{' '}
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">Are you sure you want to log out?</div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">
            {__(lang, 'CANCEL')}
          </button>
          <button type="button" className="btn btn-warning redLogout" data-dismiss="modal" onClick={_onLogOut}>
            {__(lang, 'LOGOUT')}
          </button>
        </div>
      </div>
    </div>
  </div>
);

const UserOptions = ({ lang, handleClick }) => (
  <div className="dropdown-menu dropdown-menu-right mt-3" style={{ right: '17px' }}>
    <a className="dropdown-item" href="/merchant" data-path="/merchant" onClick={handleClick}>
      {__(lang, 'MY_ACCOUNT')}
    </a>
    <a className="dropdown-item" href="/billing" data-path="/billing" onClick={handleClick}>
      {__(lang, 'MY_PLAN')}
    </a>
    <a className="dropdown-item" href="/merchant/invoices" data-path="/merchant/invoices" onClick={handleClick}>
      {__(lang, 'INVOICES')}
    </a>
    <a className="dropdown-item cursor-pointer redLogout" data-toggle="modal" data-target="#exampleModal" role="button">
      {__(lang, 'LOGOUT')}
    </a>
  </div>
);

const NavbarOptions = ({ lang, urls, handleClick, location, open }) => (
  <div className={`collapse navbar-collapse ${open == 1 ? 'show' : ''}`} id="NavbarOptions">
    <div className="dropdown d-none d-sm-none d-md-none d-lg-block">
      <UserOptions lang={lang} handleClick={handleClick} mobile={true} />
    </div>

    <div className="d-flex menu-wrap d-lg-none">
      <ul className="navbar-nav py-11">
        {urls.map(({ url, name }) => (
          <li key={name} className="nav-item">
            <a
              className={'nav-link ' + (location.pathname === `/${url}` ? 'active' : '')}
              data-path={`/${url}`}
              href={`/${url}`}
              onClick={handleClick}
              style={{ color: '#939393' }}>
              {__(lang, name)}
            </a>
          </li>
        ))}
        <li className="nav-item">
          <a
            className="nav-link cursor-pointer redLogout"
            data-toggle="modal"
            data-target="#exampleModal"
            role="button"
            style={{ color: '#939393' }}>
            {__(lang, 'LOGOUT')}
          </a>
        </li>
        <ProductSwitch />
      </ul>
    </div>
  </div>
);

const ProductOptions = ({ lang, urls, handleClick, _onLogOut, location, open }) => (
  <div className={`collapse navbar-collapse ${open == 2 ? 'show' : ''}`} id="ProductOptions">
    <div className="dropdown d-none d-sm-none d-md-none d-lg-block">
      <div className="dropdown-menu dropdown-menu-right">
        <a className="dropdown-item" data-path="/merchant" href="/merchant" onClick={handleClick}>
          {__(lang, 'MY_ACCOUNT')}
        </a>
        <a className="dropdown-item" data-path="/billing" href="/billing" onClick={handleClick}>
          {__(lang, 'MY_PLAN')}
        </a>
        <a className="dropdown-item" href="/merchant/invoices" data-path="/merchant/invoices" onClick={handleClick}>
          {__(lang, 'INVOICES')}
        </a>
        <a className="dropdown-item redLogout" data-path="/logout" href="/logout" onClick={_onLogOut}>
          {__(lang, 'LOGOUT')}
        </a>
      </div>
    </div>

    <div className="d-flex menu-wrap d-lg-none">
      <ul className="navbar-nav py-11">
        {urls.map(({ url, name }) => (
          <li key={name} className="nav-item">
            <a
              className={'nav-link ' + (location.pathname === `/${url}` ? 'active' : '')}
              data-path={`/${url}`}
              // href={`/${url}`}
              onClick={handleClick}
              style={{ color: '#939393' }}>
              {__(lang, name)}
            </a>
          </li>
        ))}
        <ProductSwitch />
      </ul>
    </div>
  </div>
);

function ProductSwitch() {
  const dispatch = useDispatch();

  const { isYuge } = useSelector(state => state);

  const getProduct = () => {
    try {
      const sYuge = localStorage.getItem('sYuge');
      dispatch(setProduct(sYuge == 'true'));
    } catch (e) {}
  };

  const handleChangeProduct = () => {
    dispatch(setProduct(!isYuge));
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <li className="nav-item" style={{ marginTop: 'auto' }}>
      <a onClick={handleChangeProduct} className={isYuge ? 'active' : ''}>
        YUGE
      </a>
      <a onClick={handleChangeProduct} className={!isYuge ? 'active' : ''}>
        Storefront
      </a>
    </li>
  );
}

const BurguerMenu = () => (
  <svg width={30} height={30} xmlns="http://www.w3.org/2000/svg">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30} fill="#fff">
      <path
        d="M3 8h18a1 1 0 0 0 0-2H3a1 1 0 0 0 0 2Zm18 8H3a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2Zm0-5H3a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2Z"
        className="color000 svgShape"
        fill="#fff"
      />
    </svg>
  </svg>
);

const CloseMenu = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width={30}
    height={30}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
);

const urls = [
  {
    name: 'MY_ACCOUNT',
    url: 'merchant',
  },
  {
    name: 'MY_PLAN',
    url: 'billing',
  },
  {
    name: 'INVOICES',
    url: 'merchant/invoices',
  },
];

const urlsYuge = [
  {
    url: 'products',
    name: 'PRODUCT_LIST',
  },
  {
    url: 'domain',
    name: 'YUGE_PROFILE',
  },
  {
    url: 'analytics',
    name: 'ANALYTICS',
  },
];

const orders = {
  url: 'orders',
  name: 'ORDERS',
};

const urlsStorefront = [
  {
    url: 'products',
    name: 'PRODUCT_LIST',
  },
  {
    url: 'productslinks',
    name: 'SMART_LINKS',
  },
  {
    url: 'website',
    name: 'AR_STORE_FRONTS',
  },
  {
    url: 'storefrontdomain',
    name: 'DOMAIN',
  },
];

export default memo(Menu);
