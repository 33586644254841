import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL_V1 } from '../../common/variables';

const requestAnalytics = createAsyncThunk('REQUEST_ANALYTICS', async (arg, { getState, rejectWithValue }) => {
  try {
    const { merchantId } = arg;
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/stats/merchant?merchantId=' + merchantId, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    }
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    isFetchingAnalytics: false,
    arrAnalytics: [],
    errorAnalytics: false,
  },
  reducers: {},
  extraReducers: {
    [requestAnalytics.pending]: state => {
      state.isFetchingAnalytics = true;
      state.errorAnalytics = false;
    },
    [requestAnalytics.fulfilled]: (state, action) => {
      state.isFetchingAnalytics = false;
      state.errorAnalytics = false;
      state.arrAnalytics = action.payload;
    },
    [requestAnalytics.rejected]: (state, action) => {
      state.isFetchingAnalytics = true;
      state.errorAnalytics = action.payload;
    },
  },
});

export { requestAnalytics };

export default analyticsSlice.reducer;
