import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL_V1 } from '../../common/variables';

const initialCategoriesState = {
  fetchDone: false,
  isFetchingCategories: true,
  items: [],
  errorFetchingCategories: false,
  isCreatingCategory: false,
  errorCreatingCategory: false,
  category: [],
};

const requestCategories = createAsyncThunk('REQUEST_CATEGORIES', async (id, { rejectWithValue }) => {
  try {
    let res = await fetch(API_URL_V1 + '/categories', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return await res.json();
    } else return rejectWithValue(res.status);
  } catch (e) {
    return rejectWithValue(e);
  }
});

//TODO: this function is unfinished as far I can see it's the same as previous one
const addCategory = createAsyncThunk('ADD_CATEGORY', async (id, { rejectWithValue }) => {
  try {
    let res = await fetch(API_URL_V1 + '/categories/search', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return await res.json();
    } else return rejectWithValue(res.status);
  } catch (e) {
    return rejectWithValue(e);
  }
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: initialCategoriesState,
  reducers: {},
  extraReducers: {
    [requestCategories.pending]: state => {
      state.isFetchingCategories = true;
      state.fetchDone = true;
    },
    [requestCategories.fulfilled]: (state, action) => {
      state.items = action.payload; //.filter(obj=>obj.publish===true)
      state.errorFetchingCategories = false;
      state.isFetchingCategories = false;
      state.fetchDone = true;
    },
    [requestCategories.rejected]: (state, action) => {
      state.errorFetchingCategories = action.payload;
      state.fetchDone = true;
    },
    [addCategory.pending]: state => {
      state.isCreatingCategory = true;
    },
    [addCategory.fulfilled]: (state, action) => {
      state.isCreatingCategory = false;
      state.category = action.payload;
    },
    [addCategory.rejected]: (state, action) => {
      state.errorCreatingCategory = action.payload;
    },
  },
});
export { requestCategories, addCategory };
export default categoriesSlice.reducer;
