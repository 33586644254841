/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import PropType from 'prop-types';
import PricingCountUp from './pricingCountUp';
import PlanFeatures from './planFeatures';
import { __ } from '../../lib/i18n';

function BasicPricingCard({ lang, amount, subscribed, handleClick, disabled, freeTrial }) {
  function PriceAmount() {
    return <PricingCountUp price={amount} startFrom={0} />;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <>
      <div
        role="listitem"
        className="bg-yellow-200 shadow rounded-xl mt-3 flex relative z-30 w-full md:w-80"
        // onClick={handleChangeSelectedPlan}
        style={{ minHeight: '350px' }}>
        <div className="w-full p-4 flex flex-col justify-between">
          <p className="m-0 text-center text-4xl font-bold text-black">
            YUGE <br /> mobile <br /> APP
          </p>
          <div>
            <p className="leading-10 text-center text-4xl md:mt-0 mt-4 font-bold leading-6 mb-1">
              {'€'} <PriceAmount />
            </p>
            <p className="text-center mb-4 text-lg font-semibold">{`{${__(lang, 'EXT_BTW')}}`}</p>
            {freeTrial && <p className="text-center font-semibold text-lg my-4">{__(lang, 'FREE_TRIAL_PRICING')}</p>}
            <button
              onClick={handleClick}
              className={`bg-gray-50 bg-opacity-80 w-full text-black border border-gray-200 font-semibold py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${disabled ? '' : 'hover:bg-blue-500 hover:text-white active:bg-blue-600'}`}
              type="button"
              disabled={disabled}>
              {__(lang, subscribed ? 'SUBSCRIBED' : 'SUBSCRIBE')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function RecommendedPricingCard({ lang, name, freeTrial, amount, description, subscribed, disabled, handleClick }) {
  function PriceAmount() {
    return <PricingCountUp price={amount} startFrom={0} />;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      role="listitem"
      className="bg-green-300 shadow rounded-xl flex flex-col relative z-30 w-full md:w-80"
      style={{ minHeight: '400px' }}>
      <p className="text-lg flex justify-center font-bold mb-2">
        <span className="p-2 px-4 bg-green-100 bg-opacity-80 rounded-b-xl ">{__(lang, 'RECOMMENDED')}</span>
      </p>
      <div className="w-full p-4 flex flex-col justify-between">
        <p className="m-0 text-center text-primary px-4 text-4xl font-bold" style={{ color: '#000' }}>
          {__(lang, name)}
        </p>
        <div>
          <p className="text-base p-3 font-bold">{__(lang, description)}</p>
          <p className="leading-10 text-center md:mt-0 mt-4 font-bold leading-6 text-5xl mb-1">
            {'€'} <PriceAmount />
          </p>
          <p className="text-center text-lg font-semibold">{`{${__(lang, 'EXT_BTW')}}`}</p>
          {freeTrial && <p className="text-center font-semibold text-lg my-4">{__(lang, 'FREE_TRIAL_PRICING')}</p>}
          <button
            onClick={handleClick}
            type="button"
            className={`bg-gray-50 bg-opacity-80 w-full text-black border border-gray-200 font-semibold py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
            disabled={disabled}>
            {__(lang, subscribed ? 'SUBSCRIBED' : 'SUBSCRIBE')}
          </button>
        </div>
      </div>
    </div>
  );
}

function FlexiblePricingCard({ lang, name, description, handleClick }) {
  return (
    <div
      role="listitem"
      className="bg-gray-200 shadow rounded-xl mt-3 flex relative z-30 w-full md:w-80"
      // onClick={handleChangeSelectedPlan}
      style={{ minHeight: '370px' }}>
      <div className="w-full p-4 flex flex-col justify-between">
        <p className="m-0 text-center text-4xl text-black">{__(lang, name)}</p>
        <p className="text-base p-3 font-bold">{__(lang, description)}</p>
        <button
          type="button"
          className={`bg-gray-50 bg-opacity-80 w-full text-black border-2 border-gray-400 font-semibold py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
          onClick={handleClick}>
          {__(lang, 'GET_QUOTE')}
        </button>
      </div>
    </div>
  );
}

function PricingCard(props) {
  const { features, handleChangeSelectedPlan, id } = props;

  let component = <BasicPricingCard handleClick={handleChangeSelectedPlan} {...props} />;
  if (props.featured) component = <RecommendedPricingCard handleClick={handleChangeSelectedPlan} {...props} />;
  if (id === 'enterprise') component = <FlexiblePricingCard handleClick={handleChangeSelectedPlan} {...props} />;

  return (
    <div className="w-full md:w-auto">
      {component}
      <div className="d-block">
        <PlanFeatures lang={props.lang} features={features} />
      </div>
    </div>
  );
}

PricingCard.propTypes = {
  id: PropType.string,
  name: PropType.string,
  amount: PropType.number,
  description: PropType.string,
  featured: PropType.bool,
  billingPeriod: PropType.string,
  handleChangeSelectedPlan: PropType.func,
  freeTrial: PropType.bool.isRequired,
  features: PropType.array,
  subscribed: PropType.bool,
};

export default memo(PricingCard);
