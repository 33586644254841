import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { __ } from '../lib/i18n';
import { changeCategory } from '../redux/slices/merchantCategories';
import { changePage, requestMerchantPods } from '../redux/slices/products';
import styles from './CategoriesTab.module.css';
import { RootState } from '../redux/store';
import Skeleton from 'react-loading-skeleton';
import './Product/Product.scss';

function CategoriesTab() {
  const dispatch = useDispatch();
  const {
    app: { lang },
    merchantCategories: { category, merchantItems, isFetchingMerchantCategories },
    products: { isFetchingProducts },
  } = useSelector((state: RootState) => state);

  const handleChangeCategory = async key => {
    if (isFetchingProducts) return;
    await dispatch(changeCategory(key));
    await dispatch(changePage());
    await dispatch(requestMerchantPods());
  };

  return (
    <nav className={styles.categoryContainer}>
      {isFetchingMerchantCategories && !category ? (
        <>
          <Skeleton style={{ width: '90px', height: '22px', margin: '6px' }} />
          <Skeleton style={{ width: '90px', height: '22px', margin: '6px' }} />
          <Skeleton style={{ width: '90px', height: '22px', margin: '6px' }} />
          <Skeleton style={{ width: '90px', height: '22px', margin: '6px' }} />
          <Skeleton style={{ width: '90px', height: '22px', margin: '6px' }} />
        </>
      ) : (
        merchantItems.map(({ id, publish, key }) => {
          return (
            <button
              key={id}
              onClick={() => handleChangeCategory(key)}
              className={
                'buttonSimpleGreyHover ' +
                styles.categoryTab +
                ' ' +
                (key === category ? styles.active : '') +
                ' ' +
                (!publish && styles.notActive)
              }>
              {key}
            </button>
          );
        })
      )}
      <button
        onClick={() => handleChangeCategory('ALL')}
        className={'buttonSimpleGreyHover ' + styles.categoryTab + ' ' + ('ALL' === category ? styles.active : '')}>
        {__(lang, 'ALL')}
      </button>
    </nav>
  );
}

export default CategoriesTab;
