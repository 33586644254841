import React, { useEffect, useState } from 'react';
import back1 from '../ImageEraser/resources/img/squares.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faExpandArrowsAlt,
  faRedo,
  faSearchPlus,
  faSync,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { getCanvasBase64 } from '../../utils/editor/getCanvasBase64';

const Canvas = ({ imageSrc, save, close }) => {
  const canvasRef = React.useRef(null);
  const imgRef = React.useRef(null);

  const [rotation, setRotation] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [imgSrc, setImgSrc] = useState('');
  const [moving, setMoving] = useState(false);
  const [x, setX] = useState();
  const [y, setY] = useState();

  const [option, setOption] = useState(null);

  const [index, setIndex] = useState(0);
  const [canvasHistory, setCanvasHistory] = useState([{ image: imageSrc }]);

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    if (!imgSrc) {
      setImgSrc(imageSrc);
    }
  }, []);

  useEffect(() => {
    const handleTouchMove = (event) => {
      event.preventDefault();
    };

    document.getElementsByTagName('body')[0].style.overscrollBehavior =
      'contain';
    document.getElementsByTagName('body')[0].style.position = 'fixed';
    document.getElementsByTagName('body')[0].style.overflowX = 'hidden';

    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.getElementsByTagName('body')[0].style.overscrollBehavior =
        'auto';
      document.getElementsByTagName('body')[0].style.position = 'relative';
      document.getElementsByTagName('body')[0].style.overflowX = 'auto';

      document.removeEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    };
  }, []);

  useEffect(() => {
    // Actualizar la referencia de la imagen con el valor actualizado de imgSrc
    imgRef.current.crossOrigin = 'anonymous';
    imgRef.current.src = imgSrc;
  }, [imgSrc]);

  useEffect(() => {
    if (canvasRef.current && x && y) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.drawImage(
        imgRef.current,
        x - imgRef.current.width / 2,
        y - imgRef.current.height / 2,
        canvasRef.current.width,
        canvasRef.current.height,
      );
    }
  }, [width, height]);

  const init = () => {
    setX(imgRef.current.width / 2);
    setY(imgRef.current.height / 2);

    setWidth(imgRef.current.width);
    setHeight(imgRef.current.height);
  };

  const onSave = async () => {
    const content = getCanvasBase64(canvasRef);
    save(content);
  };

  const rotateImage = (rotation) => {
    canvasRef.current.height = canvasRef.current.width;
    const ctx = canvasRef.current.getContext('2d');
    ctx.setTransform(
      zoomLevel,
      0,
      0,
      zoomLevel,
      canvasRef.current.width / 2,
      canvasRef.current.height / 2,
    );
    ctx.rotate(rotation * (Math.PI / 180));
    ctx.drawImage(
      imgRef.current,
      x - imgRef.current.width,
      y - imgRef.current.height,
      canvasRef.current.width,
      canvasRef.current.height,
    );
    setCanvasHistory([
      ...canvasHistory.slice(0, index + 1),
      {
        image: getCanvasBase64(canvasRef),
        x,
        y,
        rotation,
        zoomLevel,
      },
    ]);
    setIndex(index + 1);
  };

  const handleRotation = (e) => {
    const rot = e.target.value;
    rotateImage(rot);
    setRotation(rot);
  };

  const handleZoom = (e) => {
    const zoom = e.target.value;
    applyZoom(zoom);
    setZoomLevel(zoom);
  };

  const applyZoom = async (zoomLevel) => {
    const ctx = canvasRef.current.getContext('2d');
    canvasRef.current.width = canvasRef.current.width;
    ctx.setTransform(
      zoomLevel,
      0,
      0,
      zoomLevel,
      canvasRef.current.width / 2,
      canvasRef.current.height / 2,
    );
    ctx.rotate(rotation * (Math.PI / 180));
    ctx.clearRect(
      -canvasRef.current.width / 2,
      -canvasRef.current.height / 2,
      canvasRef.current.width,
      canvasRef.current.height,
    );
    ctx.drawImage(
      imgRef.current,
      x - imgRef.current.width,
      y - imgRef.current.height,
      canvasRef.current.width,
      canvasRef.current.height,
    );
    setCanvasHistory([
      ...canvasHistory.slice(0, index + 1),
      {
        image: getCanvasBase64(canvasRef),
        x,
        y,
        rotation,
        zoomLevel,
      },
    ]);
    setIndex(index + 1);
  };

  const handleMove = (event, mobile) => {
    if (!moving && !mobile) return;

    const rect = event.target.getBoundingClientRect();

    const mouseX =
      ((event.clientX - rect.left) / (rect.right - rect.left)) *
      canvasRef.current.width;
    const mouseY =
      ((event.clientY - rect.top) / (rect.bottom - rect.top)) *
      canvasRef.current.height;

    setX(mouseX);
    setY(mouseY);

    const ctx = canvasRef.current.getContext('2d');
    canvasRef.current.width = canvasRef.current.width;

    const centerX = canvasRef.current.width / 2;
    const centerY = canvasRef.current.height / 2;
    const offsetX = mouseX - centerX;
    const offsetY = mouseY - centerY;

    const rad = (-rotation * Math.PI) / 180;
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);
    const x = offsetX * cos - offsetY * sin;
    const y = offsetX * sin + offsetY * cos;

    ctx.translate(centerX, centerY);
    ctx.rotate(rotation * (Math.PI / 180));
    ctx.scale(zoomLevel, zoomLevel);
    ctx.drawImage(
      imgRef.current,
      x - canvasRef.current.width / 2,
      y - canvasRef.current.height / 2,
      canvasRef.current.width,
      canvasRef.current.height,
    );
    ctx.restore();
  };

  const handleOption = (opt) => {
    if (opt === option) {
      setOption(null);
    } else {
      setOption(opt);
    }
  };

  const handleHistory = (value) => {
    const prevImage = new Image();
    const {
      x: xx,
      y: yy,
      image: imagSrc,
      zoomLevel: zl,
      rotation: rt,
    } = canvasHistory[index + value];
    prevImage.src = imagSrc;

    const loadImage = new Promise((resolve, reject) => {
      prevImage.onload = () => resolve(prevImage);
      prevImage.onerror = () => reject(new Error('Failed to load image'));
    });

    loadImage
      .then((image) => {
        const ctx = canvasRef.current.getContext('2d');
        canvasRef.current.width = canvasRef.current.width;
        ctx.drawImage(
          image,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height,
        );
        setIndex((prev) => prev + value);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="mx-2">
      <div id="canvasRef">
        {width && height && (
          <canvas
            onClick={() => {
              if (moving) {
                handleOption('move');
                setCanvasHistory([
                  ...canvasHistory.slice(0, index + 1),
                  {
                    image: getCanvasBase64(canvasRef),
                  },
                ]);
                setIndex(index + 1);
                setMoving(false);
              }
            }}
            onTouchEnd={() => {
              setCanvasHistory([
                ...canvasHistory.slice(0, index + 1),
                {
                  image: getCanvasBase64(canvasRef),
                },
              ]);
              setIndex(index + 1);
            }}
            onMouseMove={handleMove}
            onTouchMove={(e) => handleMove(e.touches[0], true)}
            ref={canvasRef}
            className="rounded-lg"
            width={width}
            height={height}
            style={{
              backgroundImage: `url(${back1})`,
              width: '100%',
              maxWidth: '530px',
              aspectRatio: '1/1',
            }}
          />
        )}
      </div>
      <div className="flex flex-row justify-between flex-wrap">
        <div className="mb-2">
          <button
            onClick={() => handleOption('rotate')}
            className={`m-0 mr-2 btn btn${
              option === 'rotate' ? '' : '-outline'
            }-warning eraser-buttons`}
          >
            <FontAwesomeIcon icon={faSync} />
          </button>
          <button
            onClick={() => handleOption('zoom')}
            className={`m-0 mr-2 btn btn${
              option === 'zoom' ? '' : '-outline'
            }-warning eraser-buttons`}
          >
            <FontAwesomeIcon icon={faSearchPlus} />
          </button>
          <button
            onClick={() => {
              handleOption('move');
              setMoving(!moving);
            }}
            className={`m-0 mr-2 btn btn${
              option === 'move' ? '' : '-outline'
            }-warning eraser-buttons`}
          >
            <FontAwesomeIcon icon={faExpandArrowsAlt} />
          </button>
          <button
            onClick={() => {
              handleHistory(-1);
            }}
            className="m-0 mr-2 btn btn-outline-warning eraser-buttons"
            disabled={index === 0}
          >
            <FontAwesomeIcon icon={faUndo} />
          </button>
          <button
            onClick={() => {
              handleHistory(1);
            }}
            className="m-0 mr-2 btn btn-outline-warning eraser-buttons"
            disabled={index + 1 === canvasHistory.length}
          >
            <FontAwesomeIcon icon={faRedo} />
          </button>
        </div>
        <div>
          <button
            className="m-0 mr-2 btn btn-outline-warning eraser-buttons"
            onClick={onSave}
          >
            Save
          </button>
          <button
            className="m-0 btn btn-outline-warning eraser-buttons"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </div>
      {option === 'rotate' && (
        <div className="flex flex-col mb-2">
          <h5 className="mt-2">Rotate:</h5>
          <input
            defaultValue={rotation}
            type="range"
            className="custom-range"
            id="customRange1"
            min="0"
            max="360"
            step="5"
            onChange={(e) => handleRotation(e)}
          />
        </div>
      )}
      {option === 'zoom' && (
        <div className="flex flex-col mb-2">
          <h5 className="mt-2">Zoom:</h5>
          <input
            defaultValue={zoomLevel}
            type="range"
            className="custom-range"
            id="customRange1"
            min="0.1"
            max="2.0"
            step="0.1"
            onChange={(e) => handleZoom(e)}
          />
        </div>
      )}
      <img
        onLoad={() => init()}
        ref={imgRef}
        style={{ display: 'none' }}
        src={imgSrc}
      />
    </div>
  );
};

export default Canvas;
