import React, { useEffect, useState, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { __ } from '../../lib/i18n';
import { Paginator, RowOrder, LoadingModal, Footer } from '../../components';
import emptyOrdersImg from '../../img/orders.jpg';

import {
  requestOrders,
  selectOrder,
  changeOrderNewToShipping,
  changeOrderShippingToNew,
} from '../../redux/slices/orders';

const Orders = () => {
  const dispatch = useDispatch();
  const {
    app: { lang },
    order: {
      isFetchingOrderPagin,
      isFetchingOrder,
      updatingOrder,
      errorOrder,
      errorOrderPagin,
      paginator,
      arrOrders,
      changingOrderStatus,
      errorUpdating,
    },
    merchant: {
      item: { id: merchantId },
    },
  } = useSelector(state => state);

  const history = useHistory();
  const [nElem, setNElem] = useState(10);
  const [cPage, setCPage] = useState(1);
  const [orderState, setOrderState] = useState('new');
  const [filterOrder, setFilterOrder] = useState({});
  const [filterPaginator, setFilterPaginator] = useState({});
  const [arr, setArr] = useState([]);
  const tHeadNew = [
    __(lang, 'NAME'),
    __(lang, 'STREET'),
    __(lang, 'CITY'),
    __(lang, 'TEL_NUMBER'),
    __(lang, 'COUNT'),
    __(lang, 'TOTAL_PURCHASE'),
    __(lang, 'DETAIL'),
    __(lang, 'SHIPPING'),
  ];
  const tHeadShiiping = [
    __(lang, 'NAME'),
    __(lang, 'STREET'),
    __(lang, 'CITY'),
    __(lang, 'TEL_NUMBER'),
    __(lang, 'COUNT'),
    __(lang, 'TOTAL_PURCHASE'),
    __(lang, 'DETAIL'),
    __(lang, 'CANCEL'),
  ];
  const tHeadDelivered = [
    __(lang, 'NAME'),
    __(lang, 'STREET'),
    __(lang, 'CITY'),
    __(lang, 'TEL_NUMBER'),
    __(lang, 'COUNT'),
    __(lang, 'TOTAL_PURCHASE'),
    __(lang, 'DETAIL'),
  ];

  useEffect(() => {
    let filterPaginator, filterOrder;
    if (orderState === 'new') {
      filterPaginator = {
        options: { elements: nElem },
        filter: {
          merchantId: merchantId,
          statusNew: true,
          statusShipping: false,
          statusDelivered: false,
        },
      };
      filterOrder = {
        filter: {
          merchantId: merchantId,
          statusNew: true,
          statusShipping: false,
          statusDelivered: false,
        },
        paginationByPageNumbers: {
          page: 1,
          elements: nElem,
        },
      };
    }
    if (orderState === 'shipping') {
      filterPaginator = {
        options: { elements: nElem },
        filter: {
          merchantId: merchantId,
          statusNew: false,
          statusShipping: true,
          statusDelivered: false,
        },
      };
      filterOrder = {
        filter: {
          merchantId: merchantId,
          statusNew: false,
          statusShipping: true,
          statusDelivered: false,
        },
        paginationByPageNumbers: {
          page: 1,
          elements: nElem,
        },
      };
    }
    if (orderState === 'delivered') {
      filterPaginator = {
        options: { elements: nElem },
        filter: {
          merchantId: merchantId,
          statusNew: false,
          statusShipping: false,
          statusDelivered: true,
        },
      };
      filterOrder = {
        filter: {
          merchantId: merchantId,
          statusNew: false,
          statusShipping: false,
          statusDelivered: true,
        },
        paginationByPageNumbers: {
          page: 1,
          elements: nElem,
        },
      };
    }
    setFilterPaginator(filterPaginator);
    setFilterOrder(filterOrder);

    dispatch(
      requestOrders({
        merchantId: filterOrder.merchant,
        statusNew: filterOrder.statusNew,
        statusShipping: filterOrder.statusShipping,
        statusDelivered: filterOrder.statusDelivered,
        page: filterOrder.paginationByPageNumbers.page,
        elements: filterOrder.paginationByPageNumbers.elements,
      }),
    );
  }, [orderState, dispatch, merchantId, nElem]);

  const onSelectOrderDetail = order => {
    // console.log(order);
    dispatch(selectOrder(order));
    history.push('/order/detail');
  };

  const onChangeOrderState = event => {
    setOrderState(event.target.value);
  };

  const handleOnClickBtn = evt => {
    evt.preventDefault();
    const cp = parseInt(evt.target.value);
    let filter = {
      ...filterOrder,
      paginationByPageNumbers: { page: cp, elements: nElem },
    };

    dispatch(
      requestOrders({
        merchantId: filter.merchant,
        statusNew: filter.statusNew,
        statusShipping: filter.statusShipping,
        statusDelivered: filter.statusDelivered,
        page: filter.paginationByPageNumbers.page,
        elements: filter.paginationByPageNumbers.elements,
      }),
    );

    setCPage(cp);
    setFilterOrder(filter);
  };

  const onChangeNElement = n => {
    let filterP = { ...filterPaginator, options: { elements: n } };
    let filter = {
      ...filterOrder,
      paginationByPageNumbers: { page: 1, elements: n },
    };

    dispatch(
      requestOrders({
        merchantId: filter.merchant,
        statusNew: filter.statusNew,
        statusShipping: filter.statusShipping,
        statusDelivered: filter.statusDelivered,
        page: filter.paginationByPageNumbers.page,
        elements: filter.paginationByPageNumbers.elements,
      }),
    );

    setFilterPaginator(filterP);
    setFilterOrder(filter);
    setNElem(n);
    setCPage(1);
  };

  const _changeMerchantOrderStatusNewToShipping = useCallback(
    orderId => {
      dispatch(changeOrderNewToShipping({ orderId: orderId }));
    },
    [dispatch],
  );
  const _changeMerchantOrderStatusToShippingNew = useCallback(
    orderId => {
      dispatch(changeOrderShippingToNew({ orderId: orderId }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (arrOrders.length > 0) {
      const _arr = [...arrOrders].map((order, key) => (
        <RowOrder
          key={key}
          orderState={orderState}
          order={order}
          onSelectOrderDetail={onSelectOrderDetail}
          changeMerchantOrderStatusNewToShipping={_changeMerchantOrderStatusNewToShipping}
          changeMerchantOrderStatusToShippingNew={_changeMerchantOrderStatusToShippingNew}
        />
      ));
      setArr(_arr);
    } else {
      setArr([]);
    }
  }, [arrOrders, orderState]); // eslint-disable-line react-hooks/exhaustive-deps

  const display = () => {
    if (errorOrder || errorOrderPagin || errorUpdating) {
      return (
        <div className="row h-75 align-items-center justify-content-center">
          <div className="alert alert-danger" style={{ height: 'fit-content' }} role="alert">
            {__(lang, 'ERROR_FETCHING_ORDERS')}
          </div>
        </div>
      );
    }

    if (isFetchingOrderPagin || isFetchingOrder || updatingOrder) {
      return <LoadingModal isLoading={isFetchingOrderPagin || isFetchingOrder || updatingOrder} />;
    }

    if (arr.length > 0) {
      let tHead = tHeadNew;
      if (orderState === 'shipping') tHead = tHeadShiiping;
      if (orderState === 'delivered') tHead = tHeadDelivered;
      return (
        <div className="row justify-content-center">
          <Paginator
            lang={lang}
            cPage={cPage}
            data={arr}
            head={tHead}
            nElem={nElem}
            handleOnClickBtn={handleOnClickBtn}
            onChangeNElement={onChangeNElement}
            paginator={paginator}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <main role="main" className={'container-fluid  pt-3 px-4 '}>
      <LoadingModal isLoading={changingOrderStatus} />
      <div className="row justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom">
        <h2 className="text-warning">{__(lang, 'ORDERS')}</h2>
        <div className="btn-toolbar">
          <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
            <button
              type="button"
              value={'new'}
              className={'btn mr-0 ' + (orderState === 'new' ? 'btn-secondary' : 'btn-outline-secondary')}
              onClick={onChangeOrderState}>
              {__(lang, 'NEW')}
            </button>
            <button
              type="button"
              value={'shipping'}
              className={'btn mr-0 ' + (orderState === 'shipping' ? 'btn-secondary' : 'btn-outline-secondary')}
              onClick={onChangeOrderState}>
              {__(lang, 'SHIPPING')}
            </button>
            <button
              type="button"
              value={'delivered'}
              className={'btn mr-0 ' + (orderState === 'delivered' ? 'btn-secondary' : 'btn-outline-secondary')}
              onClick={onChangeOrderState}>
              {__(lang, 'DELIVERED')}
            </button>
          </div>
        </div>
      </div>
      {display()}

      {arr.length === 0 && (
        <div className={'d-flex flex-column align-items-center'} style={{ width: '100%', height: '100%' }}>
          <img className="emptyOrders" src={emptyOrdersImg} alt="" />
        </div>
      )}
      <Footer />
    </main>
  );
};
export default memo(Orders);
