import React, { useEffect, useState } from 'react';
import back1 from '../ImageEraser/resources/img/squares.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faEraser,
  faRedo,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { getCanvasBase64 } from '../../utils/editor/getCanvasBase64';

const Canvas = ({ imageSrc, save, close }) => {
  const canvasRef = React.useRef(null);
  const imgRef = React.useRef(null);

  const [brushValue, setBrushValue] = useState(32.0);
  const [imgSrc, setImgSrc] = useState('');
  const [erasing, setErasing] = useState(false);
  const [x, setX] = useState();
  const [y, setY] = useState();

  const [option, setOption] = useState('erase');

  const [index, setIndex] = useState(0);
  const [canvasHistory, setCanvasHistory] = useState([]);

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    if (!imgSrc) {
      setImgSrc(imageSrc);
    }
  }, []);

  useEffect(() => {
    imgRef.current.crossOrigin = 'anonymous';
    imgRef.current.src = imgSrc;
  }, [imgSrc]);

  useEffect(() => {
    const handleTouchMove = (event) => {
      event.preventDefault();
    };

    document.getElementsByTagName('body')[0].style.overscrollBehavior =
      'contain';
    document.getElementsByTagName('body')[0].style.position = 'fixed';
    document.getElementsByTagName('body')[0].style.overflowX = 'hidden';

    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.getElementsByTagName('body')[0].style.overscrollBehavior =
        'auto';
      document.getElementsByTagName('body')[0].style.position = 'relative';
      document.getElementsByTagName('body')[0].style.overflowX = 'auto';

      document.removeEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    };
  }, []);

  useEffect(() => {
    if (canvasRef.current && x && y) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.drawImage(
        imgRef.current,
        x - imgRef.current.width / 2,
        y - imgRef.current.height / 2,
        canvasRef.current.width,
        canvasRef.current.height,
      );
      const content = getCanvasBase64(canvasRef);
      setCanvasHistory([{ image: content }]);
    }
  }, [width, height]);

  const init = () => {
    setX(imgRef.current.width / 2);
    setY(imgRef.current.height / 2);

    setWidth(imgRef.current.width);
    setHeight(imgRef.current.height);
  };

  const onSave = async () => {
    const content = getCanvasBase64(canvasRef);
    save(content);
  };

  const handleBrushSize = (e) => {
    const brush = e.target.value;
    setBrushValue(brush);
  };

  const handleErase = (event, mobile = false) => {
    if (!erasing && !mobile) return;

    const rect = event.target.getBoundingClientRect();

    const mouseX =
      ((event.clientX - rect.left) / (rect.right - rect.left)) *
      canvasRef.current.width;
    const mouseY =
      ((event.clientY - rect.top) / (rect.bottom - rect.top)) *
      canvasRef.current.height;

    const ctx = canvasRef.current.getContext('2d');
    ctx.globalCompositeOperation = 'destination-out';
    ctx.beginPath();
    ctx.arc(mouseX, mouseY, brushValue, 0, 2 * Math.PI);
    ctx.fill();
  };

  const handleOption = (opt) => {
    if (opt === option) {
      setOption(null);
    } else {
      setOption(opt);
    }
  };

  const handleHistory = (value) => {
    const prevImage = new Image();
    const { image: imagSrc } = canvasHistory[index + value];
    prevImage.src = imagSrc;

    const loadImage = new Promise((resolve, reject) => {
      prevImage.onload = () => resolve(prevImage);
      prevImage.onerror = () => reject(new Error('Failed to load image'));
    });

    loadImage
      .then((image) => {
        const ctx = canvasRef.current.getContext('2d');
        canvasRef.current.width = canvasRef.current.width;
        ctx.drawImage(
          image,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height,
        );
        setIndex((prev) => prev + value);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleMouseEnter = () => {
    const cursorCircle = document.getElementById('cursor-circle');
    cursorCircle.style.display = 'block';
    cursorCircle.style.width = `${brushValue}px`;
  };

  const handleMouseLeave = () => {
    const cursorCircle = document.getElementById('cursor-circle');
    cursorCircle.style.display = 'none';
  };

  const handleMouseMove = (event) => {
    const cursorCircle = document.getElementById('cursor-circle');
    const x = event.clientX;
    const y = event.clientY;

    cursorCircle.style.left = `${x - brushValue / 2}px`;
    cursorCircle.style.top = `${y - brushValue / 2}px`;
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const currentImageContent = canvas.toDataURL();

    const currentImageLink = document.createElement('a');
    currentImageLink.href = currentImageContent;
    currentImageLink.download = 'Image.png';
    currentImageLink.click();
  };

  return (
    <div className="mx-2">
      <div id="canvasRef">
        <div id="cursor-circle"></div>
        {width && height && (
          <canvas
            onMouseDown={() => setErasing(true)}
            onMouseUp={async () => {
              if (erasing) {
                setErasing(false);
                setCanvasHistory([
                  ...canvasHistory.slice(0, index + 1),
                  {
                    image: await getCanvasBase64(canvasRef),
                  },
                ]);
                setIndex(index + 1);
              }
            }}
            onTouchEnd={async () => {
              if (option === 'erase') {
                setCanvasHistory([
                  ...canvasHistory.slice(0, index + 1),
                  {
                    image: await getCanvasBase64(canvasRef),
                  },
                ]);
                setIndex(index + 1);
              }
            }}
            onMouseMove={(e) => {
              handleErase(e);
              handleMouseMove(e, brushValue);
            }}
            onTouchMove={(e) => handleErase(e.touches[0], true)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={canvasRef}
            className="cursor-none rounded-lg"
            width={width}
            height={height}
            style={{
              backgroundImage: `url(${back1})`,
              width: '100%',
              maxWidth: '530px',
              aspectRatio: '1/1',
            }}
          />
        )}
      </div>
      <div className="flex flex-row justify-between flex-wrap">
        <div className="mb-2">
          <button
            onClick={() => handleOption('erase')}
            className={`m-0 mr-2 btn btn${
              option === 'erase' ? '' : '-outline'
            }-warning eraser-buttons`}
          >
            <FontAwesomeIcon icon={faEraser} />
          </button>
          <button
            onClick={() => handleHistory(-1)}
            className="m-0 mr-2 btn btn-outline-warning eraser-buttons"
            disabled={index === 0}
          >
            <FontAwesomeIcon icon={faUndo} />
          </button>
          <button
            onClick={() => handleHistory(1)}
            className="m-0 mr-2 btn btn-outline-warning eraser-buttons"
            disabled={index + 1 === canvasHistory.length}
          >
            <FontAwesomeIcon icon={faRedo} />
          </button>
          <button
            onClick={() => handleDownload()}
            className="m-0 mr-2 btn btn-outline-warning eraser-buttons"
            disabled={!x && !y}
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
        <div>
          <button
            className="m-0 mr-2 btn btn-outline-warning eraser-buttons"
            onClick={onSave}
          >
            Save
          </button>
          <button
            className="m-0 btn btn-outline-warning eraser-buttons"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </div>
      {option === 'erase' && (
        <div className="flex flex-col mb-2">
          <h5 className="mt-2">Erase:</h5>
          <input
            defaultValue={brushValue}
            type="range"
            className="custom-range"
            id="customRange1"
            min="1"
            max="100"
            step="1"
            onChange={(e) => handleBrushSize(e)}
          />
        </div>
      )}
      <img
        onLoad={() => init()}
        ref={imgRef}
        style={{ display: 'none' }}
        src={imgSrc}
      />
    </div>
  );
};

export default Canvas;
