import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL_V1 } from '../../common/variables';

const initialMerchantState = {
  isFetchingMerchant: false,
  isSaving: false,
  item: {},
  slug: {
    slug: null,
    exists: false,
    isSaving: false,
  },
  savingIban: false,
  errorIban: false,
  receivedIban: null,
  errorMerchant: false,
  errorDeletingAccount: false,
  accountDeleted: false,
};

const updateBankTransferAccount = createAsyncThunk(
  'UPDATE_BANK_TRANSFER_ACCOUNT',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { stripe, ibanElement, merchantId, name } = data;
      const { token } = getState().app;

      const promise = new Promise((resolve, reject) => {
        stripe
          .createToken(ibanElement, {
            currency: 'eur',
            account_holder_name: name,
            account_holder_type: 'individual',
          })
          .then(result => {
            if (!result.error) {
              resolve(result);
            }
            reject(result.error);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });

      const result = await promise;

      let res = await fetch(API_URL_V1 + '/subscriptions/transfers/bank-account', {
        body: JSON.stringify({
          merchantId: merchantId,
          accountToken: result.token.id,
        }),
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        return await res.json();
      }

      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);

const saveIbanNumber = createAsyncThunk('CREATE_BANK_TRANSFER_ACCOUNT', async (data, { getState, rejectWithValue }) => {
  try {
    const { stripe, ibanElement, merchantId, name } = data;
    const { token } = getState().app;

    const promise = new Promise((resolve, reject) => {
      stripe
        .createToken(ibanElement, {
          currency: 'eur',
          account_holder_name: name,
          account_holder_type: 'individual',
        })
        .then(result => {
          if (!result.error) {
            resolve(result);
          }
          reject(result.error);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });

    const result = await promise;

    let res = await fetch(API_URL_V1 + '/subscriptions/transfers/bank-account', {
      body: JSON.stringify({
        merchantId: merchantId,
        accountToken: result.token.id,
      }),
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      return await res.json();
    }

    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const updateMerchant = createAsyncThunk('UPDATE_MERCHANT', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/merchants', {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const d = await res.json();
    if (res.status === 200) return d;
    return rejectWithValue({ error: true, errorCode: d });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});
const updateMerchantCategories = createAsyncThunk('UPDATE_CATEGORY', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    const { merchantId, categoryId } = data;
    let res = await fetch(API_URL_V1 + '/merchants/categories', {
      method: 'PUT',
      body: JSON.stringify({
        merchantId: merchantId,
        categoryIds: [categoryId],
      }),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) return await res.json();
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});
const editMerchantOpeningHours = createAsyncThunk('EDIT_OPENING_HOURS', async (arg, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;

    let data = {
      merchant_request: { merchantId: arg.merchantId },
      hours: { hours: arg.json },
    };
    let res = await fetch(API_URL_V1 + '/merchants/opening-hours', {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) return await res.json();
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const updateMerchantSlug = createAsyncThunk('UPDATE_SLUG', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/merchants/slug', {
      method: 'PUT',
      body: JSON.stringify({
        merchantId: data.merchantId,
        slug: data.slug,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) return await res.json();
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const createCover = createAsyncThunk('CREATE_COVER', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    const { merchantId, file } = data;
    const fd = new FormData();
    fd.append('file', file);
    fd.append('merchantId', merchantId);

    let res = await fetch(API_URL_V1 + '/merchants/cover', {
      method: 'PUT',
      body: fd,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) return await res.json();
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const deleteCover = createAsyncThunk('DELETE_COVER', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + `/merchants/cover/${data.filename}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) return await res.json();
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const changePassword = createAsyncThunk('CHANGE_PASSWORD', async (data, { getState, rejectWithValue }) => {
  try {
    const { oldPassword, newPassword } = data;
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/users/password/change', {
      method: 'PUT',
      body: JSON.stringify({ currentPassword: oldPassword, newPassword }),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) return await res.json();
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const deleteAccount = createAsyncThunk('DELETE_ACCOUNT', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/users/current', {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) return { status: 200 };
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const merchantSlice = createSlice({
  name: 'merchant',
  initialState: initialMerchantState,
  reducers: {
    receivedMerchant: (state, action) => {
      state.isFetchingMerchant = false;
      state.isSaving = false;
      state.item = action.payload;
      state.slug = {
        slug: action.payload.slug,
        exists: false,
        isSaving: false,
      };
      // augmentProgress(60);
    },
    setMerchantIsSaving: (state, action) => {
      state.isSaving = action.payload;
    },
    resetStateMerchant(state) {
      state.isFetchingMerchant = false;
      state.isSaving = false;
      state.item = {};
      state.slug = {
        slug: null,
        exists: false,
        isSaving: false,
      };
      state.savingIban = false;
      state.errorIban = false;
      state.receivedIban = null;
      state.errorMerchant = false;
    },
  },
  extraReducers: {
    [updateBankTransferAccount.pending]: state => {
      state.savingIban = true;
      state.errorIban = false;
    },
    [updateBankTransferAccount.fulfilled]: (state, action) => {
      state.savingIban = false;
      state.errorIban = false;
      window.location.href = action.payload.url;
    },
    [updateBankTransferAccount.rejected]: (state, action) => {
      state.savingIban = false;
      state.errorIban = action.payload;
    },
    [saveIbanNumber.pending]: state => {
      state.savingIban = true;
      state.errorIban = false;
    },
    [saveIbanNumber.fulfilled]: (state, action) => {
      state.savingIban = false;
      state.errorIban = false;
      window.location.href = action.payload.url;
    },
    [saveIbanNumber.rejected]: (state, action) => {
      state.savingIban = false;
      state.errorIban = action.payload;
    },
    [updateMerchant.pending]: state => {
      state.isSaving = true;
      state.errorMerchant = false;
    },
    [updateMerchant.fulfilled]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = false;
      state.item = action.payload;
    },
    [updateMerchant.rejected]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = action.payload;
    },
    [updateMerchantCategories.pending]: state => {
      state.isSaving = true;
      state.errorMerchant = false;
    },
    [updateMerchantCategories.fulfilled]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = false;
      state.item = action.payload;
    },
    [updateMerchantCategories.rejected]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = action.payload;
    },
    [editMerchantOpeningHours.pending]: state => {
      state.isSaving = true;
      state.errorMerchant = false;
    },
    [editMerchantOpeningHours.fulfilled]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = false;
      state.item = action.payload;
    },
    [editMerchantOpeningHours.rejected]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = action.payload;
    },
    [updateMerchantSlug.pending]: state => {
      state.isSaving = true;
      state.errorMerchant = false;
    },
    [updateMerchantSlug.fulfilled]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = false;

      state.item = action.payload;
    },
    [updateMerchantSlug.rejected]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = action.payload;
    },
    [createCover.pending]: state => {
      state.isSaving = true;
      state.errorMerchant = false;
    },
    [createCover.fulfilled]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = false;
      state.item.images = action.payload.images;
    },
    [createCover.rejected]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = action.payload;
    },
    [deleteCover.pending]: state => {
      state.isSaving = true;
      state.errorMerchant = false;
    },
    [deleteCover.fulfilled]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = false;
      state.item.images = action.payload.images;
    },
    [deleteCover.rejected]: (state, action) => {
      state.isSaving = false;
      state.errorMerchant = action.payload;
    },
    [changePassword.pending]: state => {
      state.isSaving = true;
      state.errorSavingPassword = false;
      state.passwordChanged = false;
    },
    [changePassword.fulfilled]: state => {
      state.isSaving = false;
      state.errorSavingPassword = false;
      state.passwordChanged = true;
    },
    [changePassword.rejected]: state => {
      state.isSaving = false;
      state.errorSavingPassword = true;
      state.passwordChanged = false;
    },
    [deleteAccount.pending]: state => {
      state.isSaving = true;
      state.errorDeletingAccount = false;
      state.accountDeleted = false;
    },
    [deleteAccount.fulfilled]: state => {
      state.isSaving = false;
      state.errorDeletingAccount = false;
      state.accountDeleted = true;
    },
    [deleteAccount.rejected]: state => {
      state.isSaving = false;
      state.errorDeletingAccount = true;
      state.accountDeleted = false;
    },
  },
});

export const { receivedMerchant, setMerchantIsSaving, resetStateMerchant } = merchantSlice.actions;
export {
  updateBankTransferAccount,
  saveIbanNumber,
  updateMerchant,
  updateMerchantCategories,
  editMerchantOpeningHours,
  updateMerchantSlug,
  createCover,
  deleteCover,
  changePassword,
  deleteAccount,
};
export default merchantSlice.reducer;
