import React, { useEffect, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestAnalytics } from '../../redux/slices/analytics';
import { BarChart, Footer, LoadingModal } from '../../components';
import { __ } from '../../lib/i18n';
import TitleComponent from '../../components/TitleComponent';

const Analytics = () => {
  const {
    app: { lang },
    analytics: { isFetchingAnalytics, arrAnalytics, errorAnalytics },
    merchant: {
      item: { id: merchantId },
    },
  } = useSelector((state) => state);
  // const { isFetchingAnalytics, arrAnalytics, errorAnalytics } =useSelector((state) => state.analytics);

  const dispatch = useDispatch();
  const [_width, setWidth] = useState(350);

  useEffect(() => {
    window.addEventListener('resize', getSize);

    dispatch(requestAnalytics({ merchantId: merchantId }));
    // props.requestAnalytics(merchantId)

    return () => {
      window.removeEventListener('resize', getSize);
    };
  }, [dispatch, merchantId]);

  const getSize = (event) => {
    event.preventDefault();
    let w = window.innerWidth;
    if (w > 576) setWidth(400);
  };
  const getProductsStats = () => {
    if (arrAnalytics.length > 0) {
      const { productStats } = arrAnalytics;
      let favorited = 0,
        vTime = 0,
        vCount = 0,
        tCount = 0;
      productStats.forEach((product) => {
        favorited += product.favoritedCountMetric;
        vTime += product.arViewTimeMetric;
        vCount += product.viewCountMetric;
        tCount += product.testCountMetric;
      });
      return {
        favoritedCountMetric: favorited,
        arViewTimeMetric: vTime,
        viewCountMetric: vCount,
        testCountMetric: tCount,
      };
    }
    return null;
  };
  const display = () => {
    if (errorAnalytics) {
      return (
        <div className="row justify-content-center">
          <div className="alert alert-danger" role="alert">
            {__(lang, 'ERROR_FETCHING_ANALYTICS')}
          </div>
        </div>
      );
    }

    if (isFetchingAnalytics) {
      return <LoadingModal isLoading={isFetchingAnalytics} />;
    }

    let {
      favoritedCountMetric,
      viewCountMetric,
      openingHoursViewCountMetric,
      sharedCountMetric,
    } = arrAnalytics;

    const merchantTxt = [
      __(lang, 'MERCHANT'),
      __(lang, 'FAVORITE'),
      __(lang, 'VIEWED'),
      __(lang, 'OPENING_HOURS'),
      __(lang, 'SHARED'),
    ];
    const merchantData = [
      ' ',
      favoritedCountMetric,
      viewCountMetric,
      openingHoursViewCountMetric,
      sharedCountMetric,
    ];
    const merchant = [merchantTxt, merchantData];

    const productsTxt = [
      __(lang, 'PRODUCTS'),
      __(lang, 'FAVORITE'),
      __(lang, 'GLASSES'),
      __(lang, 'VIEWED'),
      __(lang, 'TESTED'),
    ];
    const res = getProductsStats();

    let product = [productsTxt, [' ', 0, 0, 0, 0]];
    if (res !== null) {
      const productDate = [
        ' ',
        res.favoritedCountMetric,
        res.arViewTimeMetric,
        res.viewCountMetric,
        res.testCountMetric,
      ];
      product = [productsTxt, productDate];
    }
    return (
      <>
        <BarChart width={_width} height={_width / 1.6} data={merchant} />
        <BarChart width={_width} height={_width / 1.6} data={product} />
      </>
    );
  };
  return (
    <main role="main" className="container-fluid  pt-3 px-4">
      <TitleComponent title={__(lang, 'ANALYTICS')} />
      <div className="row d-flex justify-content-around align-items-end mt-5">
        {display()}
      </div>
      <Footer />
    </main>
  );
};

export default memo(Analytics);
