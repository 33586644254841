import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Local
import { __ } from '../../lib/i18n';
import { Footer, LoadingModal } from '../../components';
import { fetchPlans } from '../../redux/actions/billingActions';
import { selectPlans, selectFetchingError, selectIsFetchingPlans } from '../../redux/selectors/billingSelector';
import PricingHeader from './PricingHeader';
import PricingGrid from './pricingContainer';
import CustomAlert from '../../components/CustomAlert';
import TitleComponent from '../../components/TitleComponent';
import './styles.css';
import { cancelSubscription, createPaymentIntent, getCurrentPlan } from '../../redux/slices/plan';
import { LIVE } from '../../common/variables';

const monthlyPlansInfo = [
  {
    id: LIVE ? 'price_1MQB77GqQRZLTfCcQIksYeOx' : 'price_1M55eiGqQRZLTfCcrkFlzkHU',
    name: 'P_1_NAME',
    description: 'P_1_DESC',
    featured: false,
    freeTrial: true,
    features: [
      {
        name: 'PF_1_1',
        enabled: false,
      },
      {
        name: 'PF_1_2',
        enabled: false,
      },
      {
        name: 'PF_1_3',
        enabled: false,
      },
      {
        name: 'PF_1_4',
        enabled: true,
        onclick: 'https://itunes.apple.com/app/yuge/id1046505084',
        style: 'border-bottom border-dark a-blue-500 cursor-pointer',
      },
      {
        name: 'PF_1_5',
        enabled: true,
      },
      {
        name: 'PF_1_6',
        enabled: true,
      },
      {
        name: 'PF_1_7',
        enabled: true,
      },
    ],
  },
  {
    id: LIVE ? 'price_1MQB7IGqQRZLTfCc4kqBeJCo' : 'price_1M55fmGqQRZLTfCceEa25NqJ',
    name: 'P_2_NAME',
    description: 'P_2_DESC',
    featured: true,
    freeTrial: true,
    features: [
      {
        name: 'PF_1_1',
        enabled: true,
      },
      {
        name: 'PF_2_3',
        enabled: true,
      },
      {
        name: 'PF_1_3',
        enabled: true,
      },
      {
        name: 'PF_1_4',
        enabled: true,
      },
      {
        name: 'PF_2_1',
        enabled: true,
      },
      {
        name: 'PF_1_6',
        enabled: true,
        onclick: 'https://apps.apple.com/nl/app/yuge-merchant/id6443528979?l=en',
        style: 'border-bottom border-dark a-blue-500 cursor-pointer',
      },
      {
        name: 'PF_2_2',
        enabled: true,
      },
    ],
    // features: ['PF_2_1', 'PF_2_2', 'PF_2_3', 'PF_2_4', 'PF_2_5', 'PF_2_6'],
  },
];
const yearlyPlansInfo = [
  {
    id: LIVE ? 'price_1MQB7EGqQRZLTfCcoqOEteSD' : 'price_1M56GlGqQRZLTfCcL4zJhAuy',
    name: 'P_1_NAME',
    description: 'P_1_DESC',
    featured: false,
    freeTrial: true,
    features: [
      {
        name: 'PF_1_1',
        enabled: false,
      },
      {
        name: 'PF_1_2',
        enabled: false,
      },
      {
        name: 'PF_1_3',
        enabled: false,
      },
      {
        name: 'PF_1_4',
        enabled: true,
        onclick: 'https://itunes.apple.com/app/yuge/id1046505084',
        style: 'border-bottom border-dark a-blue-500 cursor-pointer',
      },
      {
        name: 'PF_1_5',
        enabled: true,
      },
      {
        name: 'PF_1_6',
        enabled: true,
      },
      {
        name: 'PF_1_7',
        enabled: true,
      },
    ],
  },
  {
    id: LIVE ? 'price_1MQB7UGqQRZLTfCcVLLT58EH' : 'price_1M56I0GqQRZLTfCc8jS8IZ90',
    name: 'P_2_NAME',
    description: 'P_2_DESC',
    featured: true,
    freeTrial: true,
    features: [
      {
        name: 'PF_1_1',
        enabled: true,
      },
      {
        name: 'PF_2_3',
        enabled: true,
      },
      {
        name: 'PF_1_3',
        enabled: true,
      },
      {
        name: 'PF_1_4',
        enabled: true,
      },
      {
        name: 'PF_2_1',
        enabled: true,
      },
      {
        name: 'PF_1_6',
        enabled: true,
        onclick: 'https://apps.apple.com/nl/app/yuge-merchant/id6443528979?l=en',
        style: 'border-bottom border-dark a-blue-500 cursor-pointer',
      },
      {
        name: 'PF_2_2',
        enabled: true,
      },
    ],
    // features: ['PF_2_1', 'PF_2_2', 'PF_2_3', 'PF_2_4', 'PF_2_5', 'PF_2_6'],
  },
];

const enterprisePlan = {
  id: 'enterprise',
  name: 'P_3_NAME',
  priceMonth: 0,
  priceYear: 0,
  description: 'P_3_DESC',
  featured: false,
  freeTrial: false,
  features: [
    {
      name: 'PF_3_1',
      enabled: true,
    },
    {
      name: 'PF_3_2',
      enabled: true,
    },
    {
      name: 'PF_3_3',
      enabled: true,
    },
  ],
};

const Billing = () => {
  const dispatch = useDispatch();
  const {
    app: { lang },
    plan: { isCreatingPaymentIntent, errorCreatingPaymentIntent, plan: currentPlan },
  } = useSelector(state => state);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const fetchingPlans = useSelector(selectIsFetchingPlans);
  const fetchingError = useSelector(selectFetchingError);
  const fetchedPlans = useSelector(selectPlans);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);

  //TODO: remove when all info is available inside fetched plan object
  //Populate more info on received plans
  useEffect(() => {
    if (fetchedPlans) {
      const { monthlyPlans, yearlyPlans } = fetchedPlans;
      const newMonthlyPlans = monthlyPlans.map(plan => {
        const monthlyPlanInfo = monthlyPlansInfo.filter(p => p.id === plan.id || p.idTest === plan.id);
        const amount = plan.amount ? plan.amount / 100 : 0;
        return monthlyPlanInfo.length ? { ...plan, ...monthlyPlanInfo[0], amount } : plan;
      });
      const newYearlyPlans = yearlyPlans.map(plan => {
        const yearlyPlanInfo = yearlyPlansInfo.filter(p => p.id === plan.id || p.idTest === plan.id);
        const amount = plan.amount ? plan.amount / 100 : 0;
        return yearlyPlanInfo.length ? { ...plan, ...yearlyPlanInfo[0], amount } : plan;
      });
      setMonthlyPlans([...newMonthlyPlans, enterprisePlan]);
      setYearlyPlans([...newYearlyPlans, enterprisePlan]);
    }
  }, [fetchedPlans]);

  useEffect(() => {
    dispatch(fetchPlans());
    dispatch(getCurrentPlan());
  }, [dispatch]);

  useEffect(() => {
    if (errorCreatingPaymentIntent) {
      setErrorMessage(__(lang, 'ERROR_900'));
    }
  }, [errorCreatingPaymentIntent]);

  useEffect(() => {
    if (isCreatingPaymentIntent) {
      setErrorMessage(null);
    }
  }, [isCreatingPaymentIntent]);

  const [period, setPeriod] = useState('year');

  function handleSelectPlan(selectedPlan) {
    if (selectedPlan.id === 'enterprise') {
      window.location = 'https://goyuge.com/contact';
      return;
    }
    dispatch(createPaymentIntent(selectedPlan.id)).then(result => {
      const { url } = result.payload;
      window.location.href = url;
    });
  }

  const handleCancelSubscription = () => {
    if (window.confirm(__(lang, 'ARE_YOU_SURE_CANCEL_PLAN'))) {
      dispatch(cancelSubscription()).then(res => {
        if (res?.payload?.success) {
          setMessage(__(lang, 'CANCEL_SUBSCRIPTION_SUCCESS'));
        }
      });
    }
  };

  return (
    <main role="main" className={'container-fluid  pt-3 px-4'}>
      {message && (
        <CustomAlert type={'info'} onDismiss={() => setMessage(null)}>
          {message}
        </CustomAlert>
      )}
      {errorMessage && (
        <CustomAlert type={'error'} onDismiss={() => setMessage(null)}>
          {errorMessage}
        </CustomAlert>
      )}
      <LoadingModal isLoading={fetchingPlans || isCreatingPaymentIntent} />
      <TitleComponent title={__(lang, 'BILLING')} />
      {currentPlan === 'NONE' && (
        <CustomAlert type={'warning'} noDimiss={true}>
          {__(lang, 'FREE_PLAN_WARNING')}. {__(lang, 'SELECT_PLAN_WARNING')}.
        </CustomAlert>
      )}
      {!fetchingPlans && !isCreatingPaymentIntent && (!!monthlyPlans.length || !!yearlyPlans.length) && (
        <div className="page-pricing2">
          <div className="page-pricing px-4 md:px-16 mt-4 pt-4">
            <div className="flex flex-col items-center justify-between">
              <PricingHeader
                lang={lang}
                period={period}
                handlePeriod={newPeriod => {
                  setPeriod(newPeriod);
                }}
              />
              <PricingGrid
                monthlyPlans={monthlyPlans}
                yearlyPlans={yearlyPlans}
                lang={lang}
                billingPeriod={period}
                currentPlan={currentPlan}
                handleSelectPlan={handleSelectPlan}
              />
            </div>
          </div>
        </div>
      )}
      {fetchingError && (
        <div className="row h-75 align-items-center justify-content-center">
          <div className="alert alert-danger" style={{ height: 'fit-content' }} role="alert">
            {__(lang, 'ERROR_FETCHING_PLANS')}
          </div>
        </div>
      )}
      {currentPlan?.id && (
        <button
          onClick={handleCancelSubscription}
          className="bg-gray-50 bg-opacity-80 text-black border border-gray-200 font-semibold py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-blue-500 hover:text-white active:bg-blue-600"
          type="button"
          style={{ paddingLeft: 30, paddingRight: 30, alignSelf: 'center' }}>
          {__(lang, 'CANCEL_SUBSCRIPTION')}
        </button>
      )}
      <Footer />
    </main>
  );
};

export default Billing;
