import Axios from 'axios';
import React, { useState } from 'react';
import { API_URL_V1 } from '../../common/variables';
import { __ } from '../../lib/i18n';
import { isPassWordStrong, isEmailValid } from '../../utils/validations';
import styles from './SignUp.module.scss';
import eyeIcon from '../../img/eye.svg';

const defaultData = [
  { name: 'email', value: '', label: 'EMAIL', type: 'email', error: '' },
  {
    name: 'password',
    value: '',
    label: 'PASSWORD',
    type: 'password',
    error: '',
    eye: true,
  },
  {
    name: 'confirmPassword',
    value: '',
    label: 'CONFIRM_PASSWORD',
    type: 'password',
    error: '',
    eye: true,
  },
  {
    name: 'merchantName',
    value: '',
    label: 'MERCHANT',
    type: 'text',
    error: '',
  },
];

export default function SignUp({ lang }) {
  const [step, setStep] = useState(0);
  const [error, setError] = useState('');
  const [form, setForm] = useState(defaultData);
  const [viewPassword, setViewPassword] = useState(false);

  const checkErrors = index => {
    let data = [...form];
    let formErrors = true;

    let email = {};
    switch (index) {
      case 0:
        email = data[0];
        if (isEmailValid(email.value)) {
          email.error = '';
          formErrors = false;
        } else if (email.value === '') {
          email.error = 'EMAIL_MUST_BE_VALID';
        } else {
          email.error = 'EMAIL_MUST_BE_CORRECT_FORMAT';
        }
        data[index] = email;
        setForm(data);
        break;
      case 1:
        let password = data[1];
        if (isPassWordStrong(password.value)) {
          password.error = '';
          formErrors = false;
        } else if (password.value === '') {
          password.error = 'PASSWORD_MUST_BE_VALID';
        } else {
          password.error = 'PASSWORD_INVALID_REQUIREMENTS';
        }
        data[index] = password;
        setForm(data);
        break;
      case 2:
        let confirmPassword = data[2];
        if (confirmPassword.value === data[1].value && confirmPassword.value !== '') {
          confirmPassword.error = '';
          formErrors = false;
        } else {
          confirmPassword.error = 'PASSWORD_CONFIRMATION_NO_MATCH';
        }
        data[index] = confirmPassword;
        setForm(data);
        break;
      default:
        let merchantName = data[3];
        if (merchantName.value !== '') {
          merchantName.error = '';
          formErrors = false;
        } else {
          email.error = 'STORE_NAME_MUST_BE_VALID';
        }
        data[index] = merchantName;
        setForm(data);
        break;
    }

    return formErrors;
  };

  const handleFormChange = (index, event) => {
    let data = [...form];
    data[index].value = event.target.value;
    setForm(data);

    checkErrors(index);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (checkErrors(0) || checkErrors(1) || checkErrors(2) || checkErrors(3)) return;

    const body = {
      username: form[0].value,
      password: form[1].value,
      merchantName: form[3].value,
    };

    await Axios.post(API_URL_V1 + '/users', body)
      .then(_data => {
        setError(false);
        setStep(1);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.detail === 'Company name already exists.') {
            setError('This storename is already taken. Please choose a different name.');
          } else {
            setError(error.response.data.detail);
          }
        }
      });
    // console.log(res)
    // setError(false);
    // setStep(1);
  };

  const cleanData = () => {
    setForm(defaultData);
  };

  return (
    <>
      <div
        className={styles.modal + ' modal fade'}
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="false">
        <div className={styles.modal_dialog + ' modal-dialog modal-dialog-centered'} role="document">
          <div className={styles.modal_content + ' modal-content'}>
            <div className="modal-body">
              {step === 1 ? (
                <form method="POST" onSubmit={e => e.preventDefault()}>
                  <h2
                    style={{
                      fontSize: '1rem',
                      lineHeight: '1.75rem',
                      fontWeight: 500,
                    }}>
                    {__(lang, 'EMAIL_CONFIRMATION')}
                  </h2>
                  <p
                    style={{
                      fontSize: '1rem',
                      lineHeight: '1.75rem',
                      fontWeight: 400,
                    }}>
                    {__(lang, 'YUGE_SENT_EMAIL')} {form[0].value}. {__(lang, 'OPEN_EMAIL_AND_CONFIRM')}
                  </p>
                  <button className={styles.submit} onClick={() => cleanData()} type="submit" data-dismiss="modal">
                    OK
                  </button>
                </form>
              ) : (
                <form method="POST" onSubmit={e => handleSubmit(e)}>
                  {form.map((input, index) => (
                    <div className={styles.form_group} key={input.name}>
                      <label htmlFor={input.name}>{__(lang, input.label)}</label>
                      <div className="relative">
                        <input
                          id={input.name}
                          name={input.name}
                          type={input.type === 'password' && viewPassword ? 'text' : input.type}
                          value={input.value}
                          onChange={e => handleFormChange(index, e)}
                        />
                        {input.eye && (
                          <img
                            onClick={() => setViewPassword(!viewPassword)}
                            style={{
                              position: 'absolute',
                              top: '9px',
                              right: '8px',
                            }}
                            className="eyeIcon"
                            src={eyeIcon}
                            alt="eye icon"
                          />
                        )}
                      </div>
                      {input.error && <span className={styles.error_message}>{__(lang, input.error)}</span>}
                    </div>
                  ))}
                  <button className={styles.submit} type="submit">
                    {__(lang, 'CREATE_YOUR_STORE')}
                  </button>
                  {error && <span className={styles.error_message}>{error}</span>}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
