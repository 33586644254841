import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL_V1 } from '../../common/variables';

const getCurrentPlan = createAsyncThunk('GET_PLAN', async (id, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/subscriptions/current', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    } else return rejectWithValue(res.status);
  } catch (e) {
    return rejectWithValue(e);
  }
});

const createPaymentIntent = createAsyncThunk('CREATE_PAYMENT_INTENT', async (id, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/subscriptions/checkout', {
      method: 'POST',
      body: JSON.stringify({
        planId: id,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    } else return rejectWithValue(res.status);
  } catch (e) {
    return rejectWithValue(e);
  }
});

const cancelSubscription = createAsyncThunk('CANCEL_SUBSCRIPTION', async (_, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/subscriptions/cancel', {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    } else return rejectWithValue(res.status);
  } catch (e) {
    return rejectWithValue(e);
  }
});

const currentPlan = createSlice({
  name: 'plan',
  initialState: {
    isFetchingCurrentPlan: false,
    errorFetchingCurrentPlan: false,
    errorCancelingCurrentPlan: false,
    currentPlan: {},
    isCreatingPaymentIntent: false,
    errorCreatingPaymentIntent: false,
    plan: null,
  },
  reducers: {},
  extraReducers: {
    [getCurrentPlan.pending]: state => {
      state.isFetchingCurrentPlan = true;
    },
    [getCurrentPlan.fulfilled]: (state, action) => {
      state.isFetchingCurrentPlan = false;
      state.errorFetchingCurrentPlan = false;
      state.plan = action.payload;
    },
    [getCurrentPlan.rejected]: state => {
      state.errorFetchingCurrentPlan = true;
      state.plan = 'NONE';
    },
    [createPaymentIntent.pending]: state => {
      state.isCreatingPaymentIntent = true;
    },
    [createPaymentIntent.fulfilled]: state => {
      state.isCreatingPaymentIntent = false;
      state.errorCreatingPaymentIntent = false;
    },
    [createPaymentIntent.rejected]: state => {
      state.errorCreatingPaymentIntent = true;
    },
    [cancelSubscription.pending]: state => {
      state.isFetchingCurrentPlan = true;
    },
    [cancelSubscription.fulfilled]: state => {
      state.isFetchingCurrentPlan = false;
      state.errorCancelingCurrentPlan = false;
      state.plan = {};
    },
    [cancelSubscription.rejected]: state => {
      state.errorCancelingCurrentPlan = true;
    },
  },
});

export { getCurrentPlan, createPaymentIntent, cancelSubscription };
export default currentPlan.reducer;
