import React, { useState } from 'react';
import { __ } from '../../lib/i18n';
import SignUp from '../SignUp/SignUp';

import styles from './Header.module.scss';

const languages = [
  ['us', 'USA', 'en'],
  ['es', 'ESP', 'es'],
  ['nl', 'NL', 'nl'],
];

const headerLinks = [
  {
    text: 'ABOUT_HEADER',
    url: 'https://goyuge.com/',
  },
  {
    text: 'APPS',
    url: 'https://goyuge.com/apps',
  },
  {
    text: 'PRICING',
    url: 'https://goyuge.com/pricing',
  },
  {
    text: 'LOGIN',
    url: '/',
  },
];

function LanguagePickerModal({ open, handleLangChange, activeLanguage }) {
  if (!open) return <></>;
  return (
    <ul className={styles.language_list}>
      {languages.map((country) => (
        <li
          key={country[0]}
          className={activeLanguage === country ? styles.active : ''}
          onClick={() => handleLangChange(country)}
        >
          <div className={styles.col_1}>
            <svg
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill={activeLanguage === country ? '#9b9b9b' : 'rgba(1, 1, 1, 0)'}
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <div className={styles.col_2}>
            <img
              alt="es flag"
              src={`/${country[0]}.png`}
              decoding="async"
              data-nimg="intrinsic"
            />
          </div>
          <div className={styles.col_3}>{country[1]}</div>
        </li>
      ))}
    </ul>
  );
}

function LanguagePickerButton({ open, setOpen, activeLanguage, type }) {
  const button_style =
    type === 'regular'
      ? styles.language_picker_button_regular
      : styles.language_picker_button_transparent;
  const text_color = type === 'regular' ? '#9b9b9b' : '#FFF';
  return (
    <button className={button_style} onClick={() => setOpen(!open)}>
      <span className={styles.items_center} style={{ color: text_color }}>
        <img
          src={`/${activeLanguage[0]}.png`}
          decoding="async"
          data-nimg="intrinsic"
        />
        <span style={{ marginLeft: '5px' }}>{activeLanguage[1]}</span>
      </span>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill={text_color}
          aria-hidden="true"
          height={20}
          width={20}
          style={{ marginRight: '2px' }}
        >
          <path
            fillRule="evenodd"
            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    </button>
  );
}

export function LanguagePicker({ handleChangeLanguage, type = 'regular' }) {
  const [open, setOpen] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(languages[0]);

  function handleLangChange(l) {
    handleChangeLanguage(l[2]);
    setActiveLanguage(l);
    setOpen(false);
  }
  return (
    <>
      <LanguagePickerButton
        open={open}
        setOpen={setOpen}
        activeLanguage={activeLanguage}
        type={type}
      />
      <LanguagePickerModal
        open={open}
        handleLangChange={handleLangChange}
        activeLanguage={activeLanguage}
      />
    </>
  );
}

function HeaderMobileDrawer({ open, lang }) {
  return (
    <div className={open ? styles.drawerOpen : styles.drawerClose}>
      <ul>
        {headerLinks.map((link) => (
          <li key={link.url}>
            <a href={link.url}>{__(lang, link.text)}</a>
          </li>
        ))}
        <li>
          <a href="#" data-toggle="modal" data-target="#exampleModalCenter">
            {__(lang, 'CREATE_ACCOUNT')}
          </a>
        </li>
        <li>
          <p className={styles.drawer_social}>
            <a href="https://www.facebook.com/YugeApp">
              <img src="/face.svg" />
            </a>
            <a
              href="https://www.instagram.com/yuge_ar"
              style={{ marginLeft: '0px' }}
            >
              <img src="/insta.svg" />
            </a>
            <a href="https://www.goyuge.com/contact">
              <img src="/mail.svg" />
            </a>
          </p>
        </li>
      </ul>
    </div>
  );
}

export default function Header({ lang, handleChangeLanguage }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <div className={styles.full_header_container}>
      <div className="container2 px-0 mx-auto">
        <div className={styles.header_container}>
          <div className={styles.header_language_picker + ' col-sm'}>
            <LanguagePicker handleChangeLanguage={handleChangeLanguage} />
          </div>
          <div className={styles.header_logo + ' col-sm'}>
            <a href="http://goyuge.com">
              <img src="/yuge-logo.svg" />
            </a>
          </div>
          <div className={styles.header_links + ' col-sm'}>
            {headerLinks.map((link) => (
              <a key={link.url} href={link.url}>
                {__(lang, link.text)}
              </a>
            ))}
            <a
              className={styles.create_account}
              data-toggle="modal"
              data-target="#exampleModalCenter"
              style={{ whiteSpace: 'nowrap' }}
            >
              {__(lang, 'CREATE_ACCOUNT')}
            </a>
          </div>

          <div className={styles.header_links_mobile + ' col-sm'}>
            <button type="button" onClick={() => setOpenDrawer(!openDrawer)}>
              <span className={styles.hamburguer_box}>
                <div
                  className={
                    openDrawer
                      ? styles.hamburguer_inner_open
                      : styles.hamburguer_inner
                  }
                ></div>
              </span>
            </button>
          </div>
        </div>
      </div>
      <SignUp lang={lang} />

      <HeaderMobileDrawer lang={lang} open={openDrawer} />
    </div>
  );
}
