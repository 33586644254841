import React from 'react';
import update from 'immutability-helper';
import { Component } from 'react';
import { Card } from './Card.js';
import { orderProducts } from '../../redux/slices/products.js';
import { connect } from 'react-redux';

const style = {
  display: 'flex',
  flexWrap: 'wrap',
  // marginRight:'-35px',
};

function buildCardData(cards) {
  const cardsById = {};
  const cardsByIndex = [];
  for (let i = 0; i < cards.length; i += 1) {
    if (cards[i]?.id) {
      cardsById[cards[i].id] = cards[i];
    }
    cardsByIndex[i] = cards[i];
  }
  return {
    cardsById,
    cardsByIndex,
  };
}
class Container extends Component {
  requestedFrame;
  constructor(props) {
    super(props);
    this.state = {
      //cards are the objects and products the components
      cardState: buildCardData(props.cards),
    };
  }
  componentWillUnmount() {
    if (this.requestedFrame !== undefined) {
      cancelAnimationFrame(this.requestedFrame);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.cards !== this.props.cards) {
      const cardState = buildCardData(this.props.cards);
      this.setState({
        cardState,
      });
    }
  }
  render() {
    const { cardsByIndex } = this.state.cardState;
    const { productsList } = this.props;

    return (
      <>
        <div
          className="w-100"
          style={{
            ...style,
            marginTop: !this.props.isMosaicView ? '15px' : 0,
            marginLeft: !this.props.isMosaicView ? '0px' : '-15px',
            backgroundColor: !this.props.isMosaicView && '#f8f9fa',
            padding: !this.props.isMosaicView && '24px',
            paddingLeft: !this.props.isMosaicView && '0px',
            boxSizing: 'border-box',
          }}>
          {cardsByIndex.map((card, index) => (
            <div
              key={card?.id}
              className={
                this.props.isMosaicView ? 'col-sm-12 col-sm-6 col-md-6 col-lg-4 pr-0 pl-4' : 'col-sm-12 pr-0 pl-4'
              }>
              <Card
                p={card}
                e={productsList[index]}
                moveCardHover={this.moveCardHover}
                moveCardDrop={this.moveCardDrop}
              />
            </div>
          ))}
        </div>
      </>
    );
  }
  moveCardHover = async (id, afterId) => {
    const { cardsById, cardsByIndex } = this.state.cardState;
    const card = cardsById[id];
    const afterCard = cardsById[afterId];
    const cardIndex = cardsByIndex.indexOf(card);
    const afterIndex = cardsByIndex.indexOf(afterCard);
    this.state.cardState = update(this.state.cardState, {
      cardsByIndex: {
        $splice: [
          [cardIndex, 1],
          [afterIndex, 0, card],
        ],
      },
    });
    await this.scheduleUpdate();
  };
  moveCardDrop = () => {
    const productsSorted = this.state.cardState.cardsByIndex;
    const products = productsSorted.map(category => category.id);
    const data = {
      products,
      productsSorted,
    };
    this.props.orderProducts(data);
  };
  scheduleUpdate() {
    if (!this.requestedFrame) {
      this.requestedFrame = requestAnimationFrame(this.drawFrame);
    }
  }
  drawFrame = () => {
    this.setState(STATE);
    this.requestedFrame = undefined;
  };
}
const STATE = {};

const mapDispatchToProps = {
  orderProducts,
};

const mapStateToProps = state => ({
  ...state,
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
