import { FETCHING_PLANS, SET_PLANS, ERROR_PLANS } from '../actions/billingActions';

const initialState = {
  monthlyPlans: [],
  yearlyPlans: [],
  fetchingPlans: false,
  fetchingError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PLANS:
      return { ...state, monthlyPlans: action.payload.monthlyPlans, yearlyPlans: action.payload.yearlyPlans };
    case FETCHING_PLANS:
      return { ...state, fetchingPlans: action.payload };
    case ERROR_PLANS:
      return { ...state, fetchingError: action.payload };
    default:
      return state;
  }
};
