import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { API_URL_V1 } from '../../common/variables';

const requestCities = createAsyncThunk('REQUEST_CITIES', async (arg, { rejectWithValue }) => {
  try {
    let res = await fetch(API_URL_V1 + '/cities', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      return await res.json();
    } else return rejectWithValue({ error: true, errorCode: res.status });
  } catch (err) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const citiesSlice = createSlice({
  name: 'cities',
  initialState: {
    isFetchingCities: false,
    arrCities: [],
    citiesError: false,
    countryCodes: [],
  },
  reducers: {},
  extraReducers: {
    [requestCities.pending]: state => {
      state.isFetchingCities = true;
      state.citiesError = false;
    },
    [requestCities.fulfilled]: (state, action) => {
      state.isFetchingCities = false;
      state.citiesError = false;
      const cities = action.payload;
      state.countryCodes = cities
        .map(item => item.countryCode)
        .filter((value, index, self) => self.indexOf(value) === index);
      state.arrCities = cities;
    },
    [requestCities.rejected]: (state, action) => {
      state.isFetchingCities = false;
      state.citiesError = action.payload;
    },
  },
});

//export const {} = citiesSlice.actions

export { requestCities };

export default citiesSlice.reducer;
