import React from 'react';
import { connect } from 'react-redux';
import { __ } from '../../lib/i18n';
import { Customer } from '../../components';

const Customers = (props) => {
  const lang = 'en';
  return (
    <main role="main" className={'container-fluid  pt-3 px-4 '}>
      <div className="row justify-content-center">
        <h2 className="font-weight-bold text-warning">
          {__(lang, 'CUSTOMERS_OVERVIEW')}
        </h2>
      </div>
      <div className="row justify-content-between pl-4 pr-4">
        {[...new Array(5)].map((customer, key) => (
          <Customer lang={lang} customer />
        ))}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
