import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import FormLogin from '../components/LoginOut/FormLogin';
import ConfirmByEmail from './ConfirmByEmail';
import { AuthByToken } from '../components';
import LoginRedirect from './LoginRedirect';

const redirectToLoginFromPassRecovery = (data) => {
  if (data && data.location && data.location.search) {
    const urlParams = new URLSearchParams(data.location.search);
    const email = urlParams.get('email');
    const code = urlParams.get('code');
    if (email && code) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            recoveryData: { email: email, code: code },
          }}
        />
      );
    }
  }

  // return <Redirect to="/login" />;
  window.location.href = '/login';
};

const LoginOut = () => {
  function hideLoadingSpinner() {
    const loadingSpinner = document.querySelector('#spinner');
    loadingSpinner.style.display = 'none';
  }
  useEffect(() => {
    hideLoadingSpinner();
  }, []);
  return (
    <Switch>
      <Route path="/login" component={FormLogin} />
      <Route path="/confirm_by_email" component={ConfirmByEmail} />
      <Route path="/token" component={AuthByToken} />
      <Route path="/forgotten_password" component={AuthByToken} />
      <Route path="/redirect" component={LoginRedirect} />
      <Route
        path="/confirm_password_recovery"
        render={redirectToLoginFromPassRecovery}
      />
      {/* <Redirect from="*" to="/login" /> */}
      <Redirect from="*" to="/redirect" />
    </Switch>
  );
};

export default LoginOut;
