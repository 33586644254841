import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchConfirmByEmail } from '../redux/slices/confirmRegiter';

const ConfirmByEmail = () => {
  const dispatch = useDispatch();
  const { isConfirming, success, errorConfirming } = useSelector(
    (state) => state.confirm,
  );
  const location = useLocation();

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let email = params.get('email');
    let code = params.get('code');

    if (email !== null && code !== null) {
      dispatch(fetchConfirmByEmail({ email: email, code: code }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const display = () => {
    if (
      errorConfirming &&
      errorConfirming.errorCode &&
      errorConfirming.errorCode === 422
    )
      return (
        <div className="content mt-5 d-flex flex-column justify-content-center align-items-center">
          <div className="alert alert-danger" role="alert">
            Incorrect token.
          </div>
        </div>
      );
    if (
      errorConfirming &&
      errorConfirming.errorCode &&
      (errorConfirming.errorCode === 412 || errorConfirming.errorCode === 406)
    )
      return (
        <div className="content mt-5 d-flex flex-column justify-content-center align-items-center">
          <div className="alert alert-danger" role="alert">
            This confirmation link has been expired!
          </div>
        </div>
      );
    if (
      errorConfirming &&
      errorConfirming.errorCode &&
      errorConfirming.errorCode === 900
    )
      return (
        <div className="content mt-5 d-flex flex-column justify-content-center align-items-center">
          <div className="alert alert-danger" role="alert">
            Oops! something went wrong, please contact site admin.
          </div>
        </div>
      );
    if (isConfirming)
      return (
        <div className="content mt-5 d-flex flex-column justify-content-center align-items-center">
          <h1>Confirming</h1>
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Please wait...</span>
          </div>
        </div>
      );
    if (success)
      return (
        <div className="content mt-5 d-flex flex-column justify-content-center align-items-center">
          <div className="alert alert-success" role="alert">
            <h1>Your email has been successfully confirmed.</h1>
          </div>
          <a href="/login">Click to login</a>
        </div>
      );

    return null;
  };
  return display();
};

export default ConfirmByEmail;
