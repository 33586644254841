import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LoginIn, LoginOut } from './containers';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import docCookies from './common/cookie';
import { isLogin } from './redux/selectors/appSelector';

const App = (props) => {
  const isLoggedIn = useSelector(isLogin);
  const display = () => {
    return isLoggedIn === true || docCookies.getItem('token') !== null ? (
      <BrowserRouter>
        <LoginIn {...props} />
      </BrowserRouter>
    ) : (
      <BrowserRouter>
        <LoginOut />
      </BrowserRouter>
    );
  };

  return display();
};
export default App;
