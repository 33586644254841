import React from 'react';
import Example from './Example';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export default function DnDProducts(props) {
  const backend = HTML5Backend;

  return (
    <DndProvider backend={backend} options={{ previewEnabled: true }}>
      <Example {...props} />
    </DndProvider>
  );
}
