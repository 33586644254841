import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { configParams } from '../common/variables';

const CustomAlert = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { type, children, onDismiss, noDimiss } = props;

  useEffect(() => {
    if (children) {
      setErrorMessage(children);
      if (noDimiss === true) {
      } else {
        setTimeout(() => {
          setErrorMessage(null);
          if (typeof onDismiss === 'function') {
            onDismiss();
          }
        }, configParams.feedbackTimeout);
      }
    }
  }, [children]); // eslint-disable-line react-hooks/exhaustive-deps

  let bsStyle = 'warning';

  if (type === 'error') bsStyle = 'danger';
  else if (type === 'info') bsStyle = 'success';

  return <>{errorMessage && <Alert bsStyle={bsStyle}>{errorMessage}</Alert>}</>;
};

CustomAlert.propTypes = {
  type: PropTypes.string.isRequired, // error or info
  onDismiss: PropTypes.func,
  noDimiss: PropTypes.bool,
};

export default CustomAlert;
