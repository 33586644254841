import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const Invoice = ({ created, invoice_pdf, lines, noInvoices }) => {
  if (noInvoices) {
    return (
      <div className="col-12 bg-light rounded d-flex flex-row align-items-center  justify-content-between p-2 m-0 mb-2">
        <span
          className="col-6 font-weight-bold text-dark"
          style={{
            paddingTop: '6px',
            paddingBottom: '6px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
        >
          There are no invoices to show
        </span>
        <span className="col-2 font-weight-bold text-dark"></span>
        <span className="col-3 font-weight-bold text-dark"></span>
      </div>
    );
  }
  const { amount, description } = lines.data[0];
  return (
    <div className="col-12 bg-light rounded d-flex flex-row align-items-center  justify-content-between p-2 m-0 mb-2">
      <span
        className="col-6 font-weight-bold text-dark"
        style={{
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '12px',
          paddingRight: '12px',
        }}
      >
        {description}
      </span>
      <span className="col-2 font-weight-bold text-dark">
        {months[new Date(created * 1000).getMonth()]}
      </span>
      <span className="col-3 font-weight-bold text-dark">€ {amount / 100}</span>
      <a className="col-1 btn m-0" href={invoice_pdf} download>
        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
      </a>
    </div>
  );
};

export default Invoice;
