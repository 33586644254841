// Snackbar.tsx
import React, { useState, useEffect } from 'react';
import Portal from './Portal';
import DoneIcon from '../../common/icons/doneIcon';
import CloseIcon from '../../common/icons/closeCrossIcon';
import ErrorIcon from '../../common/icons/errorIcon';

interface SnackbarProps {
  visibleProp: boolean;
  message?: string;
  duration?: number;
  onClose?: () => void;
  keyString: string;
  error: boolean;
  errorMessage?: string;
  // index: number;
}

const Snackbar: React.FC<SnackbarProps> = ({
  keyString,
  visibleProp,
  message = '3D scan added successfully',
  error,
  errorMessage,
  duration = 4500,
  onClose,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(visibleProp);
    }, 100);
  }, [visibleProp]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      setTimeout(() => {
        onClose();
      }, 300);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [visible]);

  return (
    <Portal /* target={`snackbar-container-${index}`} */>
      <div
        key={'snbr' + keyString}
        className={`snackbar  ${visible ? 'visible' : ''}`}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '7px',
            height: '100%',
          }}
        >
          {error ? (
            <div style={{ color: '#db3b5e' }}>
              <ErrorIcon />
            </div>
          ) : (
            <div style={{ color: '#22c522' }}>
              <DoneIcon />
            </div>
          )}
          {error ? errorMessage ?? 'Error adding 3D scan' : message} &nbsp;
          <div style={{ width: '44px', minWidth: '44px', height: '100%' }}>
            <button
              className="btn btn-light"
              style={{
                borderRadius: '12px',
                marginRight: 0,
                position: 'absolute',
                top: 4,
                right: 4,
              }}
              onClick={() => {
                setVisible(false);
                setTimeout(() => {
                  onClose();
                }, 300);
              }}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Snackbar;
