import React from 'react';
import { __ } from '../../lib/i18n';

const BankAccountNotification = ({
  href,
  goToAccountBank,
  lang,
  hasTransfersBankAccountCreated,
  hasTransfersBankAccountStatusIncomplete,
}) => {
  const display = () => {
    if (!hasTransfersBankAccountCreated)
      return (
        <div className="alert alert-danger">
          {__(lang, 'NO_HAS_BANK_ACCOUNT')}
          <a className="ml-1" href={href} onClick={goToAccountBank}>
            {__(lang, 'LINK_TO_BANK_ACCOUNT')}
          </a>
        </div>
      );
    if (
      hasTransfersBankAccountCreated &&
      hasTransfersBankAccountStatusIncomplete
    )
      return (
        <div className="alert alert-danger">
          {__(lang, 'INCOMPLETE_BANK_ACCOUNT')}
          <a className="ml-1" href={href} onClick={goToAccountBank}>
            {__(lang, 'LINK_TO_BANK_ACCOUNT')}
          </a>
        </div>
      );
    return null;
  };
  return display();
};
export default BankAccountNotification;
//<a className="ml-1" href="/merchant/#bank_account">
