// Portal.tsx
import React, { useEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
  target?: string;
}

const Portal: React.FC<PortalProps> = ({
  children /* , target = 'portal-root' */,
}) => {
  const portalElement = document.getElementById('portal-root');

  return createPortal(children, portalElement);
};

export default Portal;
