import React, { useEffect } from 'react';
import { SuspenseFallback } from './RoutesLogin';
import {useHistory} from "react-router";

const ProductsRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/products');
  }, []);

  return <SuspenseFallback />;
};

export default ProductsRedirect;
