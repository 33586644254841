import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL_V1 } from '../../common/variables';

const requestCountries = createAsyncThunk('REQUEST_COUNTRIES', async (data, { rejectWithValue }) => {
  try {
    let res = await fetch(API_URL_V1 + '/countries/supported', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (res.status === 200) return await res.json();
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const countries = createSlice({
  name: 'countries',
  initialState: {
    isFetchingCountries: false,
    items: [],
    errorCountries: false,
  },
  extraReducers: {
    [requestCountries.pending]: state => {
      state.isFetchingCountries = true;
      state.errorCountries = false;
    },
    [requestCountries.fulfilled]: (state, action) => {
      state.isFetchingCountries = false;
      state.errorCountries = false;
      state.items = action.payload;
    },
    [requestCountries.rejected]: (state, action) => {
      state.isFetchingCountries = false;
      state.errorCountries = action.payload;
    },
  },
});

export { requestCountries };
export default countries.reducer;
