import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL_V1 } from '../../common/variables';

const initialMerchantCategoriesState = {
  isFetchingMerchantCategories: true,
  merchantItems: [],
  errorFetchingMerchantCategories: false,
  isCreatingMerchantCategory: false,
  errorCreatingMerchantCategory: false,
  isDeletingMerchantCategory: false,
  errorDeletingMerchantCategory: false,
  isUpdatingMerchantCategory: false,
  errorUpdatingMerchantCategory: false,
  isOrderingMerchantCategory: false,
  errorOrderingMerchantCategory: false,
  merchantCategory: [],
  category: null,
};

const requestMerchantCategories = createAsyncThunk(
  'REQUEST_MERCHANT_CATEGORIES',
  async (merchantId, { getState, rejectWithValue }) => {
    const { token } = getState().app;
    const quantity = 50000;

    try {
      let res = await fetch(API_URL_V1 + `/merchants/categories?merchantId=${merchantId}&quantity=${quantity}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        let result = await res.json();
        return result.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return 0;
        });
      } else return rejectWithValue(res.status);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const addMerchantCategory = createAsyncThunk('ADD_MERCHANT_CATEGORY', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/merchants/categories', {
      method: 'POST',
      body: JSON.stringify({ ...data }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    } else {
      return rejectWithValue(res.status);
    }
  } catch (e) {
    return rejectWithValue(e);
  }
});

const deleteMerchantCategory = createAsyncThunk(
  'DELETE_MERCHANT_CATEGORY',
  async (categoryId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;
      let res = await fetch(API_URL_V1 + `/merchants/categories/${categoryId}`, {
        method: 'DELETE',
        body: JSON.stringify({}),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return await res.json();
      } else {
        return rejectWithValue(res.status);
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const updateMerchantCategory = createAsyncThunk(
  'DELETE_MERCHANT_CATEGORY',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { form, categoryId } = data;
      const { token } = getState().app;
      let res = await fetch(API_URL_V1 + `/merchants/categories/${categoryId}`, {
        method: 'PUT',
        body: JSON.stringify({ ...form }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return await res.json();
      } else {
        return rejectWithValue(res.status);
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const orderMerchantCategory = createAsyncThunk(
  'ORDER_MERCHANT_CATEGORY',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { categoriesSorted, categories } = data;
      const { token } = getState().app;
      const { item } = getState().merchant;
      const merchantId = item.id;
      let res = await fetch(API_URL_V1 + `/merchants/categories/order/sort`, {
        method: 'PUT',
        body: JSON.stringify({ categories, merchantId }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return categoriesSorted;
      } else {
        return rejectWithValue(res.status);
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const merchantCategoriesSlice = createSlice({
  name: 'merchantCategories',
  initialState: initialMerchantCategoriesState,
  reducers: {
    removeFromCategories(state, action) {
      state.merchantItems = state.merchantItems.filter(cat => cat.id !== action.payload);
    },
    changeCategory: (state, action) => {
      state.category = action.payload;
    },
  },
  extraReducers: {
    [requestMerchantCategories.pending]: state => {
      state.isFetchingMerchantCategories = true;
    },
    [requestMerchantCategories.fulfilled]: (state, action) => {
      state.isFetchingMerchantCategories = false;
      state.errorFetchingMerchantCategories = false;
      // state.merchantItems = action.payload; //.filter(obj=>obj.publish===true)
      const sortedCategories = [...action.payload].sort((a, b) => a.order - b.order); //.filter(obj=>obj.publish===true)
      state.merchantItems = sortedCategories;
      state.category =
        state.category && sortedCategories.filter(c => c.key === state.category).length && state.category !== 'ALL'
          ? state.category
          : sortedCategories[0]?.key ?? 'ALL';
      // state.category !== null ? state.category : 'ALL';
    },
    [requestMerchantCategories.rejected]: (state, action) => {
      state.errorFetchingMerchantCategories = action.payload;
    },
    [addMerchantCategory.pending]: state => {
      state.isCreatingMerchantCategory = true;
    },
    [addMerchantCategory.fulfilled]: (state, action) => {
      state.isCreatingMerchantCategory = false;
      state.merchantCategory = action.payload;
      state.merchantItems = [...state.merchantItems, action.payload];
    },
    [addMerchantCategory.rejected]: (state, action) => {
      state.errorCreatingMerchantCategory = action.payload;
    },
    [deleteMerchantCategory.pending]: state => {
      state.isDeletingMerchantCategory = true;
    },
    [deleteMerchantCategory.fulfilled]: state => {
      state.isDeletingMerchantCategory = false;
      state.errorDeletingMerchantCategory = false;
    },
    [deleteMerchantCategory.rejected]: (state, action) => {
      state.errorDeletingMerchantCategory = action.payload;
    },
    [updateMerchantCategory.pending]: state => {
      state.isUpdatingMerchantCategory = true;
    },
    [updateMerchantCategory.fulfilled]: (state, action) => {
      state.isUpdatingMerchantCategory = false;
      state.errorUpdatingMerchantCategory = false;
      const category = action.payload;
      state.merchantItems = state.merchantItems.map(item => (item.id === category.id ? category : item));
    },
    [updateMerchantCategory.rejected]: (state, action) => {
      state.errorUpdatingMerchantCategory = action.payload;
    },
    [orderMerchantCategory.pending]: state => {
      state.isOrderingMerchantCategory = true;
    },
    [orderMerchantCategory.fulfilled]: (state, action) => {
      state.isOrderingMerchantCategory = false;
      state.errorOrderingMerchantCategory = false;
      state.merchantItems = action.payload;
      // const category = action.payload;
      // state.merchantItems = state.merchantItems.map((item) =>
      //   item.id === category.id ? category : item,
      // );
    },
    [orderMerchantCategory.rejected]: (state, action) => {
      state.isOrderingMerchantCategory = false;
      state.errorOrderingMerchantCategory = action.payload;
    },
  },
});
export const { changeCategory, removeFromCategories } = merchantCategoriesSlice.actions;
export {
  requestMerchantCategories,
  addMerchantCategory,
  updateMerchantCategory,
  orderMerchantCategory,
  deleteMerchantCategory,
};
export default merchantCategoriesSlice.reducer;
