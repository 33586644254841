import React from 'react';
import { __ } from '../../lib/i18n';

const Customer = (props) => {
  const { lang } = props;
  return (
    <div className="col-xs-12 col-sm-6">
      <div className="card  m-2">
        <div className="card-header">
          <h5>John Doe</h5>
        </div>
        <div className="card-body">
          <div className={'row'}>
            <div className="col">
              <p>{__(lang, 'EMAIL')}:</p>
              <p>{__(lang, 'STREET')}:</p>
              <p>{__(lang, 'CITY')}:</p>
              <p>{__(lang, 'TEL_NUMBER')}:</p>
              <p>{__(lang, 'PRODUCT_BOUGHT')}:</p>
              <p>{__(lang, 'TOTAL_PURCHASE')}:</p>
            </div>
            <div className="col">
              <p>
                <a href="mailto:email@server.domain">email@server.domain</a>{' '}
              </p>
              <p>Street Name</p>
              <p>Amsterdam</p>
              <p>123 456 7890</p>
              <p>Name ID:09735</p>
              <p className="text-primary font-weight-bold">€ 123.00</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
