import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { __ } from '../../lib/i18n';
import {
  onLogin,
  onChangeLanguage,
  forgottenPasswordRequest,
  confirmForgottenPasswordRequest,
} from '../../redux/slices/app';

import englishFlag from '../../img/united-kingdom-flag-xs.png';
import espFlag from '../../img/spain-flag-xs.png';
import nlFlag from '../../img/netherlands-flag-xs.png';
import '../../css/login.css';
import spinnerSrc from '../../img/ajax-loader.gif';
import { isEmailValid, hasWhiteSpace, isPassWordStrong } from '../../utils/validations';
import CustomAlert from '../CustomAlert';
import Header from '../Header/Header';
import eyeIcon from '../../img/eye.svg';
import checkboxChecked from '../../img/checkboxChecked.svg';
import checkbox from '../../img/checkbox.svg';

const FormLogin = () => {
  const dispatch = useDispatch();
  const { lang, isFetchingUser, errorLogin, isRequestingForgottenPass, isConfirmingForgottenPass } = useSelector(
    state => state.app,
  );
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [showForgotten, setShowForgotten] = useState(false);
  const [showConfirmForgotten, setShowConfirmForgotten] = useState(false);
  const [forgottenEmail, setForgottenEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const [typeForm, setTypeForm] = useState(false);
  const _onChangeEmail = evt => {
    setEmail(evt.target.value);
  };

  const _onChangeForgottenPasswordEmail = evt => {
    setForgottenEmail(evt.target.value);
  };

  const _onChangePass = evt => {
    setPass(evt.target.value);
  };

  const _onChangeForgottenPass = evt => {
    setNewPassword(evt.target.value);
  };

  const _onChangeComfirmForgottenPass = evt => {
    setNewPasswordConfirm(evt.target.value);
  };

  const _onSubmit = evt => {
    if (evt) {
      evt.preventDefault();
    }

    if (!email || !pass) {
      return;
    }
    dispatch(onLogin({ email: email.toString().toLowerCase(), password: pass }));
  };

  const _onSubmitForgottenPassword = evt => {
    evt.preventDefault();
    if (!forgottenEmail || !isEmailValid(forgottenEmail)) {
      setErrorMessage(__(lang, 'EMAIL_NOT_VALID'));
      return;
    }

    dispatch(
      forgottenPasswordRequest({
        email: forgottenEmail.toString().toLowerCase(),
        locale: lang,
      }),
    ).then(result => {
      setShowForgotten(false);
      if (!result.error) {
        setSuccessMessage(__(lang, 'RECOVER_EMAIL_SENT'));
      } else {
        if (result.payload && result.payload.forgottenError === 412) {
          setErrorMessage(__(lang, 'EMAIL_NOT_REGISTER_IN_YUGE'));
          return;
        }
        setErrorMessage(__(lang, 'SOMETHING_WENT_WRONG'));
      }
    });
  };

  const _getFlag = flag => {
    if (flag === 'es') return <img src={espFlag} alt="" style={{ width: '3rem', marginRight: '.5rem' }} />;
    if (flag === 'en') return <img src={englishFlag} alt="" style={{ width: '3rem', marginRight: '.5rem' }} />;
    return <img src={nlFlag} alt="" style={{ width: '3rem', marginRight: '.5rem' }} />;
  };

  const _handleChangeLanguage = lang => {
    dispatch(onChangeLanguage(lang));
  };

  const handlerShowForgottenPasswordForm = event => {
    event.preventDefault();
    setShowForgotten(!showForgotten);
  };

  const onSubmitConfirmPasswordChange = evt => {
    evt.preventDefault();

    if (newPassword !== newPasswordConfirm) {
      setErrorMessage(__(lang, 'PASSWORDS_DOESNT_MATCH'));
      return;
    }

    if (!isPassWordStrong(newPassword)) {
      setErrorMessage(__(lang, 'STRONG_PASSWORD_REQUIRED'));
      return;
    }

    if (hasWhiteSpace(newPassword)) {
      setErrorMessage(__(lang, 'PASSWORD_WHITESPACES'));
      return;
    }

    if (location.recoveryData?.email && location.recoveryData.code) {
      dispatch(
        confirmForgottenPasswordRequest({
          email: location.recoveryData.email,
          code: location.recoveryData.code,
          password: newPassword,
        }),
      ).then(result => {
        setShowConfirmForgotten(false);
        setShowForgotten(false);
        if (!result.error) {
          setSuccessMessage(__(lang, 'PASSWORD_RECOVERED'));
          setTimeout(() => {
            dispatch(
              onLogin({
                email: location.recoveryData.email.toString().toLowerCase(),
                password: newPassword,
              }),
            );
          }, 3000);
        } else {
          if (result.payload.confirmingForgottenError && result.payload.confirmingForgottenError === 412) {
            setErrorMessage(__(lang, 'EXPIRED_LINK'));
            return;
          }
          setErrorMessage(__(lang, 'SOMETHING_WENT_WRONG'));
        }
      });
    } else {
      setErrorMessage(__(lang, 'INVALID_CREDENTIALS'));
    }
  };

  useEffect(() => {
    if (errorLogin) {
      setErrorMessage(__(lang, 'INVALID_EMAIL_PASSWORD'));
    }
  }, [errorLogin]);

  useEffect(() => {
    if (location.recoveryData?.email && location.recoveryData.code) {
      setShowConfirmForgotten(true);
      setShowForgotten(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      _onSubmit(e);
    }
  };

  return (
    <>
      <Header getFlag={_getFlag} handleChangeLanguage={_handleChangeLanguage} lang={lang} />
      <div className="container2 mx-auto px-0">
        <div className="">
          <div className="vertical-centered col-md-12 d-flex flex-column justify-content-center">
            <div className="row mx-2">
              <div className="shadow-lg p-4 col-12 col-lg-6 col-md-8 mx-auto login-container-form">
                <h1>
                  Yuge <span>{__(lang, 'MERCHANT')}</span>
                </h1>
                {successMessage !== '' && (
                  <CustomAlert type={'info'} onDismiss={() => setSuccessMessage('')}>
                    {successMessage}
                  </CustomAlert>
                )}
                {errorMessage !== '' && (
                  <CustomAlert type={'error'} onDismiss={() => setErrorMessage('')}>
                    {errorMessage}
                  </CustomAlert>
                )}
                {!showForgotten && !showConfirmForgotten && (
                  <form onSubmit={_onSubmit} method="post" autoComplete="on">
                    <div className="row form-group">
                      <label className="col-xs-12 col-sm-4">{__(lang, 'EMAIL')} </label>
                      <div className="col-xs-12 col-sm-8">
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          onChange={_onChangeEmail}
                          onKeyDown={handleKeypress}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-xs-12 col-sm-4">{__(lang, 'PASSWORD')}</label>
                      <div className="col-xs-12 col-sm-8">
                        <input
                          className="relative form-control"
                          type={typeForm ? 'text' : 'password'}
                          name="password"
                          onChange={_onChangePass}
                          onKeyDown={handleKeypress}
                        />
                        <img onClick={() => setTypeForm(!typeForm)} className="eyeIcon" src={eyeIcon} alt="eye icon" />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-xs-12 col-sm-4"></label>
                      <div className="col-xs-12 col-sm-8">
                        <img
                          className="mr-2 cursor-pointer"
                          width={20}
                          onClick={() => setTypeForm(!typeForm)}
                          src={!typeForm ? checkbox : checkboxChecked}
                          alt="checkbox"
                        />
                        Show password
                      </div>
                    </div>
                    <div className="row form-group justify-content-between">
                      <div className="justify-content-start">
                        <button className="btn btn-link" onClick={handlerShowForgottenPasswordForm}>
                          {__(lang, 'FORGOT_PASS')}
                        </button>
                      </div>
                      <div className="col-xs-4">
                        {isFetchingUser ? (
                          <img src={spinnerSrc} alt={'...'} />
                        ) : (
                          <button className="btn btn-outline-secondary my-2 my-sm-0 " type="submit">
                            {__(lang, 'LOGIN')}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                )}
                {showForgotten && (
                  <form onSubmit={_onSubmitForgottenPassword} method="post" autoComplete="on">
                    <p style={{ color: '#6c757d' }}> {__(lang, 'FORGOTTEN_EXPLANATION')}</p>
                    <div className="row form-group">
                      <label className="col-xs-12 col-sm-5">{__(lang, 'EMAIL')} </label>
                      <div className="col-xs-12 col-sm-7">
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          onChange={_onChangeForgottenPasswordEmail}
                        />
                      </div>
                    </div>
                    <div className="row form-group justify-content-between">
                      <div className="justify-content-start">
                        <button className="btn btn-link" onClick={handlerShowForgottenPasswordForm}>
                          {__(lang, 'CANCEL')}
                        </button>
                      </div>
                      <div className="col-xs-4">
                        {isRequestingForgottenPass ? (
                          <img src={spinnerSrc} alt={'...'} />
                        ) : (
                          <button className="btn btn-outline-secondary my-2 my-sm-0 " type="submit">
                            {__(lang, 'RECOVER_PASS')}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                )}
                {showConfirmForgotten && (
                  <form onSubmit={onSubmitConfirmPasswordChange} method="post" autoComplete="on">
                    <p style={{ color: '#6c757d' }}> {__(lang, 'COMFIRM_FORGOTTEN_EXPLANATION')}</p>
                    <div className="row form-group">
                      <label className="col-xs-12 col-sm-5">{__(lang, 'NEW_PASSWORD')}</label>
                      <div className="col-xs-12 col-sm-7">
                        <input
                          className="relative form-control"
                          type={typeForm ? 'text' : 'password'}
                          name="password"
                          onChange={_onChangeForgottenPass}
                        />
                        <img onClick={() => setTypeForm(!typeForm)} className="eyeIcon" src={eyeIcon} alt="eye icon" />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-xs-12 col-sm-5">{__(lang, 'CONFIRM_PASSWORD')}</label>
                      <div className="col-xs-12 col-sm-7">
                        <input
                          className="relative form-control"
                          type={typeForm ? 'text' : 'password'}
                          name="password"
                          onChange={_onChangeComfirmForgottenPass}
                        />
                        <img onClick={() => setTypeForm(!typeForm)} className="eyeIcon" src={eyeIcon} alt="eye icon" />
                      </div>
                    </div>
                    <div className="row form-group justify-content-end">
                      <div className="col-xs-4">
                        {isConfirmingForgottenPass ? (
                          <img src={spinnerSrc} alt={'...'} />
                        ) : (
                          <button className="btn btn-outline-secondary my-2 my-sm-0 " type="submit">
                            {__(lang, 'RECOVER_PASS')}
                          </button>
                        )}
                      </div>
                    </div>
                    <p style={{ color: '#6c757d', fontSize: 10 }}> {'*' + __(lang, 'STRONG_PASSWORD_REQUIRED')}</p>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormLogin;
