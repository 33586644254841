import { API_URL_V1 } from '../../common/variables';

export const FETCHING_PLANS = 'FETCHING_PLANS';
export const SET_PLANS = 'SET_PLANS';
export const ERROR_PLANS = 'ERROR_PLANS';

export const fetchingPlans = fetching => ({
  type: FETCHING_PLANS,
  payload: fetching,
});

export const receivedPlans = ({ monthlyPlans, yearlyPlans }) => ({
  type: SET_PLANS,
  payload: { monthlyPlans, yearlyPlans },
});

export const receivedError = error => ({
  type: ERROR_PLANS,
  payload: error,
});

export const fetchPlans = () => {
  return dispatch => {
    dispatch(receivedError(false));
    dispatch(fetchingPlans(true));
    return fetch(API_URL_V1 + '/subscriptions/plans', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status === 200) {
          res.json().then(plans => {
            dispatch(fetchingPlans(false));
            const monthlyPlans = plans.filter(plan => plan.interval === 'month').sort((a, b) => a.amount - b.amount);
            const yearlyPlans = plans.filter(plan => plan.interval === 'year').sort((a, b) => a.amount - b.amount);
            dispatch(receivedPlans({ monthlyPlans, yearlyPlans }));
          });
        } else {
          dispatch(fetchingPlans(false));
        }
      })
      .catch(error => {
        dispatch(fetchingPlans(false));
        dispatch(receivedError(true));
        console.log('Error fetching plans', error);
      });
  };
};
