import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import reducerProduct from '../slices/products';
import reducerApp from '../slices/app';
import reducerMerchants from '../slices/merchants';
import reducerMerchant from '../slices/merchant';
import reducerOrder from '../slices/orders';
import reducerCategories from '../slices/categories';
import reducerCountries from '../slices/countries';
import reducerAnalytics from '../slices/analytics';
import reducerCities from '../slices/cities';
import reducerConfirm from '../slices/confirmRegiter';
import reducerBilling from '../reducers/billingReducer';
import reducerInvoices from '../reducers/invoicesReducers';
import customDomainSlice from '../slices/customDomainSlice';
import isYuge from '../slices/isYuge';
import currentPlan from '../slices/plan';
import reducerMerchantCategories from '../slices/merchantCategories';

export type RootState = {
  app: ReturnType<typeof reducerApp>;
  products: ReturnType<typeof reducerProduct>;
  merchants: ReturnType<typeof reducerMerchants>;
  merchant: ReturnType<typeof reducerMerchant>;
  order: ReturnType<typeof reducerOrder>;
  categories: ReturnType<typeof reducerCategories>;
  merchantCategories: ReturnType<typeof reducerMerchantCategories>;
  countries: ReturnType<typeof reducerCountries>;
  analytics: ReturnType<typeof reducerAnalytics>;
  cities: ReturnType<typeof reducerCities>;
  confirm: ReturnType<typeof reducerConfirm>;
  billing: ReturnType<typeof reducerBilling>;
  invoices: ReturnType<typeof reducerInvoices>;
  customDomain: ReturnType<typeof customDomainSlice>;
  isYuge: ReturnType<typeof isYuge>;
  plan: ReturnType<typeof currentPlan>;
};

const rootReducer = combineReducers({
  app: reducerApp,
  isYuge: isYuge,
  products: reducerProduct,
  merchants: reducerMerchants,
  merchant: reducerMerchant,
  order: reducerOrder,
  categories: reducerCategories,
  merchantCategories: reducerMerchantCategories,
  countries: reducerCountries,
  analytics: reducerAnalytics,
  cities: reducerCities,
  confirm: reducerConfirm,
  billing: reducerBilling,
  invoices: reducerInvoices,
  customDomain: customDomainSlice,
  plan: currentPlan,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
// export type { RootState };
