import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL_V1 } from '../../common/variables';
import { selectCategory } from '../selectors/categories';
import { selectMerchantId } from '../selectors/merchantSelectors';

const initialProductsState = {
  isFetchingProducts: true,
  isFetchingProductsPagin: true,
  errorPagin: false,
  paginator: false,
  isIndexed: false,
  isCreating: false,
  isRemoving: false,
  isActivating: false,
  isUpdating: false,
  items: [], //Pods
  itemsUpdated: 0,
  merchantId: undefined,
  errorProducts: null,
  errorCreatingProduct: null,
  selectedProduct: null,
  selectedExtraction: null,
  isUpdatingExtraction: false,
  errorUpdatingExtraction: false,
  isDeletingVariation: false,
  errorDeletingVariation: false,
  isCreatingVariation: false,
  errorCreatingVariation: false,
  isOrderingVariation: false,
  errorOrderingVariation: false,
  page: 1,
  showLoadMore: false,
  isOrderingProducts: false,
  lastProductId3DModelUploaded: null,
  variants: [], //All variants (product variations) of the merchant
  pageVariants: 1,
  isFetchingVariants: true,
  errorVariants: null,
  showLoadMoreVariants: false,
};

const PRODUCTS_PER_PAGE = 12;
const VARIANTS_PER_PAGE = 20;

const updateProductExtraction = createAsyncThunk(
  'UPDATE_PRODUCT_EXTRACTION',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;
      const { productId, fileBase64, fileName } = arg;
      let res = await fetch(API_URL_V1 + '/products/extraction/base64', {
        body: JSON.stringify({
          productId: productId,
          fileBase64: fileBase64,
          filename: fileName,
        }),
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return await res.json();
      }
      return rejectWithValue(res.status);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

function getFile(base64String) {
  const binaryString = window?.atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Crear un objeto File a partir del ArrayBuffer
  return new File([bytes.buffer], 'filename.png', { type: 'image/png' });
}

const requestMerchantPods = createAsyncThunk('FETCHING_PRODUCTS', async (data, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const { token } = state.app;
    const { page } = state.products;

    const merchantId = selectMerchantId(state);

    const category = selectCategory(state);
    let queryParams = `?merchantId=${merchantId}&page=${page}&elements=${PRODUCTS_PER_PAGE}`;

    if (category !== null && category !== 'ALL') {
      queryParams = queryParams + `&category=${category}`;
    }

    const res = await fetch(API_URL_V1 + '/pods/merchant' + queryParams, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      const result = await res.json();
      return result.map(item => item.defaultVariant);
    }
    return rejectWithValue(res.status);
  } catch (e) {
    return rejectWithValue(e);
  }
});

const requestMerchantVariants = createAsyncThunk(
  'FETCHING_PRODUCTS_VARIANTS',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { token } = state.app;
      const { pageVariants } = state.products;
      const { merchantId } = data;

      const queryParams = `?fromMerchant=${merchantId}&page=${pageVariants}&elements=${VARIANTS_PER_PAGE}&withoutMerchantInfo=true&publish=true&sortByOrderAsc=true`;

      const res = await fetch(API_URL_V1 + '/products' + queryParams, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return await res.json();
      }
      return rejectWithValue(res.status);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const addProduct = createAsyncThunk('ADD_PRODUCT', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let fd = new FormData();
    fd.append('merchantId', data.merchantId);
    fd.append('file', data.file);
    fd.append('title', data.title);
    fd.append('price', data.price);
    fd.append('description', data.description);
    if (data.category) fd.append('category', data.category);
    fd.append('width', data.dimensions.width);
    fd.append('height', data.dimensions.height);
    fd.append('depth', data.dimensions.depth);
    fd.append('stock', data.stock);
    fd.append('weight', data.weight);
    fd.append('measure', data.measure);
    fd.append('externalBuyUrl', data.externalBuyUrl);
    fd.append('externalBuyEnabled', data.externalBuyEnabled);
    fd.append('placement', data.placement);

    const res = await axios.post(API_URL_V1 + '/products', fd, {
      timeout: 20000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      //let result = await res.json()
      return res.data;
    }
    return rejectWithValue({ errorCode: res.status });
  } catch (error) {
    console.log(error);
    if (error.response) {
      // Request made and server responded
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error);
    }
    return rejectWithValue({ errorCode: 999 });
  }
});

const requestProduct = createAsyncThunk('REQUEST_PRODUCT', async (productId, { getState, rejectWithValue }) => {
  try {
    let { token } = getState().app;
    if (!token) token = sessionStorage.getItem('sessionToken');
    const res = await fetch(API_URL_V1 + `/products/auth/${productId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    }
    return rejectWithValue(res.status);
  } catch (e) {
    return rejectWithValue(900);
  }
});

const getProductVariantsByPod = createAsyncThunk(
  'GET_PRODUCT_VARIANTS_POD',
  async (podId, { getState, rejectWithValue }) => {
    try {
      let { token } = getState().app;
      if (!token) token = sessionStorage.getItem('sessionToken');
      const res = await fetch(API_URL_V1 + `/products/auth?podId=${podId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return await res.json();
      }
      return rejectWithValue(res.status);
    } catch (e) {
      return rejectWithValue(900);
    }
  },
);

const editProductImage = createAsyncThunk('EDIT_PRODUCT_IMAGE', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let fd = new FormData();
    fd.append('file', data.file);
    fd.append('productId', data.id);
    let res = await fetch(API_URL_V1 + '/products/image', {
      method: 'POST',
      body: fd,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    }
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const editProduct = createAsyncThunk('EDIT_PRODUCT', async (arg, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const { token } = state.app;
    const data = {
      productId: arg.id,
      title: arg.title,
      price: arg.price,
      description: arg.description,
      dimensions: arg.dimensions,
      sold: arg.sold,
      stock: arg.stock,
      externalBuyEnabled: arg.externalBuyEnabled,
      placement: arg.placement,
    };

    if (arg.externalBuyUrl) {
      data.externalBuyUrl = arg.externalBuyUrl;
    }

    if (arg.category) data.category = arg.category;

    data.price = parseFloat(data.price);
    if (!data.price) {
      data.price = 1;
    }

    let res = await fetch(API_URL_V1 + '/products/' + arg.id, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) return await res.json();
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const removeProductById = createAsyncThunk('REMOVE_PRODUCT', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    const { podId, productId } = data;
    let res = await fetch(API_URL_V1 + `/pods/${podId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return { podId, productId };
    }
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const removeVariationById = createAsyncThunk(
  'REMOVE_PRODUCT_VARIATION',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;
      const { productId } = data;
      let res = await fetch(API_URL_V1 + `/products/${productId}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return { productId };
      }
      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);

const productUploadExtractions = createAsyncThunk('UPLOAD_EXTRACTION', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    const { id, files } = data;
    const promises = files.map(file => {
      const fd = new FormData();
      fd.append('file', file);

      return fetch(API_URL_V1 + `/products/${id}/2d/extraction`, {
        body: fd,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (response.status === 200)
            return response.json().then(result => {
              return result;
            });
        })
        .catch(error => {
          throw new Error('error', error);
        });
    });

    return await Promise.all(promises);

    // return rejectWithValue({error:true,errorCode:900})
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const productUploadExtractions_3d = createAsyncThunk(
  'UPLOAD_EXTRACTION_3D',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;

      const formData = new FormData();
      formData.append('file', data.file);

      let res = await fetch(API_URL_V1 + `/products/${data.productId}/models/${data.fileType}`, {
        body: formData,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) return await res.json();
      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);

const productDeleteExtractions_3d = createAsyncThunk(
  'DELETE_EXTRACTION_3D',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().app;
      let res = await fetch(
        API_URL_V1 + '/products/' + data.id + '/models/' + data.extensionType + '/' + data.modelId,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (res.status === 200) return await res.json();
      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);

const setExtractionActive = createAsyncThunk(
  'EXTRACTION_ACTIVE',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const appState = getState();
      const { token } = appState.app;
      const { selectedProduct } = appState.products;

      let endPoint = `/extractions/${data.filename}/inactive`;
      if (!data.isActive) {
        endPoint = `/extractions/${data.filename}/active`;
      }
      let res = await fetch(API_URL_V1 + endPoint, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        if (selectedProduct) {
          dispatch(requestProduct(selectedProduct.id));
        }
        return data;
      }
      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);
const setExtraction3DActive = createAsyncThunk(
  'EXTRACTION3D_ACTIVE',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const appState = getState();
      const { token } = appState.app;
      const { selectedProduct } = appState.products;
      let endPoint = `/products/${data.prodId}/models/${data.modelId}/activate`;

      let res = await fetch(API_URL_V1 + endPoint, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        if (selectedProduct) {
          dispatch(requestProduct(selectedProduct.id));
        }
        return data;
      }
      return rejectWithValue({ error: true, errorCode: res.status });
    } catch (e) {
      return rejectWithValue({ error: true, errorCode: 900 });
    }
  },
);
const productDeleteExtraction = createAsyncThunk('REMOVING_EXTRACTION', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + '/extractions/' + data.filename, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) return data;
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const addProductVariation = createAsyncThunk('ADDING_Variation', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    const { file, productId } = data;

    let fd = new FormData();
    fd.append('file', file);
    fd.append('title', file.name);
    fd.append('description', file.size.toString());

    let res = await fetch(API_URL_V1 + `/products/${productId}/variants`, {
      method: 'POST',
      body: fd,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return await res.json();
    }
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const productOrderVariations = createAsyncThunk('ORDER_VARIATIONS', async (data, { getState, rejectWithValue }) => {
  try {
    const { variantsSorted, variants, productId, podId } = data;
    const { token } = getState().app;
    let res = await fetch(API_URL_V1 + `/pods/${podId}/variants/sort`, {
      method: 'PUT',
      body: JSON.stringify({ variants }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      let response = await res.json();
      return { productId, variantsSorted, variants: response.variants, podId };
    } else {
      return rejectWithValue(res.status);
    }
  } catch (e) {
    return rejectWithValue(e);
  }
});

const orderProducts = createAsyncThunk('ORDER_PRODUCTS', async (data, { getState, rejectWithValue }) => {
  try {
    const { productsSorted, products } = data;
    const { token } = getState().app;
    const { item } = getState().merchant;
    const merchantId = item.id;
    let res = await fetch(API_URL_V1 + `/merchants/products/order/sort`, {
      method: 'PUT',
      body: JSON.stringify({ products, merchantId }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return productsSorted;
    } else {
      return rejectWithValue(res.status);
    }
  } catch (e) {
    return rejectWithValue(e);
  }
});

const productDeleteVariation = createAsyncThunk('REMOVING_VARIATION', async (data, { getState, rejectWithValue }) => {
  try {
    const { token } = getState().app;
    const { variantId } = data;
    let res = await fetch(API_URL_V1 + `/products/${variantId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return data;
    }
    return rejectWithValue({ error: true, errorCode: res.status });
  } catch (e) {
    return rejectWithValue({ error: true, errorCode: 900 });
  }
});

const productSlice = createSlice({
  name: 'PRODUCTS',
  initialState: initialProductsState,
  reducers: {
    receivedProduct(state, action) {
      state.items.unshift(action.payload);
    },
    selectProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    selectExtraction(state, action) {
      state.selectedExtraction = action.payload;
    },
    changePage(state) {
      state.page = 1;
      state.items = [];
    },
    changePageVariants(state) {
      state.page = 1;
      state.items = [];
    },
    resetStateProducts(state) {
      state.isFetchingProducts = true;
      state.isFetchingProductsPagin = true;
      state.errorPagin = false;
      state.paginator = false;
      state.isIndexed = false;
      state.isCreating = false;
      state.isRemoving = false;
      state.isActivating = false;
      state.isUpdating = false;
      state.items = [];
      state.itemsUpdated = 0;
      state.merchantId = undefined;
      state.errorProducts = null;
      state.errorCreatingProduct = null;
      state.selectedProduct = null;
      state.selectedExtraction = null;
      state.isUpdatingExtraction = false;
      state.errorUpdatingExtraction = false;
      state.isDeletingVariation = false;
      state.errorDeletingVariation = false;
      state.isCreatingVariation = false;
      state.errorCreatingVariation = false;
      state.isOrderingVariation = false;
      state.errorOrderingVariation = false;
      state.page = 1;
      state.showLoadMore = false;
      state.isOrderingProducts = false;
      state.variants = [];
      state.pageVariants = 1;
      state.isFetchingVariants = true;
      state.errorVariants = null;
      state.showLoadMoreVariants = false;
    },
  },
  extraReducers: {
    [requestMerchantPods.pending]: state => {
      state.isFetchingProducts = true;
    },
    [requestMerchantPods.fulfilled]: (state, action) => {
      state.isFetchingProducts = false;
      state.errorProducts = false;
      state.items.push(...action.payload);
      state.showLoadMore = action.payload.length === PRODUCTS_PER_PAGE;
      state.page = state.page + 1;
      state.itemsUpdated = new Date().getTime();
    },
    [requestMerchantPods.rejected]: (state, action) => {
      state.errorProducts = action.payload;
    },
    [requestMerchantVariants.pending]: state => {
      state.isFetchingVariants = true;
    },
    [requestMerchantVariants.fulfilled]: (state, action) => {
      state.isFetchingVariants = false;
      state.errorVariants = false;
      state.variants.push(...action.payload);
      state.showLoadMoreVariants = action.payload.length === VARIANTS_PER_PAGE;
      state.pageVariants = state.pageVariants + 1;
    },
    [requestMerchantVariants.rejected]: (state, action) => {
      state.errorVariants = action.payload;
    },
    [addProduct.pending]: state => {
      state.isCreating = true;
    },
    [addProduct.fulfilled]: state => {
      state.isCreating = false;
      state.errorCreatingProduct = false;
    },
    [addProduct.rejected]: (state, action) => {
      state.isCreating = false;
      state.errorCreatingProduct = action.payload;
    },
    [requestProduct.pending]: state => {
      state.isFetchingProducts = true;
    },
    [requestProduct.fulfilled]: (state, action) => {
      state.isFetchingProducts = false;
      state.errorProducts = false;
      state.selectedProduct = action.payload;
      state.items = state.items.map(product => (product.id === action.payload.id ? action.payload : product));
    },
    [requestProduct.rejected]: (state, action) => {
      state.isFetchingProducts = false;
      state.errorProducts = action.payload;
    },
    [editProduct.pending]: state => {
      state.isUpdating = true;
    },
    [editProduct.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.errorProducts = false;
      let p = action.payload;
      state.selectedProduct = p;
      state.items = state.items.map(product => (product.id === p.id ? p : product));
    },
    [editProduct.rejected]: (state, action) => {
      state.isUpdating = false;
      state.errorProducts = action.payload;
    },
    [editProductImage.pending]: state => {
      state.isUpdating = true;
      state.errorProducts = false;
    },
    [editProductImage.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.errorProducts = false;
      state.selectedProduct = action.payload;
    },
    [editProductImage.rejected]: (state, action) => {
      state.isUpdating = false;
      state.errorProducts = action.payload;
    },
    [productUploadExtractions.pending]: state => {
      state.isUpdating = true;
      state.errorProducts = false;
    },
    [productUploadExtractions.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.errorProducts = false;
      let result = action.payload;
      state.selectedProduct = result[0];
      state.items = state.items.map(p => (p.id === result[0].id ? result[0] : p));
    },
    [productUploadExtractions.rejected]: (state, action) => {
      state.isUpdating = false;
      state.errorProducts = action.payload;
    },
    // PRODUCT UPLOAD 3D MODEL
    [productUploadExtractions_3d.pending]: state => {
      state.isUpdating = true;
    },
    [productUploadExtractions_3d.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.errorProducts = false;
      state.selectedProduct = action.payload;
      state.items = state.items.map(p => (p.id === action.payload.id ? action.payload : p));
      state.lastProductId3DModelUploaded = action?.payload?.id;
    },
    [productUploadExtractions_3d.rejected]: (state, action) => {
      state.isUpdating = false;
      state.errorProducts = action.payload;
    },
    // PRODUCT DELETE 3D MODEL
    [productDeleteExtractions_3d.pending]: state => {
      state.isRemoving = true;
      state.errorProducts = false;
    },
    [productDeleteExtractions_3d.fulfilled]: (state, action) => {
      state.isRemoving = false;
      state.errorProducts = false;
      state.selectedProduct = action.payload;
      state.items = state.items.map(p => (p.id === action.payload.id ? action.payload : p));
    },
    [productDeleteExtractions_3d.rejected]: (state, action) => {
      state.isRemoving = false;
      state.errorProducts = action.payload;
    },
    //
    [setExtractionActive.pending]: state => {
      state.isActivating = true;
      state.errorProducts = false;
    },
    [setExtractionActive.fulfilled]: state => {
      state.isActivating = false;
      state.errorProducts = false;
    },
    [setExtractionActive.rejected]: (state, action) => {
      state.isActivating = false;
      state.errorProducts = action.payload;
    },

    [setExtraction3DActive.pending]: state => {
      state.isActivating = true;
      state.errorProducts = false;
    },
    [setExtraction3DActive.fulfilled]: state => {
      state.isActivating = false;
      state.errorProducts = false;
    },
    [setExtraction3DActive.rejected]: (state, action) => {
      state.isActivating = false;
      state.errorProducts = action.payload;
    },

    [productDeleteExtraction.pending]: state => {
      state.isRemoving = true;
      state.errorProducts = false;
    },
    [productDeleteExtraction.fulfilled]: (state, action) => {
      state.isRemoving = false;
      state.errorProducts = false;
      let res = action.payload;
      state.selectedProduct.extractions = state.selectedProduct?.extractions?.filter(
        ext => ext.filename !== res.filename,
      );
      state.items = state.items.map(p => {
        if (p.id !== state.selectedProduct.id) return p;
        return state.selectedProduct;
      });
    },
    [productDeleteExtraction.rejected]: (state, action) => {
      state.isRemoving = false;
      state.errorProducts = action.payload;
    },
    [removeProductById.pending]: state => {
      state.isFetchingProducts = true;
      state.errorProducts = false;
    },
    [removeProductById.fulfilled]: (state, action) => {
      state.isFetchingProducts = false;
      state.errorProducts = false;
      state.items = state.items.filter(product => product.id !== action.payload.productId);
      if (state.selectedProduct !== null && state.selectedProduct !== undefined)
        state.selectedProduct = state.selectedProduct.id === action.payload.productId ? null : state.selectedProduct;
    },
    [removeProductById.rejected]: (state, action) => {
      state.isFetchingProducts = true;
      state.errorProducts = action.payload;
    },
    [removeVariationById.pending]: state => {
      state.isFetchingProducts = true;
      state.errorProducts = false;
    },
    [removeVariationById.fulfilled]: state => {
      state.isFetchingProducts = false;
      state.errorProducts = false;
    },
    [removeVariationById.rejected]: (state, action) => {
      state.isFetchingProducts = true;
      state.errorProducts = action.payload;
    },
    [updateProductExtraction.pending]: state => {
      state.isUpdatingExtraction = true;
      state.errorUpdatingExtraction = false;
    },
    [updateProductExtraction.fulfilled]: (state, action) => {
      state.isUpdatingExtraction = false;
      state.errorUpdatingExtraction = false;
      state.selectedProduct = action.payload;
      state.items = state.items.map(product => {
        if (product.id === action.payload.id) return action.payload;
        return product;
      });
    },
    [updateProductExtraction.rejected]: (state, action) => {
      state.isUpdatingExtraction = true;
      state.errorUpdatingExtraction = action.payload;
    },
    [addProductVariation.pending]: state => {
      state.isCreatingVariation = true;
    },
    [addProductVariation.fulfilled]: state => {
      state.isCreatingVariation = false;
    },
    [addProductVariation.rejected]: state => {
      state.errorCreatingVariation = true;
      state.isCreatingVariation = false;
    },
    [productDeleteVariation.pending]: state => {
      state.isDeletingVariation = true;
    },
    [productDeleteVariation.fulfilled]: state => {
      state.isDeletingVariation = false;
    },
    [productDeleteVariation.rejected]: state => {
      state.errorDeletingVariation = true;
      state.isDeletingVariation = false;
    },
    [orderProducts.pending]: state => {
      state.isOrderingProducts = true;
    },
    [orderProducts.fulfilled]: (state, action) => {
      state.isOrderingProducts = false;
      state.items = action.payload;
    },
    [orderProducts.rejected]: state => {
      state.isOrderingProducts = false;
    },
    [productOrderVariations.pending]: state => {
      state.isOrderingVariation = true;
    },
    [productOrderVariations.fulfilled]: (state, action) => {
      state.isOrderingVariation = false;
      const { productId, variantsSorted } = action.payload;
      state.items = state.items.map(item => {
        if (item.id === productId) return { ...item, variants: variantsSorted };
        return item;
      });
    },
    [productOrderVariations.rejected]: state => {
      state.errorOrderingVariation = true;
      state.isOrderingVariation = false;
    },
  },
});

export const { changePage, changePageVariants, receivedProduct, selectProduct, selectExtraction, resetStateProducts } =
  productSlice.actions;
export {
  requestMerchantPods,
  requestMerchantVariants,
  getProductVariantsByPod,
  addProduct,
  requestProduct,
  editProduct,
  editProductImage,
  productUploadExtractions,
  productUploadExtractions_3d,
  productDeleteExtractions_3d,
  setExtractionActive,
  setExtraction3DActive,
  productDeleteExtraction,
  removeProductById,
  removeVariationById,
  updateProductExtraction,
  addProductVariation,
  productDeleteVariation,
  productOrderVariations,
  orderProducts,
};
export default productSlice.reducer;
