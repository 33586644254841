import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import TitleComponent from '../../components/TitleComponent';
import { __ } from '../../lib/i18n';
import { Footer, Invoice, LoadingModal } from '../../components';
import { requestInvoices } from '../../redux/actions/invoicesActions';

const Invoices = props => {
  const {
    app: { lang },
  } = useSelector(state => state);

  useEffect(() => {
    props.requestInvoices();
  }, []);

  const { fetching, error, arrInvoices } = props;

  const display = () => {
    if (error) {
      return (
        <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center">
          <div className="alert alert-danger" role="alert">
            System Error
          </div>
        </div>
      );
    }

    if (fetching) {
      return <LoadingModal isLoading={fetching} />;
    }

    if (arrInvoices && arrInvoices.length === 0) {
      return (
        <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center">
          <Invoice lang={lang} noInvoices />
        </div>
      );
    }

    return (
      <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center">
        {arrInvoices.map((invoice, key) => (
          <Invoice key={key} lang={lang} {...invoice} />
        ))}
      </div>
    );
  };

  return (
    <main role="main" className="container-fluid  pt-3" style={{ paddingLeft: '25px', paddingRight: '25px' }}>
      <TitleComponent title={__(lang, 'INVOICES')} />
      {display()}
      <Footer />
    </main>
  );
};

const mapStateToProps = state => {
  const { fetching, error, arrInvoices } = state.invoices;
  return { fetching, error, arrInvoices };
};

//TODO: Implement pagination for invoices
const mapDispatchToProps = dispatch => {
  return {
    requestInvoices: (lastId = null, quantity = 50000) => dispatch(requestInvoices(lastId, quantity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
