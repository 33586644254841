import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

//Local
import IbanForm from './IbanForm';
import { LIVE } from '../../../common/variables';

const stripePromise = LIVE
  ? loadStripe('pk_live_DCBl415ALOPnnOXGcZ8biSzd0091780kYp')
  : loadStripe('pk_test_8HuYfyLYunFDxKxFwvH6gSow00yx2jyVba');

const IbanElementStripe = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <IbanForm {...props} />
    </Elements>
  );
};

export default IbanElementStripe;
