import React, { useEffect } from 'react';

const LoginRedirect = () => {
  useEffect(() => {
    window.location.href = '/login';
  }, []);

  return <></>;
};

export default LoginRedirect;
