import React, { useEffect, useState } from 'react'
import productsListIcon from '../img/icons/Block-icon.svg'
import listIcon from '../img/icons/List-icon.svg'


const OrganizationalButtons = ({onChangeView}) => {
    const [isMosaicView, setIsMosaicView] = useState(true);
    const handleSetMosacView = () => {
        setIsMosaicView(true);
    }

    const handleSetListView = () => {
        setIsMosaicView(false)
    }

    useEffect(() => {
        onChangeView(isMosaicView);
    }, [isMosaicView])
    
  return (
    <div style={{ display: 'flex', gap: '12px', color:'white', alignItems:'center' }}>
    <button
        className="btn btn-download buttonBigWhenHover mb-1"
    onClick={handleSetMosacView}
    >
       <img
            id="img-mosaic-view"
            title="Mosaic view"
            src={productsListIcon}
            alt={'...'}
            style={{ width: '30px', height: '30px', marginTop: '4px' }}
        />
    </button>
    <button
        className="btn btn-download buttonBigWhenHover mb-1"
    onClick={handleSetListView}
    >
    <img
            id="img-list-view"
            title="List view"
            src={listIcon}
            alt={'...'}
            style={{ width: '30px', height: '30px', marginTop: '4px' }}
        />
    </button>
</div>
  )
}

export default OrganizationalButtons