import React, { useState, Suspense, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Menu from '../components/Menu/Menu';
import { AuthByToken, SideBar } from '../components';
import { requestUserInfo, onLogOut } from '../redux/slices/app';
import docCookies from '../common/cookie';
import { requestMerchants } from '../redux/slices/merchants';
import { receivedMerchant } from '../redux/slices/merchant';
import { requestOrderStats } from '../redux/slices/orders';
import { requestCategories } from '../redux/slices/categories';
import { requestMerchantPods } from '../redux/slices/products';
import { useDispatch, useSelector } from 'react-redux';
import { requestMerchantCategories } from '../redux/slices/merchantCategories';
import augmentProgress from '../utils/augmentProgress';
import AndroidSmartBanner from '../components/androidSmartBanner';
import { useLocation } from 'react-router';
import {
  LazyAnalytics,
  LazyBilling,
  LazyCustomDomain,
  LazyCustomers,
  LazyEraserContainer,
  LazyFAQ,
  LazyInvoices,
  LazyMerchant,
  LazyMerchantCategories,
  LazyMerchantEdit,
  LazyMerchantOpeningHours,
  LazyNewCustomDomain,
  LazyOrderProductDetails,
  LazyOrders,
  LazyProducts,
  LazyProductsAdd,
  LazyProductsEdit,
  LazyProductsEditImage,
  LazyProductsExtractions,
  LazyProductsLinks,
  LazyProductsProductsModel3D,
  LazyTransformContainer,
  LazyWebsite,
} from '../routes/LazyComponents';
import './RoutesLogin.css';
import ProductsRedirect from './ProductsRedirect';
import CustomAlert from '../components/CustomAlert';
import { __ } from '../lib/i18n';

export const SuspenseFallback = () => {
  const portalContainerRef = useRef(null);
  useEffect(() => {
    const loadingSpinner = document.querySelector('#spinner');
    const loadingSpinnerCloned = loadingSpinner.cloneNode(true);
    loadingSpinnerCloned.style.display = 'flex';
    loadingSpinnerCloned.style.minHeight = 'calc(100vh - 100px)';
    portalContainerRef.current.appendChild(loadingSpinnerCloned);
  }, []);
  return (
    <div className="suspenseFallback">
      <div ref={portalContainerRef} style={{ marginBottom: '150px !important' }}></div>
    </div>
  );
};

const RoutesLogin = () => {
  const dispatch = useDispatch();
  let match = useRouteMatch({
    path: '/products/:id/eraser',
    strict: true,
    sensitive: true,
  });
  let match2 = useRouteMatch({
    path: '/products/:id/transform',
    strict: true,
    sensitive: true,
  });
  const {
    app: { lang },
    order: { stats },
  } = useSelector(state => state);
  const isLogin = useSelector(state => state.app.isLogin);
  const [alertInfo, setAlertInfo] = useState(null);
  function hideLoadingSpinner() {
    const loadingSpinner = document.querySelector('#spinner');
    loadingSpinner.style.display = 'none';
  }

  function showLoadingSpinner() {
    const loadingSpinner = document.querySelector('#spinner');
    loadingSpinner.style.display = 'flex';
  }

  const { pathname, search } = useLocation();

  useEffect(() => {
    const scrollElement = py => {
      const element = document.getElementById('pproducts');
      if (element) {
        element.scrollTop = parseInt(py);
      }
    };

    if (pathname === '/products') {
      const savedPosition = sessionStorage.getItem('scrollPosition') || '0';
      const timeoutId = setTimeout(() => scrollElement(savedPosition), 10);
      return () => clearTimeout(timeoutId);
    }
  }, [pathname]);

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    async function loadUser() {
      showLoadingSpinner();
      setShowContent(false);
      const userInfoResult = await dispatch(requestUserInfo(docCookies.getItem('token')));
      if (userInfoResult?.error || !userInfoResult?.payload?.user?.approved) {
        if (!userInfoResult?.payload?.user?.approved === false) {
          alert('Your merchant is pending approval by Yuge management. Please stay tuned for a confirmation email.');
        }
        dispatch(onLogOut());
        hideLoadingSpinner();
        return;
      }
      augmentProgress(33);
      augmentProgress(66);
      augmentProgress(100);
      hideLoadingSpinner();
      setShowContent(true);
      const merchantsInfo = await dispatch(requestMerchants());
      if (merchantsInfo?.payload) {
        await dispatch(requestMerchantCategories(merchantsInfo.payload.id));
        dispatch(requestOrderStats(merchantsInfo.payload.id));
        dispatch(receivedMerchant(merchantsInfo.payload));
        // Ron To Do -- DELETE WHEN BACKEND FINISH //
        dispatch(requestCategories());
        dispatch(requestMerchantPods());
      } else {
        //TODO: mostrar mensaje que merchant no existe.
      }
    }
    setShowContent(false);
    loadUser();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const planSuccess = query.get('subscription_success');
    if (planSuccess === '1') {
      setAlertInfo({ type: 'info', message: __(lang, 'SUBSCRIPTION_SUCCESSFUL') });
    } else if (planSuccess === '0') {
      setAlertInfo({ type: 'error', message: __(lang, 'SUBSCRIPTION_FAILURE') });
    }
  }, [search]);

  const render = () => {
    return (
      <>
        {isLogin && showContent && (
          <div className="overflow-hidden">
            <AndroidSmartBanner />
            <Menu stats={[]} lang={'es'} />
            {!!alertInfo && (
              <CustomAlert type={alertInfo.type} onDismiss={() => setAlertInfo(null)}>
                {alertInfo.message}
              </CustomAlert>
            )}
            <div className="container-fluid">
              <div className="row" style={{ flexWrap: 'nowrap' }}>
                <SideBar stats={stats} lang={lang} />
                <Suspense fallback={<SuspenseFallback />}>
                  <Switch>
                    {/* PRODUCTS */}
                    <Route path="/products/add" component={LazyProductsAdd} />
                    <Route path="/products/:id/edit" component={LazyProductsEdit} />
                    <Route path="/products/:id/edit-image" component={LazyProductsEditImage} />
                    <Route path="/products2d/:id/extractions" component={LazyProductsExtractions} />
                    <Route path="/products3d/:id/extractions" component={LazyProductsExtractions} />
                    <Route path="/products/:id/model3d" component={LazyProductsProductsModel3D} />
                    <Route path="/products/:id/eraser" component={LazyEraserContainer} />
                    <Route path="/products/:id/transform" component={LazyTransformContainer} />
                    <Route path="/productslinks" component={LazyProductsLinks} />
                    <Route path="/products" component={LazyProducts} />

                    {/* MERCHANT */}
                    <Route path="/merchant/edit" component={LazyMerchantEdit} />
                    <Route path="/merchant/opening-hours" component={LazyMerchantOpeningHours} />
                    <Route path="/merchant/categories" component={LazyMerchantCategories} />
                    <Route path="/merchant/invoices" component={LazyInvoices} />
                    <Route path="/merchant" component={LazyMerchant} />

                    {/* MIX */}
                    <Route path="/website" component={LazyWebsite} />
                    <Route path="/domain" component={LazyCustomDomain} />
                    <Route path="/storefrontdomain" component={LazyNewCustomDomain} />
                    <Route path="/faq" component={LazyFAQ} />
                    <Route path="/analytics" component={LazyAnalytics} />
                    <Route path="/customers" component={LazyCustomers} />
                    <Route path="/order/detail" component={LazyOrderProductDetails} />
                    <Route path="/orders" component={LazyOrders} />
                    <Route path="/token" component={AuthByToken} />
                    <Route path="/stripe" component={LazyMerchant} />
                    <Route path="/billing" component={LazyBilling} />
                    <Route path="/predirect" component={ProductsRedirect} />
                    <Redirect from="*" to="/predirect" />
                    {/* <Redirect from="*" to="/products" /> */}
                  </Switch>
                </Suspense>
              </div>
            </div>
            {/* <Footer lang={lang} /> */}
          </div>
        )}
      </>
    );
    // }
  };
  if (match) {
    return (
      <Suspense fallback={<SuspenseFallback />}>
        <Route path="/products/:id/eraser" component={LazyEraserContainer} />
      </Suspense>
    );
  }
  if (match2) {
    return (
      <Suspense fallback={<SuspenseFallback />}>
        <Route path="/products/:id/transform" component={LazyTransformContainer} />
      </Suspense>
    );
  }
  return render();
};
export default RoutesLogin;
