import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { __ } from '../../lib/i18n';
import './Product.scss';
import binSrc from '../../img/bin.svg';
import download from '../../img/download.svg';
import share from '../../img/share.svg';
import qrCode from '../../img/qr-code.svg';

import { ProductSkeleton } from '../ProductsSkeleton/ProductsSkeleton';
import { getImageUri } from '../../utils/images';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Upload3dModelInput from '../Upload3dModelInput';
import Snackbar from '../Snackbar/SnackBar';
import spinnerSrc from '../../img/ajax-loader.gif';
import ArSupported from '../../utils/arSupported';
import { selectBranding } from '../../redux/selectors/customDomainSelectors';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import linkedinIcon from '../../img/icons/LinkedIn.svg';
import whatsappIcon from '../../img/icons/Whatsapp.svg';
import twitterIcon from '../../img/icons/Twitter.svg';
import facebookIcon from '../../img/icons/Facebook.svg';
import linkIcon from '../../img/icons/Link.svg';

const Product = props => {
  const {
    product: { podId, id, title, images, price, currency, sold, externalBuyUrl },
  } = props;

  const {
    app: { lang },
  } = useSelector((state: RootState) => state);

  const branding = useSelector(selectBranding);

  const baseUrl = branding?.domain?.cdn_dns_deployed ? `https://${branding.domain.domain_name}` : 'https://goyuge.com';

  const pTitleClassname = title?.trim().length ? 'p-details' : 'p-details-empty ';
  const pPriceClassname = price?.length ? 'p-details' : 'p-details-empty ';
  const pLinkClassname = externalBuyUrl?.trim().length ? 'p-details' : 'p-details-empty ';

  const handleClickDownload = event => {
    event.preventDefault();
    let finalURL = images[0].URL;

    const splitedFilename = images[0].filename.split('.');
    const fileExtension = splitedFilename[splitedFilename.length - 1];

    fetch(finalURL)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title || 'download'}.${fileExtension}`;
        a.click();
        return a;
      });
  };
  const handleDelete = event => {
    event.preventDefault();
    const confirmed = window.confirm(__(lang, 'DELETE_PRODUCT_CONFIRM'));
    if (confirmed) {
      props.handleDelete({ podId, productId: id });
    }
  };

  const [showQR, setShowQR] = useState(false);

  const handleShowQr = event => {
    event.preventDefault();
    setShowQR(true);
  };

  useEffect(() => {
    if (showQR) {
      const productHtml = document.getElementById('pr' + id + title);
      if (productHtml) productHtml.focus();
    }
  }, [showQR]);

  const handleEdit = event => {
    event.preventDefault();
    props.handleEdit(props.product);
  };
  const handleClickExtractions = event => {
    event.preventDefault();
    props.handleExtractions(props.product);
  };

  const [showSkeleton, setShowSkeleton] = useState(true);

  const onLoadImageSource = () => {
    setShowSkeleton(false);
  };

  const { isAndroid, isIOS } = ArSupported();

  const [visibleSnackbar, setVisibleSnackbar] = useState(false);
  const [limitReached, setLlimitReached] = useState(false);

  const [uploadedElementsCounter, setUploadedElementsCounter] = useState(0);

  const [isUploading3D, setIsUploading3D] = useState(false);
  const [errorUploading, setErrorUploading] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      {showQR ? (
        <div
          id={'pr' + id + title}
          onBlur={() => setShowQR(false)}
          className="mt-3"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxSizing: 'border-box',
            borderRadius: '12px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            position: 'relative',
            width: '100%',
            backgroundColor: '#ffc107',
          }}
          tabIndex={0}>
          <div
            style={{
              width: 'calc(100% - 66px)',
              height: 'calc(100% - 66px)',
              padding: 0,
              margin: 0,
              marginTop: '12px',
              backgroundColor: '#ffc107',
            }}>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`${baseUrl}/product/${id}`}
              viewBox="0 0 256 256"
              bgColor="rgba(1, 1, 1, 0)"
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              padding: '6px',
              marginBottom: '6px',
              height: '48px',
              color: 'white',
              fontWeight: 600,
            }}>
            {__(lang, 'SCAN_QR_CODE')}
          </div>
        </div>
      ) : (
        <div>
          {isUploading3D && (
            <img
              src={spinnerSrc}
              alt=""
              style={{
                display: 'block',
                padding: 'calc(50% - 13px)',
                marginBottom: '18px',
              }}
            />
          )}
          <div
            className={isUploading3D ? 'unselectable' : ''}
            style={{
              opacity: isUploading3D ? '0.25' : '1',
              position: isUploading3D ? 'absolute' : 'relative',
              top: 0,
              left: 0,
            }}>
            {showSkeleton && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  height: '100%',
                }}>
                <ProductSkeleton />
              </div>
            )}
            <div
              className={`Product mt-3 ${showSkeleton ? 'hiddenn' : 'fade-inn'}`}
              style={{
                /* marginLeft: '-15px', marginRight: '-15px', */ visibility: showSkeleton ? 'hidden' : 'visible',
              }}>
              {visibleSnackbar && (
                <Snackbar
                  key={uploadedElementsCounter.toString() + 'ss' + id}
                  error={errorUploading}
                  keyString={uploadedElementsCounter.toString() + id}
                  errorMessage={limitReached ? __(lang, 'UPLOAD_3D_LIMIT_REACHED') : undefined}
                  message={'3D scan added successfully'}
                  visibleProp={visibleSnackbar}
                  onClose={() => {
                    setVisibleSnackbar(false);
                    setErrorUploading(false);
                    setLlimitReached(false);
                    // setTimeout(() => {
                    //   setErrorUploading(false);
                    // }, 300);
                  }}
                />
              )}
              <div style={{ position: 'relative' }}>
                <img
                  loading="lazy"
                  id={id}
                  // className="rounded"
                  src={getImageUri(images, false, true)}
                  alt={'...'}
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                    aspectRatio: '9/9',
                    borderRadius: '12px',
                  }}
                  onLoad={onLoadImageSource}
                />

                {sold && <div className="soldOut">{__(lang, 'SOLD')}</div>}
                <div className="Product_options" style={{ borderRadius: '12px' }}>
                  {!(isAndroid || isIOS) && (
                    <div
                      style={{
                        position: 'absolute',
                        borderRadius: '12px',
                        top: '0px',
                        left: '0px',
                        width: '100%',
                      }}>
                      <Upload3dModelInput
                        key={'upl3d' + id}
                        product={props?.product}
                        productId={id}
                        onComplete={(successfulUploading, isLimitReached) => {
                          if (!successfulUploading) {
                            setErrorUploading(true);
                          }
                          if (isLimitReached) {
                            setLlimitReached(true);
                          }
                          setVisibleSnackbar(true);
                          setUploadedElementsCounter(prev => prev + 1);
                        }}
                        onUploading={isUpl => {
                          setIsUploading3D(isUpl);
                        }}
                      />
                    </div>
                  )}
                  <button className="btn btn-download buttonBigWhenHover" onClick={handleClickDownload}>
                    <img id="img-download" title="Download" src={download} alt={'...'} />
                  </button>
                  <button className="btn btn-bin buttonBigWhenHover" title="Delete" onClick={handleDelete}>
                    <img id="img-bin" src={binSrc} alt={'...'} />
                  </button>
                  {/*  */}
                  <button
                    className="btn btn-download buttonBigWhenHover"
                    type="button"
                    data-toggle="modal"
                    data-target={'#shareModal' + id}
                    onClick={() => navigator.clipboard.writeText(`${baseUrl}/product/${id}`)}>
                    <img
                      id="img-share"
                      title="Share"
                      src={share}
                      alt={'...'}
                      style={{
                        width: '26px',
                        height: '26px',
                        marginTop: '4px',
                      }}
                    />
                  </button>
                  <button className="btn btn-download buttonBigWhenHover" onClick={handleShowQr}>
                    <img
                      id="img-qrCode"
                      title="QR-Code"
                      src={qrCode}
                      alt={'...'}
                      style={{
                        width: '26px',
                        height: '26px',
                        marginTop: '4px',
                      }}
                    />
                  </button>

                  {/*  */}
                  <button className="btn-image-action btn-edit buttonBigWhenHover" onClick={handleEdit}>
                    {__(lang, 'EDIT')}
                  </button>
                  <button
                    className="btn-image-action btn-extraction buttonBigWhenHover"
                    style={{ marginRight: 0 }}
                    onClick={handleClickExtractions}>
                    {__(lang, 'EXTRACTION')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          visibility: showSkeleton ? 'hidden' : 'visible',
          padding: '15px',
          marginTop: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: ' 12px',
          backgroundColor: '#f8f9fa',
        }}>
        {/* TITLE */}
        <p
          className={`${pTitleClassname} h5 ${title?.trim().length ? ' font-weight-bold' : ''} `}
          style={{
            height: '24px',
            fontStyle: !title?.trim().length && 'italic',
          }}>
          {title?.trim().length ? title : __(lang, 'EMPTY_PRODUCT_NAME')}
        </p>

        {/* PRICE */}
        <p
          className={pPriceClassname}
          style={{
            marginBottom: '4px',
            height: '24px',
            fontStyle: !price?.length && 'italic',
          }}>
          {price?.length ? (
            <>
              {currency} {price.toLocaleString('es-ES', { minimumFractionDigits: 2 })}
            </>
          ) : (
            __(lang, 'EMPTY_PRODUCT_PRICE')
          )}
        </p>

        {/*  LINK */}
        {externalBuyUrl?.trim().length ? (
          <a className={pLinkClassname} href={externalBuyUrl} target="_blank" style={{ height: '24px' }}>
            {externalBuyUrl}
          </a>
        ) : (
          <p className={pLinkClassname} style={{ height: '24px', marginBottom: 0, fontStyle: 'italic' }}>
            {__(lang, 'EMPTY_PRODUCT_URL')}
          </p>
        )}
      </div>

      {(isAndroid || isIOS) && (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15px',
            }}>
            <Upload3dModelInput
              key={'upl3d' + id}
              product={props?.product}
              productId={id}
              onComplete={(successfulUploading, isLimitReached) => {
                if (!successfulUploading) {
                  setErrorUploading(true);
                }
                if (isLimitReached) {
                  setLlimitReached(true);
                }
                setVisibleSnackbar(true);
                setUploadedElementsCounter(prev => prev + 1);
              }}
              onUploading={isUpl => {
                setIsUploading3D(isUpl);
              }}
            />
          </div>
          <hr style={{ borderTop: '2px solid #f0f0f0', borderRadius: '2px' }} />
        </>
      )}
      {/* MODAL */}
      {/* <div className="modal fade" id="shareModal" tabIndex={-1} aria-labelledby="shareModalLabel" aria-hidden="true" */}
      {
        id && (
          <div
            className="modal fade"
            id={'shareModal' + id}
            tabIndex={-1}
            aria-labelledby={'shareModalLabel' + id}
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div
                className="modal-content border-0"
                style={{
                  backgroundColor: '#ffc107',
                  boxSizing: 'border-box',
                  paddingLeft: '25px',
                  paddingRight: '25px',
                  borderRadius: '12px',
                }}>
                <div className="modal-header border-bottom-0" style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <h5 className="modal-title text-white mt-4" id={'shareModalLabel' + id}>
                      Link copied to clipboard
                    </h5>
                  </div>

                  {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> */}
                </div>
                <div className="modal-body ">
                  <div className="d-flex justify-content-center w-100">
                    <a
                      className="blue-hover2 text-white"
                      style={{ wordBreak: 'break-word' }}
                      target="blank"
                      href={`${baseUrl}/product/${id}`}>
                      {baseUrl}/product/{id}
                    </a>
                  </div>
                  <div className="d-flex justify-content-center w-100 mt-4">
                    <h5 className="modal-title text-white" id={'shareModalLabel' + id}>
                      Share it with others
                    </h5>
                  </div>
                  <div className="d-flex justify-content-center w-100 mt-4">
                    {/* @ts-ignore */}
                    <WhatsappShareButton
                      url={`${baseUrl}/product/${id}`}
                      title={title}
                      separator=":: "
                      className="opacc">
                      <img
                        src={whatsappIcon}
                        alt="lnkdn"
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '10px',
                        }}
                      />
                      {/* <WhatsappIcon size={32} round /> */}
                    </WhatsappShareButton>

                    {/* @ts-ignore */}
                    <TwitterShareButton url={`${baseUrl}/product/${id}`} title={title} className="opacc">
                      <img
                        src={twitterIcon}
                        alt="lnkdn"
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '10px',
                        }}
                      />

                      {/* <TwitterIcon size={32} round /> */}
                    </TwitterShareButton>

                    {/* @ts-ignore */}
                    <FacebookShareButton url={`${baseUrl}/product/${id}`} title={title} className="opacc">
                      <img
                        src={facebookIcon}
                        alt="lnkdn"
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '10px',
                        }}
                      />
                      {/* <FacebookIcon size={32} round /> */}
                    </FacebookShareButton>

                    {/* @ts-ignore */}
                    <LinkedinShareButton url={`${baseUrl}/product/${id}`} title={title} className="opacc">
                      <img
                        src={linkedinIcon}
                        alt="lnkdn"
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '10px',
                        }}
                      />
                    </LinkedinShareButton>

                    {/* @ts-ignore */}
                    {/* <CustomTooltip text={title}> */}
                    <div
                      title={title}
                      className="opacc "
                      onClick={() => navigator.clipboard.writeText(`${baseUrl}/product/${id}`)}>
                      <img
                        src={linkIcon}
                        alt="lnkdn"
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '10px',
                        }}
                      />
                    </div>
                    {/* </CustomTooltip> */}
                  </div>
                </div>
                <div className="modal-footer border-top-0 d-flex justify-content-center pt-0">
                  <button
                    type="button"
                    className="btn btn-warning px-4"
                    style={{
                      backgroundColor: '#ffda6b',
                      color: '#5c5236',
                      borderRadius: '10px',
                    }}
                    data-dismiss="modal">
                    CLOSE
                  </button>
                  {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                </div>
              </div>
            </div>
          </div>
        )
        // {/* END MODAL */}
      }
    </div>
  );
};

Product.propTypes = {
  lang: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleExtractions: PropTypes.func.isRequired,
};

export default Product;
