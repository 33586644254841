/* eslint-disable react/jsx-props-no-spreading */
import PropType from 'prop-types';
import React, { useState } from 'react';
import PricingCard from './pricingCard';

/* eslint-disable react/react-in-jsx-scope */
function PricingGrid({ monthlyPlans, yearlyPlans, lang, billingPeriod, currentPlan, handleSelectPlan }) {
  const [selectedPlan, setSelectedPlan] = useState(-1);

  const handleChangeSelectedPlan = planId => {
    handleSelectPlan(planId);
    if (selectedPlan === planId) {
      setSelectedPlan(-1);
      return;
    }
    setSelectedPlan(planId);
  };

  return (
    <div className="relative w-full lg:mt-0 flex justify-center flex-wrap gap-x-0 md:gap-x-20" role="list">
      {billingPeriod === 'month' &&
        !!monthlyPlans?.length &&
        monthlyPlans?.map((plan, index) => (
          <PricingCard
            subscribed={currentPlan?.plan_id === plan.id}
            disabled={currentPlan?.plan_id === plan.id}
            key={index}
            lang={lang}
            billingPeriod={billingPeriod}
            handleChangeSelectedPlan={() => handleChangeSelectedPlan(plan)}
            {...plan}
          />
        ))}
      {billingPeriod === 'year' &&
        !!yearlyPlans?.length &&
        yearlyPlans?.map((plan, index) => (
          <PricingCard
            subscribed={currentPlan?.plan_id === plan.id}
            disabled={currentPlan?.plan_id === plan.id}
            key={index}
            lang={lang}
            billingPeriod={billingPeriod}
            handleChangeSelectedPlan={() => handleChangeSelectedPlan(plan)}
            {...plan}
          />
        ))}
    </div>
  );
}

PricingGrid.propTypes = {
  billingPeriod: PropType.string,
  firstRender: PropType.bool,
  handleSelectPlan: PropType.func,
  plans: PropType.array,
};

export default PricingGrid;
